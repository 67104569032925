import { Box, Button, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import {
  ApiEventGroup,
  ApiEventGroupSumary,
  ApiEventSource,
  ApiUser,
  ApiUserSummary,
  UpdateApiEvent,
} from "@operations-hero/lib-api-client";
import { unwrapResult } from "@reduxjs/toolkit";
import { Form, Formik } from "formik";
import { FC, useCallback, useMemo } from "react";
import { MdCheck, MdClose } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { CheckboxControl } from "../../../../components/form-helpers/CheckboxControl";
import {
  CreateUserType,
  EventGroupUserAutocompleteControl,
} from "../../../../components/form-helpers/EventGroupUserAutocompleteControl";
import { NumberInputControl } from "../../../../components/form-helpers/NumberInputControl";
import { TextEditorControl } from "../../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { TextInputControl } from "../../../../components/form-helpers/TextInputControl";
import { RootState, useThunkDispatch } from "../../../../store";
import { updateEvent } from "../../../../store/events/event-details.slice";
import { updateSingleEventData } from "../../../../store/events/event-list.slice";
import { toastStatus } from "../EventEditForm";
import { EventGroupSelector } from "../form-components/EventGroupSelector";

interface EventSummaryProps {
  onCancel: () => void;
  showToast: (status: toastStatus, title: string) => void;
}

interface EventSummaryFormValues {
  name: string | null;
  eventGroup: ApiEventGroup | null;
  description: string | null;
  isPublic?: boolean;
  attendees: number;
  eventContact: ApiUserSummary | null;
}

const defaultSummaryFormSchema = {
  name: yup.string().required("Name is required").nullable(),
  eventGroup: yup.object().required("Group is required").nullable(),
  eventContact: yup.object().nullable().required("Event Contact is required"),
};

export const EventSummaryForm: FC<EventSummaryProps> = ({
  onCancel,
  showToast,
}) => {
  const { event } = useSelector((state: RootState) => state.eventDetails);
  const { loading } = useSelector((state: RootState) => state.eventList);
  const { requireAttendees } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );
  const { apiClient, currentAccount } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const initialValues: EventSummaryFormValues = useMemo(
    () => ({
      name: event?.name || null,
      eventGroup: (event?.eventGroup as unknown as ApiEventGroup) || null,
      description: event?.description || null,
      isPublic: event?.isPublic,
      attendees: event ? event.attendees : 0,
      eventContact: event ? event.eventContact : null,
    }),
    [event]
  );

  const handleOnSubmit = useCallback(
    (values: EventSummaryFormValues) => {
      if (event === null || !event.id) return;

      const eventToUpdate: UpdateApiEvent = {
        name: values.name || "",
        eventGroup: values.eventGroup as unknown as ApiEventGroupSumary,
        description: values.description,
        isPublic: values.isPublic,
        attendees: values.attendees,
        eventContact: values.eventContact ? values.eventContact : undefined,
      };

      thunkDispatch(
        updateEvent({
          apiClient,
          eventId: event.id,
          event: eventToUpdate,
          accountId: currentAccount.id,
        })
      )
        .then(unwrapResult)
        .then(() => {
          showToast("success", "Event was updated successfully");
          if (loading === "succeeded") {
            dispatch(
              updateSingleEventData({
                eventId: event.id,
                values: {
                  name: values.name || "",
                  eventGroup:
                    values.eventGroup as unknown as ApiEventGroupSumary,
                  description: values.description,
                  isPublic: values.isPublic,
                },
              })
            );
          }
          onCancel();
        })
        .catch(() => {
          showToast("error", "Something went wrong, please try again");
        });

      onCancel();
    },
    [
      apiClient,
      currentAccount.id,
      dispatch,
      event,
      loading,
      onCancel,
      showToast,
      thunkDispatch,
    ]
  );

  const handleEventGroupCreate = useCallback(
    (user: ApiUser) => {
      if (event?.eventGroup) {
        apiClient
          .createEventGroupUsers(currentAccount.id, event.eventGroup.id, [user])
          .then(() => {
            showToast("success", "User saved succesfully in group");
          })
          .catch(() =>
            showToast(
              "error",
              "Something went wrong saving new User, please try again"
            )
          );
      }
    },
    [event, apiClient, currentAccount, showToast]
  );

  const formSchema = useMemo(() => {
    if (Boolean(requireAttendees)) {
      const newSchema = {
        ...defaultSummaryFormSchema,
        attendees: yup
          .number()
          .min(
            1,
            "The number of attendes is required, and must be greater than 0"
          )
          .required(),
      };
      return yup.object().shape(newSchema);
    }
    return yup.object().shape(defaultSummaryFormSchema);
  }, [requireAttendees]);

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      validationSchema={formSchema}
    >
      {({ values, isValid, dirty }) => {
        return (
          <Form>
            <Grid gap={4}>
              <GridItem>
                <EventGroupSelector
                  name="eventGroup"
                  value={values.eventGroup}
                  label=""
                />
              </GridItem>
              <GridItem>
                <EventGroupUserAutocompleteControl
                  name="eventContact"
                  label="Event Contact"
                  value={values.eventContact}
                  eventGroupId={values.eventGroup ? values.eventGroup.id : ""}
                  showCreate
                  createOptions={{
                    isDisabled: !values.eventGroup,
                    userType: CreateUserType.contact,
                    formType: "inlineForm",
                    onCreate: (user) => handleEventGroupCreate(user),
                  }}
                  isDisabled={!values.eventGroup}
                />
              </GridItem>

              <GridItem>
                <TextInputControl
                  name="name"
                  value={values.name}
                  label="Name this event"
                />
              </GridItem>

              <GridItem>
                <TextEditorControl
                  name="description"
                  label="Summary or Description (Optional)"
                  value={values.description}
                />
              </GridItem>
              <GridItem>
                <Box maxW={["50%", null, "50%"]}>
                  <NumberInputControl
                    min={0}
                    showStepper
                    name="attendees"
                    label="How many people will attend?"
                    value={values.attendees}
                  />
                </Box>
              </GridItem>
              <GridItem
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <CheckboxControl
                  name="isPublic"
                  value={values.isPublic}
                  labelFontWeight="500"
                  label="Show in the public calendar?"
                />
                <Box display="flex" alignItems="center" alignSelf="flex-end">
                  <Text fontWeight="bold" whiteSpace="nowrap" mr={4}>
                    Public Portal
                  </Text>
                  {event && (
                    <Box display="flex" alignItems="center">
                      {event.source === ApiEventSource.Portal ? (
                        <Icon as={MdCheck} color="green.500" boxSize={4} />
                      ) : (
                        <Icon as={MdClose} color="red.500" boxSize={4} />
                      )}
                    </Box>
                  )}
                </Box>
              </GridItem>

              <GridItem display="flex" justifyContent="space-between">
                <Button
                  variant="outline"
                  size="sm"
                  borderColor="blue.500"
                  onClick={onCancel}
                >
                  Cancel
                </Button>
                <Button
                  size="sm"
                  type="submit"
                  colorScheme="blue"
                  disabled={!isValid || !dirty}
                >
                  Save Changes
                </Button>
              </GridItem>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
