import { Box, HStack, Icon, Text } from "@chakra-ui/react";
import { ApiAsset } from "@operations-hero/lib-api-client";
import { AsyncSelect, MenuPlacement } from "chakra-react-select";
import { Fragment, useCallback, useMemo } from "react";
import { FaSearch } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState } from "../../../store";

export interface AssetSearchAutocompleteProps {
  value: ApiAsset | null;
  onChange: (value: ApiAsset | null) => void;
  fieldRef?: any;
  locations?: string[];
  categories?: string[];
  menuPlacement?: MenuPlacement;
  selected?: ApiAsset[] | null;
}

export const AssetSearchAutocomplete = ({
  onChange,
  value,
  locations,
  categories,
  fieldRef,
  menuPlacement,
  selected,
}: AssetSearchAutocompleteProps) => {
  const { currentAccount, apiClient } = useAuthentication();
  const { descendantsMap } = useSelector(
    (state: RootState) => state.localCache
  );
  const assets = useSelector(
    (state: RootState) => state.requestForm.assets.data
  );

  const handleChange = useCallback(
    (newValue: ApiAsset | null) => {
      onChange(newValue);
    },
    [onChange]
  );

  const locationIncludingItsChildren = useMemo(() => {
    if (!locations) {
      return;
    }
    let allLocationsIds: string[] = [];

    locations.forEach((locId) => {
      allLocationsIds.push(locId);
      const children = descendantsMap[locId];
      if (children) {
        allLocationsIds.push(...children);
      }
    });

    const uniqueLocationsIds = Array.from(new Set(allLocationsIds));
    return uniqueLocationsIds;
  }, [descendantsMap, locations]);

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      apiClient
        .findAssets(currentAccount.id, {
          search: inputValue,
          pageSize: 10,
          locations: locationIncludingItsChildren,
          categories,
        })
        .then((results) => {
          if (!selected || selected.length === 0) {
            cb(results.data.filter((d) => !assets.some((a) => a.id === d.id)));
            return;
          }
          cb(results.data.filter((d) => !selected.some((a) => a.id === d.id)));
        });
    },
    [
      apiClient,
      currentAccount.id,
      locationIncludingItsChildren,
      categories,
      selected,
      assets,
    ]
  );

  const key = useMemo(() => {
    const locs = locations ? locations.join(",") : "";
    if (selected && selected.length) {
      return `taksbooks-select-${selected.length}${locs}`;
    }
    return `taksbooks-select-${assets.length}${locs}`;
  }, [assets.length, locations, selected]);

  return (
    <Fragment>
      <HStack>
        <Box flex={1}>
          <AsyncSelect
            ref={fieldRef}
            key={key}
            isClearable={false}
            cacheOptions={false}
            isMulti={false}
            defaultOptions={true}
            value={value || null}
            onChange={handleChange}
            loadOptions={loadOptions}
            menuPlacement={menuPlacement}
            getOptionValue={(item: ApiAsset) => item.id}
            getOptionLabel={(item: ApiAsset) => item.name}
            placeholder={
              <Text>
                <Icon as={FaSearch} mr={4} />
                {"Search assets to add"}
              </Text>
            }
            components={{
              DropdownIndicator: () => null,
              IndicatorSeparator: () => null,
            }}
            openMenuOnFocus={true}
            menuShouldScrollIntoView={false}
            menuShouldBlockScroll={false}
          />
        </Box>
      </HStack>
    </Fragment>
  );
};
