import { InfoIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Spacer,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { ApiAsset } from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { AssetConditionBadge } from "../../../components/badges/AssetConditionBadge";
import { AssetWarrantyBadge } from "../../../components/badges/AssetWarrantyBadge";
import { LocationTwoLine } from "../../../components/badges/LocationTwoLine";
import ImageViewerModal from "../../../components/images/ImageViewerModal";
import { useIsReviewerOrTechnicianOfWorkflows } from "../routes/routes-helpers/policies-checks";
import { useIsAllowManageAssetForTech } from "../routes/routes-helpers/settings-checks";

export interface AssetCardProps {
  asset: ApiAsset;
  isAssetList?: boolean;
  onClick?: (asset: ApiAsset) => void;
  handleEditingAssetCardClick?: (asset: ApiAsset) => void;
  children: JSX.Element;
  condensed?: boolean;
  wrapWithLink?: boolean;
}

export const AssetCard = ({
  asset,
  isAssetList,
  onClick,
  handleEditingAssetCardClick,
  children,
  condensed = false,
  wrapWithLink,
}: AssetCardProps) => {
  const { isReviewerOrTech } = useIsReviewerOrTechnicianOfWorkflows();
  const { isAllowManageAssetForTech } = useIsAllowManageAssetForTech();
  const { isProductAdmin } = useAuthentication();
  const handleCardClick = useCallback(() => {
    if (!onClick) return;
    onClick(asset);
  }, [onClick, asset]);

  const canEditAsset = useMemo(() => {
    return (isReviewerOrTech && isAllowManageAssetForTech) || isProductAdmin;
  }, [isReviewerOrTech, isAllowManageAssetForTech, isProductAdmin]);

  const requiredProperties = [
    "name",
    "externalId",
    "location",
    "reportingCategory",
    "manufacturer",
  ];

  const isAssetIncomplete = (asset: any) => {
    return requiredProperties.some((property) => !asset[property]);
  };

  const assetCardContent = (
    <Flex
      direction={condensed ? "column" : { base: "column", sm: "row" }}
      pl={0}
      borderColor={useColorModeValue("gray.200", "gray.600")}
      borderWidth={1}
      borderRadius="md"
      onClick={handleCardClick}
      _hover={
        onClick
          ? {
              cursor: "pointer",
            }
          : undefined
      }
    >
      <Box
        maxW={"100%"}
        maxH={"100%"}
        w={condensed ? "100%" : ["100%", "170px"]}
        h={condensed ? "100px" : ["100px", "170px"]}
        position="relative"
      >
        <Box
          bgColor={useColorModeValue("gray.200", "gray.600")}
          borderBottomLeftRadius={condensed ? 0 : [0, "md"]}
          borderTopLeftRadius={["md"]}
          borderTopRightRadius={condensed ? "md" : ["md", 0]}
          color={useColorModeValue("gray.500", "gray.100")}
          display="flex"
          height="100%"
          maxH="100%"
          maxW="100%"
          overflow="hidden"
          position="relative"
          width="100%"
        >
          {asset.defaultImage ? (
            <ImageViewerModal
              image={{
                src: asset.defaultImage.url,
                alt: asset.defaultImage.name || "Asset Attachment",
              }}
              imageProps={{
                minH: "100%",
                minW: "100%",
                boxSizing: "border-box",
                left: "50%",
                top: "50%",
                objectFit: "cover",
              }}
            />
          ) : (
            <Center w="100%">
              <Text>No Image</Text>
            </Center>
          )}
        </Box>
      </Box>

      <Box flex="1" px={[2, 4]} py={2}>
        <Stack spacing={3}>
          <Text fontSize="md" isTruncated={true}>
            {asset.name}
          </Text>
          <HStack spacing={2}>
            <AssetConditionBadge value={asset.condition} />
            {asset.externalId && (
              <Text fontSize="small" colorScheme="gray" isTruncated ml={2}>
                {asset.externalId}
              </Text>
            )}
          </HStack>
          {asset.reportingCategory && (
            <Box>
              <Text fontSize="sm">{asset.reportingCategory.name}</Text>
            </Box>
          )}
          <Box>
            {asset.location ? (
              <LocationTwoLine value={asset.location} />
            ) : (
              "No Location / Mobile asset"
            )}
          </Box>
        </Stack>
      </Box>
      <Flex direction={condensed ? "row" : ["row", "column"]} p={2}>
        <Box textAlign="right">
          <AssetWarrantyBadge asset={asset} />
        </Box>
        <VStack alignItems={"end"} pt={2}>
          {asset && !isAssetList && isAssetIncomplete(asset) && (
            <>
              <HStack spacing={1} alignItems="center" pt={2}>
                <InfoIcon boxSize={5} color="blue.500" />
                <Text fontSize="sm" color="blue.500">
                  Incomplete info
                </Text>
              </HStack>
              {canEditAsset && (
                <Button
                  variant="outline"
                  size="sm"
                  borderColor="blue.500"
                  onClick={(e) => {
                    e.stopPropagation();
                    if (handleEditingAssetCardClick) {
                      handleEditingAssetCardClick(asset);
                    }
                  }}
                >
                  Update
                </Button>
              )}
            </>
          )}
        </VStack>
        <Spacer />
        <Box textAlign="right">{children}</Box>
      </Flex>
    </Flex>
  );

  return wrapWithLink ? (
    <Link to={`/account/assets/${asset.id}`}>{assetCardContent}</Link>
  ) : (
    assetCardContent
  );
};
