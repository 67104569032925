import {
  Button,
  Center,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Skeleton,
  Spinner,
  Stack,
  useBreakpointValue,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { unwrapResult } from "@reduxjs/toolkit";
import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import {
  HorizontalStepItem,
  HorizontalSteps,
} from "../../../components/horizontal-steps/HorizontalSteps";
import { useHorizontalStep } from "../../../components/horizontal-steps/useHorizontalSteps";
import { RootState, useThunkDispatch } from "../../../store";
import {
  addScheduledRequestToList,
  updateScheduledRequestInList,
  updateScheduleInList,
} from "../../../store/schedule-request-list.slice";
import {
  addScheduledRequest,
  ScheduledRequestProps,
  setScheduleRequestFormExtraProps,
  unloadSchedulesForm,
} from "../../../store/scheduled-request-form/schedule-request-form.slice";
import {
  createScheduledRequest,
  getScheduledRequestFormValues,
  initScheduleRequestFormValues,
  updateScheduledRequest,
} from "../../../store/scheduled-request-form/thunks";
import { getWorkflowSchemaFieldsForSchedule } from "../../../store/scheduled-request-form/thunks/init-scheduled-request.thunk";
import { debounce } from "../../../utils/debounce";
import { ScheduleAssetsForm } from "./ScheduledAssetsForm";
import { ScheduledRequestForm } from "./ScheduledRequestForm";
import { ScheduleForm } from "./ScheduleForm";
import { ScheduledTaskBooksForm } from "./SheduledTaskbooksForm";

interface ScheduleFormProps {
  isOpen: boolean;
  onClose: () => void;
  workingScheduledRequestId: string | undefined;
  cloneScheduledRequest?: boolean;
  setCloneScheduledRequest?: Dispatch<SetStateAction<boolean>>;
  setWorkingScheduledRequestId?: Dispatch<SetStateAction<undefined | string>>;
}

export const ScheduleForms: FC<ScheduleFormProps> = ({
  isOpen,
  onClose,
  workingScheduledRequestId,
  cloneScheduledRequest,
  setCloneScheduledRequest,
  setWorkingScheduledRequestId,
}) => {
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();
  const { currentUser, currentAccount, apiClient, isProductAdmin } =
    useAuthentication();
  const toast = useToast();
  const {
    activeStep,
    isLastStep,
    getStepState,
    gotoStep,
    progress,
    setTotalSteps,
    resetSteps,
    totalSteps,
  } = useHorizontalStep();
  const isMobile = useBreakpointValue({ base: true, sm: false });

  const {
    isLoading,
    extraProps,
    willRequestRepeat,
    scheduledRequest,
    scheduledRequestAssets,
    scheduledRequestTaskBooks,
  } = useSelector((state: RootState) => state.scheduleRequestForm);

  const { workflowMap, policyMap } = useSelector(
    (state: RootState) => state.localCache
  );

  const modalBackground = useColorModeValue("white", "blue.900");
  const cancelButtonColor = useColorModeValue("blue.500", "white");
  const submitButtonColor = useColorModeValue("blue.500", "whiteAlpha.300");
  const [disableNextbutton, setDisableNextButton] = useState(false);
  const [request, setRequest] = useState<ScheduledRequestProps | null>(
    scheduledRequest
  );
  const [spinnerLoad, setSpinnerLoad] = useState<boolean>(false);

  const { showTaskbooks } = extraProps;

  const editStatus = useMemo(() => {
    if (cloneScheduledRequest !== undefined && cloneScheduledRequest) {
      return "Clone";
    }
    return workingScheduledRequestId !== undefined ? "Update" : "Create";
  }, [workingScheduledRequestId, cloneScheduledRequest]);

  const showAssetsForm = useMemo(() => {
    if (!scheduledRequest || !scheduledRequest.workflow) {
      return false;
    }
    // inactive workflows would fail this lookup
    const workflow = workflowMap[scheduledRequest.workflow.id];

    return (
      (workflow && workflow.allowAssets) || scheduledRequestAssets.length > 0
    );
  }, [workflowMap, scheduledRequest, scheduledRequestAssets]);

  const showTaskbooksForm = useMemo(() => {
    if (!scheduledRequest || !scheduledRequest.workflow) {
      return false;
    }
    // inactive workflows would fail this lookup
    const workflow = workflowMap[scheduledRequest.workflow.id];

    return (workflow && showTaskbooks) || scheduledRequestTaskBooks.length > 0;
  }, [workflowMap, scheduledRequest, scheduledRequestTaskBooks, showTaskbooks]);

  const setScheduledRequestData = useCallback(() => {
    if (request) {
      dispatch(addScheduledRequest(request));
    }
  }, [dispatch, request]);

  const scheduledRequestToast = useCallback(
    (createOrUpdateOrClone?: "create" | "update" | "clone") => {
      if (createOrUpdateOrClone) {
        return toast({
          duration: 1500,
          isClosable: true,
          position: "top",
          status: "success",
          title: `Schedule has been ${createOrUpdateOrClone}d succesfully`,
        });
      }
      return toast({
        duration: 1500,
        isClosable: true,
        position: "top",
        status: "error",
        title: "Error saving scheduled request data",
      });
    },
    [toast]
  );

  const handleOnCloseScheduleForms = useCallback(() => {
    onClose();
    resetSteps();
    setWorkingScheduledRequestId && setWorkingScheduledRequestId(undefined);
    setCloneScheduledRequest && setCloneScheduledRequest(false);
    dispatch(unloadSchedulesForm());
  }, [
    dispatch,
    onClose,
    resetSteps,
    setWorkingScheduledRequestId,
    setCloneScheduledRequest,
  ]);

  const handleOnClickSaveData = useCallback(() => {
    if (
      !scheduledRequest.id ||
      (cloneScheduledRequest !== undefined && cloneScheduledRequest)
    ) {
      let scheduleRequestOperation: "update" | "create" | "clone" | undefined =
        "create";
      if (
        scheduledRequest.id &&
        (cloneScheduledRequest === false || cloneScheduledRequest === undefined)
      ) {
        scheduleRequestOperation = "update";
      }
      if (scheduledRequest.id && cloneScheduledRequest) {
        scheduleRequestOperation = "clone";
      }
      if (
        !scheduledRequest.id &&
        (cloneScheduledRequest === false || cloneScheduledRequest === undefined)
      ) {
        scheduleRequestOperation = "create";
      }
      thunkDispatch(
        createScheduledRequest({
          accountId: currentAccount.id,
          apiClient: apiClient,
        })
      )
        .then(unwrapResult)
        .then((createdScheduledRequest) => {
          createdScheduledRequest &&
            dispatch(addScheduledRequestToList(createdScheduledRequest));
          scheduledRequestToast(scheduleRequestOperation);
        })
        .catch(() => {
          scheduledRequestToast();
        })
        .finally(() => {
          setSpinnerLoad(false);
          handleOnCloseScheduleForms();
        });
    } else {
      thunkDispatch(
        updateScheduledRequest({
          accountId: currentAccount.id,
          apiClient: apiClient,
        })
      )
        .then(unwrapResult)
        .then((res) => {
          if (res) {
            const { updatedScheduledRequest, scheduleAssociation } = res;
            updatedScheduledRequest &&
              dispatch(updateScheduledRequestInList(updatedScheduledRequest));
            scheduleAssociation &&
              dispatch(updateScheduleInList(scheduleAssociation));
            scheduledRequestToast("update");
          }
        })
        .catch(() => {
          scheduledRequestToast();
        })
        .finally(() => {
          setSpinnerLoad(false);
          handleOnCloseScheduleForms();
        });
    }
  }, [
    scheduledRequest.id,
    thunkDispatch,
    currentAccount.id,
    apiClient,
    dispatch,
    scheduledRequestToast,
    handleOnCloseScheduleForms,
    cloneScheduledRequest,
  ]);

  const handleOnClickNext = useCallback(
    (currentStep: number, isLastStep?: boolean) => {
      isLastStep && setSpinnerLoad(true);
      currentStep === 1 && setScheduledRequestData();
      isLastStep && handleOnClickSaveData();
      gotoStep(activeStep)();
    },
    [setScheduledRequestData, handleOnClickSaveData, gotoStep, activeStep]
  );

  const handleUpdateExtraProps = useCallback(
    async (newValues: ScheduledRequestProps) => {
      if (!newValues.workflow) {
        return;
      }

      const policy = policyMap[newValues.workflow.id];
      const newExtraProps = await getWorkflowSchemaFieldsForSchedule({
        apiClient,
        currentAccount,
        currentUser,
        isProductAdmin,
        policy,
        scheduledRequest: newValues,
        workflow: newValues.workflow,
      });
      dispatch(setScheduleRequestFormExtraProps(newExtraProps));
    },
    [
      apiClient,
      currentAccount,
      currentUser,
      dispatch,
      isProductAdmin,
      policyMap,
    ]
  );

  const handleOnChangeFormikValues = useCallback(
    (values: ScheduledRequestProps, isFormValid: boolean) => {
      if (disableNextbutton !== !isFormValid) {
        setDisableNextButton(!isFormValid);
      }
      setRequest((prevState) => {
        if (
          prevState?.workflow &&
          values.workflow &&
          prevState.workflow.id !== values.workflow.id
        ) {
          handleUpdateExtraProps(values);
        }
        return values;
      });
    },
    [disableNextbutton, handleUpdateExtraProps]
  );

  const debouncedChangeFormikValues = debounce(handleOnChangeFormikValues, 200);

  useEffect(() => {
    if (isOpen) {
      if (!workingScheduledRequestId) {
        thunkDispatch(
          initScheduleRequestFormValues({
            apiClient,
            currentUser,
            currentAccount,
            isProductAdmin,
          })
        );
      } else {
        thunkDispatch(
          getScheduledRequestFormValues({
            apiClient,
            currentUser,
            currentAccount,
            isProductAdmin,
            scheduledRequestId: workingScheduledRequestId,
          })
        );
      }
    }
  }, [
    thunkDispatch,
    isOpen,
    apiClient,
    isLoading,
    currentUser,
    currentAccount,
    workingScheduledRequestId,
    isProductAdmin,
  ]);

  return isOpen ? (
    <Modal
      isOpen={isOpen}
      autoFocus={false}
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onClose={handleOnCloseScheduleForms}
    >
      <ModalOverlay />
      <ModalContent
        minW={["90%", "80%", "80%", "748px"]}
        maxW={["90%", "80%", "80%", "748px"]}
        bgColor={modalBackground}
      >
        <ModalBody width="100%" p={1}>
          {spinnerLoad ? (
            <Center py={8}>
              <Spinner size="xl" />
            </Center>
          ) : isLoading ? (
            <Stack>
              <Skeleton height="550px" />
            </Stack>
          ) : (
            <HorizontalSteps
              activeStep={activeStep}
              getStepState={getStepState}
              gotoStep={gotoStep}
              progress={progress}
              setTotalSteps={setTotalSteps}
              saveCallback={activeStep === 1 ? handleOnClickNext : undefined}
              disableStepButtons={disableNextbutton}
            >
              <HorizontalStepItem>
                <ScheduledRequestForm
                  debouncedChangeFormikValues={debouncedChangeFormikValues}
                  editStatus={editStatus}
                  activeStep={activeStep}
                  totalSteps={totalSteps}
                />
              </HorizontalStepItem>

              {showTaskbooksForm && (
                <HorizontalStepItem>
                  <ScheduledTaskBooksForm
                    editStatus={editStatus}
                    activeStep={activeStep}
                    totalSteps={totalSteps}
                  />
                </HorizontalStepItem>
              )}

              {showAssetsForm && (
                <HorizontalStepItem>
                  <ScheduleAssetsForm
                    editStatus={editStatus}
                    activeStep={activeStep}
                    totalSteps={totalSteps}
                  />
                </HorizontalStepItem>
              )}

              {willRequestRepeat && (
                <HorizontalStepItem>
                  <ScheduleForm
                    editStatus={editStatus}
                    activeStep={activeStep}
                    totalSteps={totalSteps}
                  />
                </HorizontalStepItem>
              )}
            </HorizontalSteps>
          )}
        </ModalBody>
        <ModalFooter mt={4} mb={8} display="block" textAlign="center">
          <Button
            mr={10}
            variant="ghost"
            size={isMobile ? "sm" : "md"}
            onClick={handleOnCloseScheduleForms}
            color={cancelButtonColor}
          >
            Cancel
          </Button>

          <Button
            color="white"
            isDisabled={disableNextbutton}
            size={isMobile ? "sm" : "md"}
            minW={isMobile ? "120px" : "180px"}
            _hover={{ backgroundColor: "none" }}
            bgColor={submitButtonColor}
            onClick={() => handleOnClickNext(activeStep, isLastStep)}
          >
            {isLastStep ? "Save" : "Next"}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  ) : null;
};
