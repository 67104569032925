import { Box, BoxProps, Button, ButtonProps, Icon } from "@chakra-ui/react";
import { FC } from "react";
import { ImDownload3 } from "react-icons/im";
import useDownloadAttachment from "../../hooks/useDownloadAttachment";

interface DownloadButtonParams {
  url: string;
  name: string;
  boxProps?: BoxProps;
  buttonProps?: ButtonProps;
}
export const DownloadButton: FC<DownloadButtonParams> = ({
  url,
  name,
  boxProps,
  buttonProps,
}) => {
  const { downloadImage, isDownloading } = useDownloadAttachment(url, name);
  return (
    <Box {...boxProps}>
      <Button
        size="sm"
        aria-label="download attachment"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          downloadImage();
        }}
        isLoading={isDownloading}
        {...buttonProps}
      >
        <Icon as={ImDownload3} />
      </Button>
    </Box>
  );
};
