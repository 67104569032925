import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { clearFilters, updateFilters } from "./budget-list.slice";

type BudgetsPageProps = {
  queryStringFilter: string;
};

const budgetsPageSlice = createSlice({
  name: "budgets-page",
  initialState: {
    queryStringFilter: "",
  } as BudgetsPageProps,
  reducers: {
    unload: (state) => {
      state.queryStringFilter = "";
    },
    updateQueryStringFilter: (
      state,
      action: PayloadAction<BudgetsPageProps["queryStringFilter"]>
    ) => {
      state.queryStringFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateFilters.fulfilled, (state, action) => {
      const filters = { ...action.payload };
      const generatedString = btoa(JSON.stringify(filters));
      state.queryStringFilter = generatedString;
    });
    builder.addCase(clearFilters.fulfilled, (state, action) => {
      state.queryStringFilter = "";
    });
  },
});

export const { updateQueryStringFilter, unload } = budgetsPageSlice.actions;
export default budgetsPageSlice.reducer;
