import {
  Box,
  Image,
  ImageProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { FC } from "react";
import { getPreviewUrl } from "../../utils/getImagePreviewUrl";
import { DownloadButton } from "../buttons/DownloadButton";
import { ZoomableImageAttachment } from "./ZoomableImageAttachment";

interface ImageViewerModalProps {
  image: { src?: string; alt: string; name?: string };
  imageProps?: ImageProps;
  showDownloadButton?: boolean;
}

const ImageViewerModal: FC<ImageViewerModalProps> = ({
  image,
  imageProps,
  showDownloadButton,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box
      position="relative"
      onClick={
        image.src
          ? (e) => {
              e.preventDefault();
              e.stopPropagation();
              onOpen();
            }
          : undefined
      }
      cursor="pointer"
    >
      <Image
        src={getPreviewUrl(image.src || "")}
        alt={image.alt}
        {...imageProps}
      />
      {showDownloadButton && (
        <DownloadButton
          url={image.src || ""}
          name={image.name || ""}
          boxProps={{
            position: "absolute",
            width: "100%",
            gap: 2,
            justifyContent: "space-between",
            top: "0",
            right: "0",
            height: "40",
            p: 1,
          }}
        />
      )}

      {isOpen && image.src && (
        <Modal isOpen={isOpen} onClose={onClose} size="full" isCentered>
          <ModalOverlay bg="rgba(0, 0, 0, 0.8)" />{" "}
          <ModalContent
            w="full"
            h="full"
            m={0}
            p={0}
            position="relative"
            bg="transparent"
          >
            <ModalCloseButton zIndex="overlay" color="white" />
            <ModalBody
              p={0}
              display="flex"
              alignItems="center"
              justifyContent="center"
              h="full"
              overflow="hidden"
            >
              <ZoomableImageAttachment name={image.alt} url={image.src} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
};

export default ImageViewerModal;
