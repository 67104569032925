import { ApiBudget, ApiClient } from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../..";

import { LoadingStatus } from "../types";

type BudgetFormSliceProps = {
  workingBudget: ApiBudget | null;
  updateLoadingStatus: LoadingStatus;
};
type UpdateBudgetParams = {
  id: string;
  apiClient: ApiClient;
  delta: Partial<ApiBudget>;
};

export const updateBudget = createAsyncThunk<ApiBudget, UpdateBudgetParams>(
  "budget/update",
  async ({ id, apiClient, delta }, thunkAPI) => {
    const rootState = thunkAPI.getState() as RootState;
    const { currentAccount } = rootState.auth;
    const updatedBudget = await apiClient.updateBudget(
      currentAccount.id,
      id,
      delta
    );

    return updatedBudget;
  }
);

const initialState: BudgetFormSliceProps = {
  workingBudget: null,
  updateLoadingStatus: "idle",
};

const budgetFormSlice = createSlice({
  name: "budget/details",
  initialState,
  reducers: {
    setUpdateStatus: (
      state,
      action: PayloadAction<BudgetFormSliceProps["updateLoadingStatus"]>
    ) => {
      state.updateLoadingStatus = action.payload;
    },
    setWorkingData: (
      state,
      action: PayloadAction<Pick<BudgetFormSliceProps, "workingBudget">>
    ) => {
      state.workingBudget = action.payload.workingBudget;
    },

    setWorkingBudget: (
      state,
      action: PayloadAction<BudgetFormSliceProps["workingBudget"]>
    ) => {
      state.workingBudget = action.payload;
    },
    unload: (state) => {
      state.workingBudget = null;
      state.updateLoadingStatus = "idle";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateBudget.pending, (state) => {
      state.updateLoadingStatus = "pending";
    });

    builder.addCase(updateBudget.fulfilled, (state, action) => {
      state.updateLoadingStatus = "fulfilled";
      state.workingBudget = action.payload;
    });
  },
});

export const { unload, setWorkingData, setWorkingBudget, setUpdateStatus } =
  budgetFormSlice.actions;
export default budgetFormSlice.reducer;
