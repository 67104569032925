import {
  ApiRequestType,
  ApiWorkflowFieldDataType,
  ApiWorkflowReference,
  CreateApiRequest,
} from "@operations-hero/lib-api-client";
import { useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import * as yup from "yup";
import { RootState } from "../../../store";
import { getId } from "../../../utils/getId";
import { getVisibleFields } from "../../../utils/getVisibleFields";
import { useAuthentication } from "../../auth/AuthProvider";
import { NewRequestFormContext } from "../NewRequestForm";

export const useNewRequestFormSchema = (
  formContext: NewRequestFormContext,
  values: CreateApiRequest
) => {
  const { rulesEngine } = formContext;
  const { isProductAdmin } = useAuthentication();
  const { workflows, workflowMap, policyMap } = useSelector(
    (state: RootState) => state.localCache
  );

  const { visibleFields, requiredFields } = useMemo(() => {
    const schemaVisibleFields = rulesEngine.getVisibleFields({
      //@ts-ignore
      request: values,
      includeDeleted: false,
    });

    const schemaRequiredFields = rulesEngine.getRequiredFields({
      //@ts-ignore
      request: values,
    });

    return {
      visibleFields: schemaVisibleFields,
      requiredFields: schemaRequiredFields,
    };
  }, [rulesEngine, values]);

  const { showCategory, showStartDate, showDueDate } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const { validationSchema } = useMemo(() => {
    const shape: any = {
      type: yup.string().required().oneOf(Object.values(ApiRequestType)),
      requester: yup.object().required("Requester is required"),
      location: yup.object().nullable().required("Location is required"),
      summary: yup.string().nullable().required("Summary is required"),
    };

    if (showCategory) {
      shape.category = yup.object().optional().nullable();
    }

    if (showStartDate || showDueDate) {
      shape.scheduling = yup.object().shape({
        start: yup.date().optional().nullable(),
        due: yup.date().optional().nullable(),
      });
    }

    const metadataShape = requiredFields.reduce<Record<string, any>>(
      (result, field) => {
        let key = "";
        switch (field.key) {
          case "SYSTEM-CATEGORY":
            key = "reportingCategory";
            break;
          case "SYSTEM-START-DATE":
            key = "scheduling.start";
            break;
          case "SYSTEM-DUE-DATE":
            key = "scheduling.due";
            break;
          case "SYSTEM-REASON":
            key = "reason";
            break;
          case "SYSTEM-ASSIGN-TO":
            key = "assignees";
            break;
          case "SYSTEM-PROJECT":
            key = "projectId";
            break;
          case "SYSTEM-BUDGET-ON-REQUESTS":
            key = "budget";
            break;
          case "SYSTEM-ESTIMATED-COST":
            key = "estimatedCost";
            break;
          case "SYSTEM-ESTIMATED-LABOR":
            key = "estimatedHours";
            break;
          default:
            key = field.key;
            break;
        }

        let name = "";
        switch (field.key) {
          case "SYSTEM-CATEGORY":
            name = "Category";
            break;
          case "SYSTEM-START-DATE":
            name = "Start date";
            break;
          case "SYSTEM-DUE-DATE":
            name = "Due date";
            break;
          case "SYSTEM-REASON":
            name = "Reason";
            break;
          case "SYSTEM-ASSIGN-TO":
            name = "Assign to";
            break;
          case "SYSTEM-PROJECT":
            name = "Project";
            break;
          case "SYSTEM-BUDGET-ON-REQUESTS":
            name = "Request budget";
            break;
          case "SYSTEM-ESTIMATED-COST":
            name = "Estimated Cost";
            break;
          case "SYSTEM-ESTIMATED-LABOR":
            name = "Estimated Labor Hours";
            break;
          default:
            name = field.name;
            break;
        }

        result[key] = (
          field.dataType === ApiWorkflowFieldDataType.checkbox
            ? yup.boolean()
            : field.dataType === ApiWorkflowFieldDataType.date
              ? yup.date()
              : field.dataType === ApiWorkflowFieldDataType.location ||
                  field.dataType === ApiWorkflowFieldDataType.selection ||
                  field.dataType === ApiWorkflowFieldDataType.user ||
                  field.key === "SYSTEM-CATEGORY" ||
                  field.key === "SYSTEM-REASON" ||
                  field.key === "SYSTEM-BUDGET-ON-REQUESTS" ||
                  field.key === "SYSTEM-PROJECT"
                ? yup.object()
                : field.dataType === ApiWorkflowFieldDataType.number ||
                    field.key === "SYSTEM-ESTIMATED-COST" ||
                    field.key === "SYSTEM-ESTIMATED-LABOR"
                  ? yup.number()
                  : field.key === "SYSTEM-ASSIGN-TO"
                    ? yup.array().min(1, `${name} is required`)
                    : yup.string()
        )
          .nullable()
          .required(`${name} is required`);

        return result;
      },
      {}
    );

    const newMetadataShape: Record<string, any> = {};
    Object.keys(metadataShape).forEach((key) => {
      if (
        key.substring(0, 6) !== "SYSTEM" &&
        key !== "reportingCategory" &&
        key !== "scheduling.start" &&
        key !== "scheduling.due" &&
        key !== "reason" &&
        key !== "assignees" &&
        key !== "projectId" &&
        key !== "budget" &&
        key !== "estimatedCost" &&
        key !== "estimatedHours"
      ) {
        newMetadataShape[key] = metadataShape[key];
      }
    });

    if (Object.keys(newMetadataShape).length > 0) {
      shape.metadata = yup.object().shape(newMetadataShape);
    }

    let newShape = { ...shape };

    if (metadataShape["reportingCategory"]) {
      newShape = {
        ...newShape,
        reportingCategory: metadataShape["reportingCategory"],
      };
    }

    if (metadataShape["scheduling.start"]) {
      newShape = {
        ...newShape,
        scheduling: yup.object().shape({
          start: metadataShape["scheduling.start"],
          due: newShape.scheduling.fields.due,
        }),
      };
    }

    if (metadataShape["scheduling.due"]) {
      newShape = {
        ...newShape,
        scheduling: yup.object().shape({
          start: newShape.scheduling.fields.start,
          due: metadataShape["scheduling.due"],
        }),
      };
    }

    if (metadataShape["reason"]) {
      newShape = {
        ...newShape,
        reason: metadataShape["reason"],
      };
    }

    if (metadataShape["assignees"]) {
      newShape = {
        ...newShape,
        assignees: metadataShape["assignees"],
      };
    }

    if (metadataShape["projectId"]) {
      newShape = {
        ...newShape,
        projectId: metadataShape["projectId"],
      };
    }

    if (metadataShape["budget"]) {
      newShape = {
        ...newShape,
        budget: metadataShape["budget"],
      };
    }

    if (metadataShape["estimatedCost"]) {
      newShape = {
        ...newShape,
        estimatedCost: metadataShape["estimatedCost"],
      };
    }

    if (metadataShape["estimatedHours"]) {
      newShape = {
        ...newShape,
        estimatedHours: metadataShape["estimatedHours"],
      };
    }

    return { validationSchema: yup.object().shape(newShape) };
  }, [requiredFields, showCategory, showDueDate, showStartDate]);

  // This is to make manual validations if is needed
  const checkUserCanCreateRequest = useCallback(
    (workflow: ApiWorkflowReference | null) => {
      if (!workflow) {
        return false;
      }

      const workingWorflowId = getId(workflow);

      const hasUserAllowedWorkflow = workflows.some(
        (wf) => wf.id === workingWorflowId
      );

      if (!hasUserAllowedWorkflow) {
        return false;
      }

      if (isProductAdmin) {
        return true;
      }

      const workingWorkflow = workflowMap[workingWorflowId];
      if (!workingWorkflow) {
        return false;
      }

      if (workingWorkflow.allowUsersToSubmitRequests) {
        return true;
      }

      const policy = policyMap[workingWorflowId];
      if (
        policy &&
        (policy.admin ||
          policy.approver ||
          policy.requester ||
          policy.reviewer ||
          policy.technician)
      ) {
        return true;
      }

      return false;
    },
    [isProductAdmin, policyMap, workflowMap, workflows]
  );

  const validateNewRequestFields = useCallback(
    (values: CreateApiRequest) => {
      return validationSchema.isValidSync(values);
    },
    [validationSchema]
  );

  const touchedFields = useMemo(() => {
    const schemaFields = Object.keys(validationSchema.fields);

    return schemaFields.reduce(
      (hash, field) => {
        hash[field] = true;
        return hash;
      },
      {} as Record<string, boolean>
    );
  }, [validationSchema.fields]);

  return {
    visibleFields,
    touchedFields,
    requiredFields,
    validationSchema,
    validateNewRequestFields,
    checkUserCanCreateRequest,
  };
};
