import {
  ApiClient,
  ApiProject,
  ApiRequest,
  BulkUpdateApiRequest,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { isValidSchedulingMetadata, SchedulingItemMetadata } from "./types";

type AddRequestToScheduleThunk = {
  response: {
    request: ApiRequest;
    requestAsScheduleItem: SchedulingItemMetadata;
  };
  params: {
    apiClient: ApiClient;
    request: ApiRequest;
    projectId: string;
  };
  options: {
    rejectValue: { project: ApiProject; request: ApiRequest };
  };
};

export const addRequestToSchedule = createAsyncThunk<
  AddRequestToScheduleThunk["response"],
  AddRequestToScheduleThunk["params"],
  AddRequestToScheduleThunk["options"]
>(
  "project-scheduling/request/add",
  async ({ apiClient, request, projectId }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { currentAccount } = state.auth;
    const project = await apiClient.getProject(currentAccount.id, projectId);

    if (!isValidSchedulingMetadata(project.scheduling)) {
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue({ project, request });
    }

    const requestAsScheduleItem = project.scheduling.items[request.id];

    return { request, requestAsScheduleItem };
  }
);

type AddBulkRequestToScheduleThunk = {
  response: {
    requests: BulkUpdateApiRequest[];
    requestsAsScheduleItems: { [key: string]: SchedulingItemMetadata };
    scheduledRequestsIds: string[];
  };
  params: {
    apiClient: ApiClient;
    requests: BulkUpdateApiRequest[];
    projectId: string;
  };
  options: {
    rejectValue: { project: ApiProject; requests: BulkUpdateApiRequest[] };
  };
};

export const addBulkRequestsToSchedule = createAsyncThunk<
  AddBulkRequestToScheduleThunk["response"],
  AddBulkRequestToScheduleThunk["params"],
  AddBulkRequestToScheduleThunk["options"]
>(
  "project-scheduling/request/bulk/add",
  async ({ apiClient, requests, projectId }, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { currentAccount } = state.auth;
    const project = await apiClient.getProject(currentAccount.id, projectId);

    if (!isValidSchedulingMetadata(project.scheduling)) {
      const { rejectWithValue } = thunkAPI;
      return rejectWithValue({ project, requests });
    }

    const requestsAsScheduleItems = requests.reduce<{
      [key: string]: SchedulingItemMetadata;
    }>((map, item) => {
      if (project.scheduling.items[item.idOrKey]) {
        map[item.idOrKey] = project.scheduling.items[item.idOrKey];
      }
      return map;
    }, {});
    const scheduledRequestsIds: string[] = Object.keys(requestsAsScheduleItems);

    return { requests, requestsAsScheduleItems, scheduledRequestsIds };
  }
);

type UpdateTimelineParams = {
  apiClient: ApiClient;
  projectId: string;
  item: SchedulingItemMetadata;
};

export const updateProjectSchedule = createAsyncThunk(
  "project-requests-scheduling/update",
  async ({ apiClient, projectId, item }: UpdateTimelineParams, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { currentAccount } = state.auth;
    const { schedulingCache } = state.projectScheduling;
    const { requestsCache } = state.projectRequests;

    const updatedScheduleItems = {
      items: {
        ...schedulingCache,
        [item.id]: item,
      },
    };

    const updatedProject = await apiClient.updateProject(
      currentAccount.id,
      projectId,
      {
        scheduling: updatedScheduleItems,
      }
    );

    const requestToUpdate = requestsCache[item.id];
    let updatedRequest: ApiRequest | null = null;
    if (requestToUpdate) {
      updatedRequest = await apiClient.updateRequest(
        currentAccount.id,
        item.id,
        {
          scheduling: {
            ...requestToUpdate.scheduling,
            start: item.start,
            due: item.end,
          },
        }
      );
    }

    return { updatedProject, updatedRequest, item };
  }
);
