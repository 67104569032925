import {
  Box,
  Link,
  Skeleton,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiTransaction,
  ApiTransactionType,
} from "@operations-hero/lib-api-client";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { DateBadge } from "../../components/badges/DateBadge";
import { UserBadge } from "../../components/badges/UserBadge";
import {
  DataTable,
  DataTableColumn,
} from "../../components/data-table/DataTable";
import { RootState } from "../../store";
import { formatCurrency } from "../../utils/formatCurrency";
import { LaborTransactionModal } from "../request-form/transactions/labors/LaborTransactionModal";
import { ExpensesModal } from "../request-form/transactions/purchases/ExpensesModal";

export const TransactionList = () => {
  const { transactions, showLaborCosts } = useSelector(
    (state: RootState) => state.transactionList
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [transaction, setTransaction] = useState<ApiTransaction>();

  const columns = React.useMemo<DataTableColumn<ApiTransaction>[]>(
    () => [
      {
        Header: "Request",
        disableSortBy: true,
        maxWidth: 80,
        minWidth: 70,
        Cell: (data: any) => (
          <Link
            as={RouterLink}
            to={`/requests/${data.row.original.requestKey}`}
            onClick={(e) => e.stopPropagation()}
          >
            {data.row.original.requestKey}
          </Link>
        ),
      },
      {
        Header: "Who",
        disableSortBy: true,
        Cell: (cell: any) =>
          cell.row.original.type === ApiTransactionType.labor ? (
            <UserBadge value={cell.row.original.laborer} />
          ) : (
            <UserBadge value={cell.row.original.createdBy} />
          ),
      },
      {
        Header: "Transaction",
        disableSortBy: true,
        accessor: (row) => row.type,
        maxWidth: 80,
      },
      {
        Header: "Type (Reason)",
        disableSortBy: true,
        accessor: (row) => {
          let accesor: string = "";

          switch (row.type) {
            case ApiTransactionType.purchase:
              accesor = row.purchaseType;
              break;
            case ApiTransactionType.labor:
              accesor = row.laborType;
              break;
            case ApiTransactionType.issuance: {
              accesor = `${row.inventoryItemAdjustment.type} 
              ${
                row.inventoryItemAdjustment.reason
                  ? "(" + row.inventoryItemAdjustment.reason + ")"
                  : ""
              }
              `;
            }
          }
          return accesor;
        },
        maxWidth: 100,
      },
      {
        Header: "Quantity",
        disableSortBy: true,
        accessor: (row) => {
          let quantity: string = "";
          row.type === ApiTransactionType.purchase &&
            (quantity = `${row.quantity}`);
          row.type === ApiTransactionType.labor && (quantity = `${row.hours}h`);
          row.type === ApiTransactionType.issuance &&
            (quantity = `${row.inventoryItemAdjustment.quantity}`);

          return quantity;
        },
        maxWidth: 80,
      },
      {
        Header: "Unit Cost",
        disableSortBy: true,
        accessor: (row) => {
          let unit = "";
          (row.type === ApiTransactionType.purchase ||
            row.type === ApiTransactionType.issuance) &&
            (unit = `${row.unitCost}`);
          row.type === ApiTransactionType.labor && (unit = `${row.hourlyRate}`);
          if (row.type === ApiTransactionType.labor) {
            return showLaborCosts ? (
              <Text>${unit}</Text>
            ) : (
              <Skeleton height="20px" speed={0} />
            );
          }
          return <Text>${unit}</Text>;
        },
        maxWidth: 80,
      },
      {
        Header: "Total Cost",
        disableSortBy: true,
        accessor: (row) => {
          let total = "";
          (row.type === ApiTransactionType.purchase ||
            row.type === ApiTransactionType.labor ||
            row.type === ApiTransactionType.issuance) &&
            (total = `${row.total}`);
          return showLaborCosts ||
            row.type === ApiTransactionType.purchase ||
            row.type === ApiTransactionType.issuance ? (
            <Text>{formatCurrency(total)}</Text>
          ) : (
            <Skeleton height="20px" speed={0} />
          );
        },
        maxWidth: 80,
      },
      {
        Header: "Date",
        disableSortBy: true,
        accessor: "datePerformed",
        Cell: (cell: any) => (
          <DateBadge date={cell.value} showRelative={false} hideTime={true} />
        ),
      },
    ],
    [showLaborCosts]
  );

  const handleRowClick = useCallback(
    (transaction: ApiTransaction) => {
      setTransaction(transaction);
      onOpen();
    },
    [onOpen]
  );

  return (
    <Stack>
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="flex-start"
        justifyContent="space-between"
        mb={4}
      ></Box>
      <DataTable
        columns={columns}
        data={transactions}
        onRowClick={handleRowClick}
      />

      {!isOpen || !transaction ? null : transaction.type ===
        ApiTransactionType.labor ? (
        <LaborTransactionModal
          isOpen={isOpen}
          onClose={onClose}
          transaction={transaction}
        />
      ) : transaction.type === ApiTransactionType.purchase ? (
        <ExpensesModal
          isOpen={isOpen}
          handleCloseModal={onClose}
          purchaseId={transaction.id}
          workingPurchase={transaction}
        />
      ) : null}
    </Stack>
  );
};
