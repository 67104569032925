import { useMemo } from "react";
import { BoxProps, Text } from "@chakra-ui/react";

import {
  ApiPurchaseTransactionLineItem,
  ApiScannedInvoiceTransaction,
} from "@operations-hero/lib-api-client";

const LineItemTotal = ({
  lineItems,
  setSubTotal,
}: {
  lineItems: ApiPurchaseTransactionLineItem[];
  scanned?: ApiScannedInvoiceTransaction;
  setSubTotal?: (value: number) => void;
} & BoxProps) => {
  const subTotal = useMemo(() => {
    setSubTotal && setSubTotal(lineItems.reduce((sum, i) => sum + i.amount, 0));
    return lineItems.reduce((sum, i) => sum + i.amount, 0);
  }, [lineItems, setSubTotal]);

  return (
    <Text fontWeight="normal">
      {subTotal.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        style: "currency",
        currency: "USD",
      })}
    </Text>
  );
};

export default LineItemTotal;
