import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { ApiEquipmentItemStatus } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { FC, useCallback, useMemo } from "react";
import { StyledSelect } from "../selects/StyledSelect";

interface StatusCheckinSelectControlProps {
  label: string;
  name: string;
  value?: ApiEquipmentItemStatus;
  placeholder: string;
}

export const StatusCheckinSelectControl: FC<
  StatusCheckinSelectControlProps
> = ({ label, name, value, placeholder }) => {
  const { submitCount } = useFormikContext();
  const [field, meta, helper] = useField({ name, value });

  const handleOnChange = useCallback(
    (
      value: {
        label: ApiEquipmentItemStatus;
        value: ApiEquipmentItemStatus;
      } | null
    ) => {
      helper.setTouched(true);
      helper.setValue(value?.value);
    },
    [helper]
  );

  const labelOptions = useMemo(
    () => ({
      [ApiEquipmentItemStatus.damaged]: "Damaged",
      [ApiEquipmentItemStatus.lost]: "Lost",
      [ApiEquipmentItemStatus.normal]: "Normal conditions",
    }),
    []
  );

  const options = useMemo(
    () =>
      Object.keys(ApiEquipmentItemStatus).map((item) => ({
        label: labelOptions[item as ApiEquipmentItemStatus],
        value: item,
      })),
    [labelOptions]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <StyledSelect
        {...field}
        onChange={handleOnChange}
        options={options}
        placeholder={placeholder}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
