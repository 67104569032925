import {
  ApiBillingGroupEquipment,
  ApiBillingGroupEvent,
  ApiBillingGroupServices,
  ApiBillingGroupSpaces,
  ApiBillingGroupType,
  ApiClient,
  ApiRateGroup,
  CreateApiBillingGroup,
  UpdateApiBillingGroup,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { LoadingStatus } from "../types/redux-slices-types";

export interface LoadRateSheetProps {
  accountId: string;
  apiClient: ApiClient;
  type: ApiBillingGroupType;
}

export const loadRateSheet = createAsyncThunk(
  "account/load-rate-sheet",
  async (params: LoadRateSheetProps, thunkAPI) => {
    const { apiClient, accountId, type } = params;

    const state: RateSheetSliceProps = (thunkAPI.getState() as RootState)
      .rateSheetSlice;
    let includeInactive: boolean = false;
    let page: number = 1;
    let pageSize: number = 10;
    if (type === ApiBillingGroupType.space) {
      includeInactive = state.venue.filters.includeInactive;
      page = state.venue.filters.page;
      pageSize = state.venue.filters.pageSize;
    }

    if (type === ApiBillingGroupType.service) {
      includeInactive = state.service.filters.includeInactive;
      page = state.service.filters.page;
      pageSize = state.service.filters.pageSize;
    }

    if (type === ApiBillingGroupType.equipment) {
      includeInactive = state.equipment.filters.includeInactive;
      page = state.equipment.filters.page;
      pageSize = state.equipment.filters.pageSize;
    }

    if (type === ApiBillingGroupType.event) {
      includeInactive = state.event.filters.includeInactive;
      page = state.event.filters.page;
      pageSize = state.event.filters.pageSize;
    }
    const filters = {
      type: type,
      search: state.search,
      includeInactive,
      page,
      pageSize,
    };

    const response = await apiClient.findBillingGroup(accountId, filters);
    return { response, type };
  }
);

export interface CreateBillingGroupProps {
  accountId: string;
  apiClient: ApiClient;
  billingGroup: CreateApiBillingGroup;
}

export const createBillingGroup = createAsyncThunk(
  "account/new-billing-group",
  async (params: CreateBillingGroupProps) => {
    const { apiClient, accountId, billingGroup } = params;
    const response = await apiClient.createBillingGroup(
      accountId,
      billingGroup
    );
    return response;
  }
);

export interface DeactivateRateProps {
  billingGroupId: string;
  accountId: string;
  apiClient: ApiClient;
  type: ApiBillingGroupType;
}

export const deactivateBillingGroup = createAsyncThunk(
  "account/deactivate-billing-group",
  async (params: DeactivateRateProps) => {
    const { billingGroupId, accountId, apiClient, type } = params;

    await apiClient.deactivateBillingGroup(accountId, billingGroupId);
    return { type, billingGroupId };
  }
);

export const reactivateBillingGroup = createAsyncThunk(
  "account/reactivate-billing-group",
  async (params: DeactivateRateProps) => {
    const { billingGroupId, accountId, apiClient, type } = params;

    const billingGroup = await apiClient.reactivateBillingGroup(
      accountId,
      billingGroupId
    );
    return { type, billingGroup };
  }
);

export interface UpdateBillingRateProps {
  accountId: string;
  apiClient: ApiClient;
  billingGroupId: string;
  type: ApiBillingGroupType;
  billingGroup: UpdateApiBillingGroup;
}

export const updateBillingGroup = createAsyncThunk(
  "account/update-billing-group",
  async (params: UpdateBillingRateProps) => {
    const { accountId, apiClient, billingGroup, billingGroupId, type } = params;

    const updatedBillingGroup = await apiClient.updateBillingGroup(
      accountId,
      billingGroupId,
      billingGroup as UpdateApiBillingGroup
    );

    return { updatedBillingGroup, type };
  }
);

/********************SLICE********************* */
export interface BillingGroupSpacesModalProps {
  total: number;
  isOpen: boolean;
  billingGroupName: string;
  type?: ApiBillingGroupType;
  billingGroupId: string;
}

interface CommonFiltersProps {
  page: number;
  pageSize: number;
  includeInactive: boolean;
  type?: ApiBillingGroupType;
}

interface RateSheetSliceProps {
  search: string;
  workingRateId: string | null;
  workingType: ApiBillingGroupType | null;
  billingModalIsOpen: boolean;
  venue: {
    total: number;
    filters: CommonFiltersProps;
    usedSpaces: string[];
    usedSpacesAllGroups: string[];
    data: ApiBillingGroupSpaces[];
    loading: LoadingStatus;
  };
  service: {
    total: number;
    usedServices: string[];
    usedServicesAllGroups: string[];
    filters: CommonFiltersProps;
    data: ApiBillingGroupServices[];
    loading: LoadingStatus;
  };
  equipment: {
    total: number;
    filters: CommonFiltersProps;
    usedEquipment: string[];
    usedEquipmentAllGroups: string[];
    data: ApiBillingGroupEquipment[];
    loading: LoadingStatus;
  };
  event: {
    total: number;
    filters: CommonFiltersProps;
    data: ApiBillingGroupEvent[];
    loading: LoadingStatus;
  };
  billingGroupSpaces: BillingGroupSpacesModalProps;
  spaceTotal: number;
  spacePage: number;
  spacePageSize: number;
  rateGroups: ApiRateGroup[];
}

const DEFAULT_RATE_FILTERS: CommonFiltersProps = {
  page: 1,
  pageSize: 10,
  includeInactive: false,
};

export const RATE_SHEET_INITIAL_STATE: RateSheetSliceProps = {
  search: "",
  workingType: null,
  workingRateId: null,
  billingModalIsOpen: false,
  venue: {
    data: [] as ApiBillingGroupSpaces[],
    total: 0,
    usedSpaces: [],
    usedSpacesAllGroups: [],
    loading: "idle",
    filters: { ...DEFAULT_RATE_FILTERS, type: ApiBillingGroupType.space },
  },
  service: {
    data: [] as ApiBillingGroupServices[],
    total: 0,
    loading: "idle",
    usedServices: [],
    usedServicesAllGroups: [],
    filters: { ...DEFAULT_RATE_FILTERS, type: ApiBillingGroupType.service },
  },
  equipment: {
    data: [] as ApiBillingGroupEquipment[],
    total: 0,
    loading: "idle",
    usedEquipment: [],
    usedEquipmentAllGroups: [],
    filters: { ...DEFAULT_RATE_FILTERS, type: ApiBillingGroupType.equipment },
  },
  event: {
    data: [] as ApiBillingGroupEvent[],
    total: 0,
    loading: "idle",
    filters: { ...DEFAULT_RATE_FILTERS, type: ApiBillingGroupType.event },
  },
  billingGroupSpaces: {
    total: 0,
    isOpen: false,
    type: undefined,
    billingGroupId: "",
    billingGroupName: "",
  },
  spaceTotal: 0,
  spacePage: 1,
  spacePageSize: 20,
  rateGroups: [],
};

export const rateSheetSlice = createSlice({
  name: "equipments-slice",
  initialState: { ...RATE_SHEET_INITIAL_STATE },
  reducers: {
    unloadRateSheet: (state) => {
      state = RATE_SHEET_INITIAL_STATE;
    },
    setWorkingType: (
      state: RateSheetSliceProps,
      action: PayloadAction<ApiBillingGroupType | null>
    ) => {
      state.workingType = action.payload;
    },
    setSearch: (state: RateSheetSliceProps, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setIncludeInactive: (
      state: RateSheetSliceProps,
      action: PayloadAction<{ type: ApiBillingGroupType; value: boolean }>
    ) => {
      const { type, value } = action.payload;
      state.workingType = type;
      if (type === ApiBillingGroupType.space) {
        state.venue.filters.includeInactive = value;
      }

      if (type === ApiBillingGroupType.service) {
        state.service.filters.includeInactive = value;
      }

      if (type === ApiBillingGroupType.equipment) {
        state.equipment.filters.includeInactive = value;
      }

      if (type === ApiBillingGroupType.event) {
        state.event.filters.includeInactive = value;
      }
    },
    setBillingModalIsOpen: (
      state: RateSheetSliceProps,
      action: PayloadAction<boolean>
    ) => {
      state.billingModalIsOpen = action.payload;
    },
    setWorkingRateId: (
      state: RateSheetSliceProps,
      action: PayloadAction<string | null>
    ) => {
      state.workingRateId = action.payload;
    },
    setSpacePagination: (
      state: RateSheetSliceProps,
      action: PayloadAction<{ page: number; pageSize: number }>
    ) => {
      const { page, pageSize } = action.payload;
      state.spacePageSize = pageSize;
      state.spacePage = page;
    },
    setSpaceTotalPagination: (
      state: RateSheetSliceProps,
      action: PayloadAction<number>
    ) => {
      state.spaceTotal = action.payload;
    },
    SetBillingGroupSpaces: (
      state: RateSheetSliceProps,
      action: PayloadAction<Partial<BillingGroupSpacesModalProps>>
    ) => {
      state.billingGroupSpaces = {
        ...state.billingGroupSpaces,
        ...action.payload,
      };
    },
    setUsedItems: (
      state: RateSheetSliceProps,
      action: PayloadAction<{ items: string[]; type: ApiBillingGroupType }>
    ) => {
      const { type, items } = action.payload;
      if (type === ApiBillingGroupType.service) {
        state.service.usedServices = items;
        state.service.usedServicesAllGroups = items;
      }
      if (type === ApiBillingGroupType.equipment) {
        state.equipment.usedEquipment = items;
        state.equipment.usedEquipmentAllGroups = items;
      }
      if (type === ApiBillingGroupType.space) {
        state.venue.usedSpaces = items;
        state.venue.usedSpacesAllGroups = items;
      }
    },
    addOrRemoveUsedItems: (
      state: RateSheetSliceProps,
      action: PayloadAction<{ item: string; type: ApiBillingGroupType }>
    ) => {
      const { type, item } = action.payload;
      if (type === ApiBillingGroupType.service) {
        const usedServices = state.service.usedServices;
        const index = usedServices.indexOf(item);
        usedServices.includes(item)
          ? usedServices.splice(index, 1)
          : usedServices.push(item);
        state.service.usedServices = usedServices;
      }
      if (type === ApiBillingGroupType.equipment) {
        const usedEquipment = state.equipment.usedEquipment;
        const index = usedEquipment.indexOf(item);
        usedEquipment.includes(item)
          ? usedEquipment.splice(index, 1)
          : usedEquipment.push(item);
        state.equipment.usedEquipment = usedEquipment;
      }
      if (type === ApiBillingGroupType.space) {
        const usedSpaces = state.venue.usedSpaces;
        const index = usedSpaces.indexOf(item);
        usedSpaces.includes(item)
          ? usedSpaces.splice(index, 1)
          : usedSpaces.push(item);
        state.venue.usedSpaces = usedSpaces;
      }
    },
    unloadUsedItems: (state: RateSheetSliceProps) => {
      state.service.usedServices = state.service.usedServicesAllGroups;
      state.equipment.usedEquipment = state.equipment.usedEquipmentAllGroups;
      state.venue.usedSpaces = state.venue.usedSpacesAllGroups;
    },
    setRateFilter: (
      state: RateSheetSliceProps,
      action: PayloadAction<Partial<CommonFiltersProps>>
    ) => {
      const { type } = action.payload;
      if (!type) return;

      state.workingType = type;
      if (type === ApiBillingGroupType.space) {
        state.venue.filters = { ...state.venue.filters, ...action.payload };
      }

      if (type === ApiBillingGroupType.service) {
        state.service.filters = { ...state.service.filters, ...action.payload };
      }

      if (type === ApiBillingGroupType.equipment) {
        state.equipment.filters = {
          ...state.equipment.filters,
          ...action.payload,
        };
      }

      if (type === ApiBillingGroupType.event) {
        state.event.filters = {
          ...state.event.filters,
          ...action.payload,
        };
      }
    },
    setRateGroups: (
      state: RateSheetSliceProps,
      action: PayloadAction<ApiRateGroup[]>
    ) => {
      state.rateGroups = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createBillingGroup.fulfilled, (state, action) => {
      const { type } = action.payload;
      if (type === ApiBillingGroupType.space) {
        state.venue.data.unshift(action.payload as ApiBillingGroupSpaces);
      }

      if (type === ApiBillingGroupType.service) {
        state.service.data.unshift(action.payload as ApiBillingGroupServices);
      }

      if (type === ApiBillingGroupType.equipment) {
        state.equipment.data.unshift(
          action.payload as ApiBillingGroupEquipment
        );
      }

      if (type === ApiBillingGroupType.event) {
        state.event.data.unshift(action.payload as ApiBillingGroupEvent);
      }
    });

    builder.addCase(loadRateSheet.pending, (state) => {
      const { workingType } = state;

      if (!workingType) {
        state.venue.loading = "pending";
        state.service.loading = "pending";
        state.equipment.loading = "pending";
        state.event.loading = "pending";
        return;
      }

      if (workingType === ApiBillingGroupType.space) {
        state.venue.loading = "pending";
      }
      if (workingType === ApiBillingGroupType.service) {
        state.service.loading = "pending";
      }
      if (workingType === ApiBillingGroupType.equipment) {
        state.equipment.loading = "pending";
      }
      if (workingType === ApiBillingGroupType.event) {
        state.event.loading = "pending";
      }
    });

    builder.addCase(loadRateSheet.rejected, (state) => {
      const { workingType } = state;
      if (workingType === ApiBillingGroupType.space) {
        state.venue.loading = "failed";
      }
      if (workingType === ApiBillingGroupType.service) {
        state.service.loading = "failed";
      }
      if (workingType === ApiBillingGroupType.equipment) {
        state.equipment.loading = "failed";
      }
      if (workingType === ApiBillingGroupType.event) {
        state.event.loading = "failed";
      }
    });

    builder.addCase(loadRateSheet.fulfilled, (state, action) => {
      const { response, type } = action.payload;
      if (type === ApiBillingGroupType.space) {
        state.venue.data = response.data as ApiBillingGroupSpaces[];
        state.venue.total = response.total;
        state.venue.loading = "succeeded";
      }
      if (type === ApiBillingGroupType.service) {
        state.service.data = response.data as ApiBillingGroupServices[];
        state.service.total = response.total;
        state.service.loading = "succeeded";
      }
      if (type === ApiBillingGroupType.equipment) {
        state.equipment.data = response.data as ApiBillingGroupEquipment[];
        state.equipment.total = response.total;
        state.equipment.loading = "succeeded";
      }
      if (type === ApiBillingGroupType.event) {
        state.event.data = response.data as ApiBillingGroupEvent[];
        state.event.total = response.total;
        state.event.loading = "succeeded";
      }
    });

    builder.addCase(deactivateBillingGroup.fulfilled, (state, action) => {
      const { type, billingGroupId } = action.payload;
      if (type === ApiBillingGroupType.space) {
        const index = state.venue.data.findIndex(
          (billingRate) => billingRate.id === billingGroupId
        );

        if (index !== -1) {
          state.venue.filters.includeInactive
            ? (state.venue.data[index].active = false)
            : state.venue.data.splice(index, 1);
        }
        return;
      }

      if (type === ApiBillingGroupType.service) {
        const index = state.service.data.findIndex(
          (billingRate) => billingRate.id === billingGroupId
        );

        if (index !== -1) {
          state.service.filters.includeInactive
            ? (state.service.data[index].active = false)
            : state.service.data.splice(index, 1);
        }
      }

      if (type === ApiBillingGroupType.equipment) {
        const index = state.equipment.data.findIndex(
          (billingRate) => billingRate.id === billingGroupId
        );

        if (index !== -1) {
          state.equipment.filters.includeInactive
            ? (state.equipment.data[index].active = false)
            : state.equipment.data.splice(index, 1);
        }
      }

      if (type === ApiBillingGroupType.event) {
        const index = state.event.data.findIndex(
          (billingRate) => billingRate.id === billingGroupId
        );

        if (index !== -1) {
          state.event.filters.includeInactive
            ? (state.event.data[index].active = false)
            : state.event.data.splice(index, 1);
        }
      }
    });

    builder.addCase(reactivateBillingGroup.fulfilled, (state, action) => {
      const { type, billingGroup } = action.payload;
      if (type === ApiBillingGroupType.space) {
        const index = state.venue.data.findIndex(
          (billingRate) => billingRate.id === billingGroup.id
        );

        if (index !== -1) {
          state.venue.data[index] = billingGroup as ApiBillingGroupSpaces;
        }
        return;
      }

      if (type === ApiBillingGroupType.service) {
        const index = state.service.data.findIndex(
          (billingRate) => billingRate.id === billingGroup.id
        );

        if (index !== -1) {
          state.service.data[index] = billingGroup as ApiBillingGroupServices;
        }
      }

      if (type === ApiBillingGroupType.equipment) {
        const index = state.equipment.data.findIndex(
          (billingRate) => billingRate.id === billingGroup.id
        );

        if (index !== -1) {
          state.equipment.data[index] =
            billingGroup as ApiBillingGroupEquipment;
        }
      }

      if (type === ApiBillingGroupType.event) {
        const index = state.event.data.findIndex(
          (billingRate) => billingRate.id === billingGroup.id
        );

        if (index !== -1) {
          state.event.data[index] = billingGroup as ApiBillingGroupEvent;
        }
      }
    });

    builder.addCase(updateBillingGroup.fulfilled, (state, action) => {
      const { type, updatedBillingGroup } = action.payload;
      if (type === ApiBillingGroupType.space) {
        const index = state.venue.data.findIndex(
          (billingRate) => billingRate.id === updatedBillingGroup.id
        );

        if (index !== -1) {
          state.venue.data[index] =
            updatedBillingGroup as ApiBillingGroupSpaces;
        }
        return;
      }

      if (type === ApiBillingGroupType.service) {
        const index = state.service.data.findIndex(
          (billingRate) => billingRate.id === updatedBillingGroup.id
        );

        if (index !== -1) {
          state.service.data[index] =
            updatedBillingGroup as ApiBillingGroupServices;
        }
      }

      if (type === ApiBillingGroupType.equipment) {
        const index = state.equipment.data.findIndex(
          (billingRate) => billingRate.id === updatedBillingGroup.id
        );

        if (index !== -1) {
          state.equipment.data[index] =
            updatedBillingGroup as ApiBillingGroupEquipment;
        }
      }

      if (type === ApiBillingGroupType.event) {
        const index = state.event.data.findIndex(
          (billingRate) => billingRate.id === updatedBillingGroup.id
        );

        if (index !== -1) {
          state.event.data[index] = updatedBillingGroup as ApiBillingGroupEvent;
        }
      }
    });
  },
});

export const {
  unloadRateSheet,
  addOrRemoveUsedItems,
  unloadUsedItems,
  setRateFilter,
  setSearch,
  setIncludeInactive,
  setWorkingRateId,
  setBillingModalIsOpen,
  SetBillingGroupSpaces,
  setWorkingType,
  setUsedItems,
  setRateGroups,
  setSpacePagination,
  setSpaceTotalPagination,
} = rateSheetSlice.actions;

export default rateSheetSlice.reducer;
