import { Box, Text } from "@chakra-ui/react";
import {
  ApiFundingSource,
  ApiPagingFundingSourceOptions,
} from "@operations-hero/lib-api-client";
import { AsyncSelect } from "chakra-react-select";
import { useCallback, useMemo } from "react";
import { debounce } from "../../utils/debounce";
import { useAuthentication } from "../auth/AuthProvider";
import { ColoredBadge } from "../badges/ColoredBadge";
import { FundingSourceBadge } from "../badges/FundingSourceBadge";
import {
  commonStyles,
  createOptionComponent,
  getCustomSelectComponents,
} from "./select-overrides";
import {
  CustomOptionsProps,
  CustomSelectComponentProp,
  SingleValueSelect,
} from "./select-overrides-types";

export interface FundingSourceAutocompleteProps {
  value: ApiFundingSource | null;
  name?: string;
  placeholder?: string;
  onChange: (value: ApiFundingSource | null) => void;
  isInvalid?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  defaultOptions?: ApiFundingSource[] | boolean;
  loadOptionParams?: Partial<ApiPagingFundingSourceOptions>;
}

const CustomOptionComponent = createOptionComponent<ApiFundingSource>(
  ({ value }) => <FundingSourceBadge value={value} />
);

const CustomSingleValueComponent = (
  props: SingleValueSelect<ApiFundingSource>
) => {
  const { data, innerProps } = props;
  const { colorId, name } = data;
  return (
    <Box {...innerProps} maxW="90%">
      <Box w="full" isTruncated>
        <ColoredBadge
          hexColor={colorId}
          bgLightness={30}
          colorDarkness={30}
          size="lg"
          fontSize="smaller"
          alignContent="center"
          alignItems="center"
          isTruncated
          maxW="100%"
        >
          <Text casing="uppercase" fontWeight="bold" isTruncated>
            {name}
          </Text>
        </ColoredBadge>
      </Box>
    </Box>
  );
};

export function FundingSourceAutocomplete({
  name,
  value,
  placeholder,
  onChange,

  isInvalid,
  isDisabled,
  isClearable,

  defaultOptions = true,

  loadOptionParams,
}: FundingSourceAutocompleteProps) {
  const { currentAccount, apiClient } = useAuthentication();

  const loadOptions = useCallback(
    (inputValue: string, cb: any) => {
      apiClient
        .findFundingSources(currentAccount.id, {
          search: inputValue,
          pageSize: 50,
          ...loadOptionParams,
        })
        .then((response) => {
          cb(response.data);
        });
    },
    [apiClient, currentAccount.id, loadOptionParams]
  );

  const debouncedLoadOptions = debounce(loadOptions, 300);

  const handleChange = useCallback(
    (newValue: ApiFundingSource | null) => {
      onChange(newValue);
    },
    [onChange]
  );

  const getOptionValue = useCallback((venue: ApiFundingSource) => venue.id, []);

  const components = useMemo((): CustomSelectComponentProp => {
    return {
      ...getCustomSelectComponents(),
      Option: (props: CustomOptionsProps<ApiFundingSource>) =>
        CustomOptionComponent(props),
      SingleValue: (props: SingleValueSelect<ApiFundingSource>) =>
        CustomSingleValueComponent(props),
    };
  }, []);

  return (
    <AsyncSelect
      name={name ?? "fundingSourceAutocomplete"}
      defaultOptions={defaultOptions}
      value={value}
      cacheOptions={true}
      getOptionValue={getOptionValue}
      loadOptions={debouncedLoadOptions}
      onChange={handleChange}
      components={components}
      isClearable={isClearable}
      isInvalid={isInvalid}
      isMulti={false}
      placeholder={placeholder}
      isDisabled={isDisabled}
      chakraStyles={commonStyles}
    />
  );
}
