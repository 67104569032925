interface TimelineEntryFieldProps {
  [key: string]: string;
}

export const TimeLineEntryFields: TimelineEntryFieldProps = {
  assignees: "Assignees",
  location: "Location",
  budget: "Budget",
  priority: "Priority",
  reason: "Reason",
  reportingCategory: "Reporting Category",
  requester: "Requester",
  status: "Status",
  started: "Started Date",
  summary: "Summary",
  type: "Type",
  worflow: "Worflow",
  cost: "Cost",
  estimatedCost: "Estimated Cost",
  estimatedHours: "Estimated Hours",
  taskbook: "Task Book",
  task: "Task",
  attachment: "Attachment",
  comment: "Comment",
  asset: "Asset",
  labor: "Labor",
  purchase: "Purchase",
  metadata: "Metadata",
  scheduling: "Scheduling",
  name: "Name",
  eventGroup: "Event Group",
  description: "Description",
  start: "Start",
  end: "End",
  spaces: "Spaces",
  eventOccurrence: "Event Occurrence",
  services: "Services",
  rentableEquipment: "Rentable Equipment",
  isPrivate: "Show Public Calendar",
  completed: "Completed Date",
  lineItem: "Line Item",
  payment: "Payment",
  lineItems: "Line Items",
  due: "Due Date",
  allowAssets: "Allow Assets",
  allowRequestBudget: "Allow Budget on a Request",
  allowAutomaticDueDate: "Allow Automatic Due Date",
  allowComments: "Allow Comments",
  allowReporting: "Allow Reporting",
  allowRequesting: "Allow Requesting",
  allowScheduling: "Allow Scheduling",
  allowPurchaseTransactions: "Allow Purchase Transactions",
  allowLaborTransactions: "Allow Labor Transactions",
  allowBudgetsOnTransactions: "Allow Budgets On Transactions",
  allowUsersToSubmitRequests: "Allow Users To Submit Requests",
  allowEstimatesPurchases: "Allow Estimates Purchases",
  allowEstimatesLabor: "Allow Estimates Labor",
  allowTaskbooks: "Allow Taskbooks",
  requireTaskbooksCompletion: "Require Taskbooks Completion",
  priorityLow: "Priority Low",
  priorityStandard: "Priority Standard",
  priorityImportant: "Priority Important",
  priorityExpedite: "Priority Expedite",
  requireApproverForNewRequests: "Require Approver For New Requests",
  requireReviewerBeforeClose: "Require Reviewer Before Close",
  allowAssetsOnNewRequest: "Allow Assets On New Request",
  externalId: "External Id",
  notes: "Notes",
  condition: "Condition",
  manufacturer: "Manufacturer",
  model: "Model",
  serial: "Serial",
  vendor: "Vendor",
  serviceProvider: "Service Provider",
  purchaseDate: "Purchase Date",
  purchaseCost: "Purchase Cost",
  purchaseOrder: "Purchase Order",
  invoiceNumber: "Invoice Number",
  inServiceDate: "In Service Date",
  warrantyExpiration: "Warranty Expiration",
  targetRenewalDate: "Target Renewal Date",
  renewalCost: "Renewal Cost",
  labels: "Labels",
  selfAssignRequestsCreatedByTechnicians:
    "Requests made by Technicians should be automatically assigned to them",
  policy: "Policy",
  amountPaid: "Amount paid",
  amountRemaining: "Amount remaining",
  issuance: "Issuance",
  inventoryRequest: "Inventory Request",
  units: "Units",
  supplierItemNumber: "Supplier Item Number",
  identifiers: "Identifiers",
  requireApproverForInventoryRequests:
    "Require Approver For Inventory Requests",
  billingContact: "Billing Contact",
  revisingInvoice: "Revise Invoice",
  supervisors: "Supervisor",
  projectId: "Project",
  followedBy: "Watcher",
  note: "Note",
  inventoryOrderNumber: "Inventory Order Number",
  unitCost: "Unit Cost",
  fiscalYearStart: "Fiscal Year",
};
