import { Text } from "@chakra-ui/react";
import {
  ApiFundingSource,
  ApiFundingSourceSummary,
} from "@operations-hero/lib-api-client";
import { FC } from "react";
import { ColoredBadge } from "./ColoredBadge";

type FundingSourceBadgeProps = {
  value: ApiFundingSource | ApiFundingSourceSummary;
};

export const FundingSourceBadge: FC<FundingSourceBadgeProps> = ({ value }) => {
  const { colorId, name } = value;

  return (
    <ColoredBadge
      hexColor={colorId}
      bgLightness={30}
      colorDarkness={25}
      size="lg"
      fontSize="smaller"
      alignContent="center"
      alignItems="center"
      isTruncated
    >
      <Text casing="uppercase" fontWeight="bold" isTruncated>
        {name}
      </Text>
    </ColoredBadge>
  );
};
