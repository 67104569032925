import {
  ActionReducerMapBuilder,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";

import { ApiClient, ApiFundingSource } from "@operations-hero/lib-api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { Attachment } from "../../../components/attachments/Attachments";
import { LoadingStatus } from "../types";
import {
  AttachmentHandlerProps,
  findAttachmentsHandlers,
} from "./attachments/findAttachments.thunk";
import { removeAttachmentHandlers } from "./attachments/removeAttachments.thunk";
import { updateFundingSource } from "./funding-source-form.slice";

type GetfundingSourceParams = {
  apiClient: ApiClient;
  id: string;
};

export const getFundingSource = createAsyncThunk<
  ApiFundingSource,
  GetfundingSourceParams
>("funding-source/get", async ({ apiClient, id }, thunkAPI) => {
  const rootState = thunkAPI.getState() as RootState;
  const { currentAccount } = rootState.auth;
  const fundingSource = await apiClient.getFundingSource(currentAccount.id, id);
  return fundingSource;
});

type FundingSourcePageSliceProps = {
  fundingSource: ApiFundingSource | null;
  fundingSourceLoadingStatus: LoadingStatus;
  attachments: Attachment[];
  attachmentsLoadingStatus: LoadingStatus;
};

const initialState: FundingSourcePageSliceProps = {
  fundingSource: null,
  attachments: [],
  fundingSourceLoadingStatus: "idle",
  attachmentsLoadingStatus: "idle",
};

const fundingSourcePageSlice = createSlice({
  name: "funding-source-page",
  initialState: initialState,
  reducers: {
    unload: (state) => {
      state.fundingSource = initialState.fundingSource;
      state.attachments = [];
      state.fundingSourceLoadingStatus =
        initialState.fundingSourceLoadingStatus;
      state.attachmentsLoadingStatus = initialState.attachmentsLoadingStatus;
    },
    addAttachment: (state, action: PayloadAction<Attachment>) => {
      const { attachments } = state;

      if (
        attachments.some(
          (attachement) =>
            attachement.isNew === true &&
            attachement.name === action.payload.name
        )
      ) {
        return;
      }
      state.attachments.push(action.payload);
    },
    updateAttachment: (state, action: PayloadAction<Attachment>) => {
      const index = state.attachments.findIndex(
        (x) => x.isNew === true && x.name === action.payload.name
      );

      state.attachments[index] = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFundingSource.pending, (state) => {
      state.fundingSourceLoadingStatus = "pending";
    });
    builder.addCase(getFundingSource.rejected, (state) => {
      state.fundingSourceLoadingStatus = "rejected";
      state.attachmentsLoadingStatus = "rejected";
    });
    builder.addCase(getFundingSource.fulfilled, (state, action) => {
      state.fundingSource = action.payload;
      state.fundingSourceLoadingStatus = "fulfilled";
    });
    builder.addCase(updateFundingSource.fulfilled, (state, action) => {
      state.fundingSource = action.payload;
    });

    removeAttachmentHandlers(builder);
    findAttachmentsHandlers(
      builder as ActionReducerMapBuilder<AttachmentHandlerProps>
    );
  },
});

export const { unload, addAttachment, updateAttachment } =
  fundingSourcePageSlice.actions;
export default fundingSourcePageSlice.reducer;
