import { Center } from "@chakra-ui/react";
import { Spinner } from "@chakra-ui/spinner";
import { ApiAccountSettings } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { RootState } from "../../store";
import { DEFAULT_DASHBOARD } from "../user-profile/UserProfilePage";

export enum DashboardEnum {
  requests = "herohq",
  events = "eventhq",
}

export const LadingPageHandler: FC = () => {
  const navigate = useNavigate();
  const [isInit, setIsInit] = useState(false);
  const [userSettings, setUserSettings] = useState<ApiAccountSettings>();

  const { isVenueManager } = useSelector(
    (state: RootState) => state.localCache.eventsHandler.venueManagers
  );

  const {
    apiClient,
    currentAccount,
    currentUser,
    isEventAdmin,
    isProductAdmin,
  } = useAuthentication();

  const handleRedirectDashboard = useCallback(() => {
    if (!isInit) return;

    const userDasboardSetting = userSettings
      ? (userSettings[DEFAULT_DASHBOARD] as keyof typeof DashboardEnum)
      : undefined;

    if (userDasboardSetting) {
      navigate(`/dashboard/${DashboardEnum[userDasboardSetting]}`);
      return;
    }

    if (!isProductAdmin && (isEventAdmin || isVenueManager)) {
      navigate(`/dashboard/${DashboardEnum.events}`);
      return;
    }

    navigate(`/dashboard/${DashboardEnum.requests}`);
  }, [
    isEventAdmin,
    isInit,
    isProductAdmin,
    isVenueManager,
    navigate,
    userSettings,
  ]);

  useEffect(() => {
    if (isInit) return;

    apiClient
      .getCurrentUserSettings(currentAccount.id, [DEFAULT_DASHBOARD])
      .then((response) => {
        setUserSettings(response);
        setIsInit(true);
      });
  }, [apiClient, currentAccount.id, currentUser.id, isInit]);

  useEffect(() => {
    handleRedirectDashboard();
  }, [handleRedirectDashboard]);

  return (
    <>
      {!isInit && (
        <Center alignItems="center" h="calc(100vh - 250px)">
          <Spinner color="blue.500" />
        </Center>
      )}
    </>
  );
};
