import { EventInput } from "@fullcalendar/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { LoadingStatus } from "../project-list";
import { getProject } from "../project-page";
import { findProjectRequests } from "../requests/findRequests.thunk";
import {
  addBulkRequestsToSchedule,
  addRequestToSchedule,
  updateProjectSchedule,
} from "./scheduling-requests.thunk";
import { isValidSchedulingMetadata, SchedulingItemMetadata } from "./types";

type SchedulingPageProps = {
  requestsCache: { [key: string]: ApiRequest };
  schedulingCache: { [key: string]: SchedulingItemMetadata };
  initState: "idle" | "pending" | "fullfiled" | "rejected";
  requestAdded: {
    status: LoadingStatus;
    requestId: string | null;
  };
  bulkRequestsAdded: {
    status: LoadingStatus;
    requestIds: string[];
  };

  initialEvents: EventInput[];
};

const initialState: SchedulingPageProps = {
  requestsCache: {},
  schedulingCache: {},
  requestAdded: {
    status: "idle",
    requestId: null,
  },
  bulkRequestsAdded: {
    status: "idle",
    requestIds: [],
  },
  initState: "idle",
  initialEvents: [],
};

const projectScheduling = createSlice({
  name: "project-scheduling",
  initialState: initialState,
  reducers: {
    unloadProjectScheduling: (state) => {
      state.requestsCache = {};
      state.schedulingCache = {};
      state.initState = "idle";
      state.initialEvents = [];
      state.requestAdded = {
        status: "idle",
        requestId: null,
      };
    },
    unloadRequestAdded: (state) => {
      state.requestAdded.status = "idle";
      state.requestAdded.requestId = null;
    },
    unloadBulkRequestsAdded: (state) => {
      state.bulkRequestsAdded.status = "idle";
      state.bulkRequestsAdded.requestIds = [];
    },

    setSchedulingState: (
      state,
      action: PayloadAction<SchedulingPageProps["initState"]>
    ) => {
      state.initState = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(addRequestToSchedule.fulfilled, (state, action) => {
      const { request, requestAsScheduleItem } = action.payload;
      const { id } = request;
      state.requestsCache[id] = request;

      if (requestAsScheduleItem) {
        state.schedulingCache[id] = requestAsScheduleItem;
      }
      state.requestAdded = {
        status: "fulfilled",
        requestId: id,
      };
    });

    builder.addCase(addRequestToSchedule.rejected, (state, action) => {
      if (!action.payload) return;
      const { request } = action.payload;
      const { id } = request;
      state.requestsCache[id] = request;
      state.requestAdded = {
        status: "fulfilled",
        requestId: id,
      };
    });

    builder.addCase(addBulkRequestsToSchedule.fulfilled, (state, action) => {
      const { requestsAsScheduleItems, scheduledRequestsIds } = action.payload;
      for (const requestId of scheduledRequestsIds) {
        if (requestsAsScheduleItems[requestId]) {
          state.schedulingCache[requestId] = requestsAsScheduleItems[requestId];
        }
      }
      state.bulkRequestsAdded = {
        status: "fulfilled",
        requestIds: scheduledRequestsIds,
      };
    });

    builder.addCase(addBulkRequestsToSchedule.rejected, (state, action) => {
      state.bulkRequestsAdded = {
        status: "fulfilled",
        requestIds: [],
      };
    });

    builder.addCase(updateProjectSchedule.fulfilled, (state, action) => {
      const { item } = action.payload;
      state.schedulingCache[item.id] = item;
    });

    builder.addCase(findProjectRequests.fulfilled, (state, action) => {
      const {
        requests: { data },
      } = action.payload;
      const builtRequestCache = data.reduce<
        SchedulingPageProps["requestsCache"]
      >((cache, r) => {
        cache[r.id] = r;
        return cache;
      }, {});

      state.requestsCache = { ...state.requestsCache, ...builtRequestCache };
      if (state.initState === "idle") {
        state.initialEvents = Object.keys(state.schedulingCache).map((key) => {
          const item = state.schedulingCache[key];
          let scheduledStart = moment(item.start);
          let scheduledEnd = moment(item.end);

          const isSameDay = scheduledStart.isSame(scheduledEnd, "date");
          if (isSameDay) {
            scheduledStart = moment(item.start).startOf("day");
            scheduledEnd = moment(item.start).add({ day: 1 }).startOf("day");
          }

          const eventItem: EventInput = {
            ...builtRequestCache[key],
            start: scheduledStart.toDate(),
            end: scheduledEnd.toDate(),
            title: "",
            resourceId: item.rowId,
          };
          return eventItem;
        });
        state.initState = "fullfiled";
      }
    });

    builder.addCase(getProject.fulfilled, (state, action) => {
      const { scheduling } = action.payload;
      if (!isValidSchedulingMetadata(scheduling)) {
        state.schedulingCache = {};
      } else {
        state.schedulingCache = scheduling.items;
      }
    });
  },
});

export const {
  unloadRequestAdded,
  setSchedulingState,
  unloadProjectScheduling,
  unloadBulkRequestsAdded,
} = projectScheduling.actions;

export default projectScheduling.reducer;
