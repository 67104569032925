import {
  Alert,
  AlertDescription,
  AlertProps,
  AlertTitle,
  AlertTitleProps,
  Box,
  BoxProps,
  Icon,
  IconProps,
  ListItem,
  Text,
  UnorderedList,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, useCallback } from "react";
import { MdWarning } from "react-icons/md";

interface ConflictAlertProps {
  title?: string;
  boxProps?: BoxProps;
  alertProps?: AlertProps;
  includeDescription?: boolean;
  includeDescriptionForInactiveVenue?: boolean;
  showConflictsLink?: boolean;
  alertTitleProps?: AlertTitleProps;
  iconProps?: IconProps;
  onClickShowConflicts?: () => void;
}

export const ConflictAlert: FC<ConflictAlertProps> = ({
  title,
  boxProps,
  alertProps,
  alertTitleProps,
  showConflictsLink,
  onClickShowConflicts,
  includeDescription = true,
  includeDescriptionForInactiveVenue = false,
  iconProps,
}) => {
  const conflictBgColor = useColorModeValue("orange.50", "orange.600");
  const textColor = useColorModeValue("orange.500", "white");

  const handleOnClickShowConflicts = useCallback(() => {
    onClickShowConflicts && onClickShowConflicts();
  }, [onClickShowConflicts]);

  return (
    <Box {...boxProps}>
      <Alert
        status="warning"
        borderRadius={6}
        color={textColor}
        bgColor={conflictBgColor}
        {...alertProps}
      >
        <Icon as={MdWarning} boxSize="24px" mr={2.5} {...iconProps} />
        <Box>
          <AlertTitle w="100%" {...alertTitleProps}>
            {title ? title : "The time or location may have conflict."}
            {showConflictsLink && (
              <Text
                ml={2}
                as="span"
                color="blue.500"
                cursor="pointer"
                onClick={handleOnClickShowConflicts}
              >
                Show Conflicts
              </Text>
            )}
          </AlertTitle>
          {includeDescription && (
            <AlertDescription>
              <UnorderedList>
                <ListItem>
                  You can try changing your date, time or venue to resolve on
                  your own.
                </ListItem>
                <ListItem>
                  Event coordinator will follow up to resolve.
                </ListItem>
              </UnorderedList>
            </AlertDescription>
          )}
          {includeDescriptionForInactiveVenue && (
            <AlertDescription>
              <UnorderedList>
                <ListItem>
                  Work order for this date will not be generated
                </ListItem>
                <ListItem>
                  You can try changing your venue to resolve on your own.
                </ListItem>
                <ListItem>Contact Admin to resolve it.</ListItem>
              </UnorderedList>
            </AlertDescription>
          )}
        </Box>
      </Alert>
    </Box>
  );
};
