import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import {
  ApiLocationSummary,
  ApiUserSummary,
  ApiWorkflow,
} from "@operations-hero/lib-api-client";
import { useField } from "formik";
import { FC, useCallback, useMemo } from "react";
import { RequesterAutocomplete } from "../selects/RequesterAutocomplete";

export interface RequesterAutocompleteControlProps {
  name: string;
  label: string;
  value: ApiUserSummary;
  workflow: ApiWorkflow;
  location: ApiLocationSummary;
  isDisabled?: boolean;
}

export const RequesterAutocompleteControl: FC<
  RequesterAutocompleteControlProps
> = ({ name, label, value, workflow, location, isDisabled }) => {
  //@ts-ignore
  const [field, meta, helper] = useField({ name, value });

  const handleOnChange = useCallback(
    (workflow: ApiUserSummary | null) => {
      helper.setTouched(true);
      helper.setValue(workflow);
    },
    [helper]
  );

  const isInvalid = useMemo(() => !!meta.error && meta.touched, [meta]);

  return (
    <FormControl isInvalid={!!meta.error && meta.touched}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <RequesterAutocomplete
        {...field}
        name={name}
        value={value}
        workflow={workflow}
        location={location}
        isInvalid={isInvalid}
        onChange={handleOnChange}
        isDisabled={isDisabled}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
