import FullCalendar, { EventInput } from "@fullcalendar/react";
import moment from "moment";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import {
  unloadBulkRequestsAdded,
  unloadRequestAdded,
} from "../store/planning-hq/scheduling/scheduling.slice";

type useProjectSchedulingProps = {
  fullCalendar: FullCalendar | null;
};

/**
 * This hook contains side effects to be applied on the Scheduling Tab.
 * Side effects trigger when requests are added through the new request form or in bulk
 * @param fullCalendar The FullCalendar component reference
 */
export const useOnRequestAddedToProject = ({
  fullCalendar,
}: useProjectSchedulingProps) => {
  const dispatch = useDispatch();

  const { requestAdded, bulkRequestsAdded, schedulingCache, requestsCache } =
    useSelector((state: RootState) => state.projectScheduling);

  /**
   * Side effect when a single request is added
   */
  useEffect(() => {
    if (!fullCalendar) return;
    if (requestAdded.status === "fulfilled" && requestAdded.requestId) {
      const requestAsTimelineItem = schedulingCache[requestAdded.requestId];
      if (!requestAsTimelineItem) {
        dispatch(unloadRequestAdded());
        return;
      }
      const { start, end, rowId } = requestAsTimelineItem;
      let scheduledStart = moment(start);
      let scheduledEnd = moment(end);

      const isSameDay = scheduledStart.isSame(scheduledEnd, "date");

      if (isSameDay) {
        scheduledStart = moment(start).startOf("day");
        scheduledEnd = moment(start).add({ day: 1 }).startOf("day");
      }

      const requestAsCalendarEvent: EventInput = {
        ...requestsCache[requestAdded.requestId],
        start: scheduledStart.toDate(),
        end: scheduledEnd.toDate(),
        resourceId: rowId,
      };
      fullCalendar.getApi().addEvent(requestAsCalendarEvent);
      dispatch(unloadRequestAdded());
    }
  }, [requestAdded, dispatch, requestsCache, schedulingCache, fullCalendar]);

  /**
   * Side effect when requests are added in bulk
   */
  useEffect(() => {
    if (!fullCalendar) return;
    if (
      bulkRequestsAdded.status === "fulfilled" &&
      bulkRequestsAdded.requestIds.length > 0
    ) {
      bulkRequestsAdded.requestIds.forEach((requestId) => {
        const requestAsTimelineItem = schedulingCache[requestId];
        const { start, end, rowId } = requestAsTimelineItem;
        let scheduledStart = moment(start);
        let scheduledEnd = moment(end);

        const isSameDay = scheduledStart.isSame(scheduledEnd, "date");

        if (isSameDay) {
          scheduledStart = moment(start).startOf("day");
          scheduledEnd = moment(start).add({ day: 1 }).startOf("day");
        }

        const requestAsCalendarEvent: EventInput = {
          ...requestsCache[requestId],
          start: scheduledStart.toDate(),
          end: scheduledEnd.toDate(),
          resourceId: rowId,
        };
        fullCalendar.getApi().addEvent(requestAsCalendarEvent);
      });

      dispatch(unloadBulkRequestsAdded());
    }
  }, [
    bulkRequestsAdded,
    dispatch,
    requestsCache,
    schedulingCache,
    fullCalendar,
  ]);
};
