import {
  ApiAccount,
  ApiClient,
  ApiLaborTransaction,
  ApiRequest,
  ApiTransactionType,
  CreateApiPurchaseTransaction,
  CreateApiPurchaseTransactionLineItem,
  CreateApiTransaction,
} from "@operations-hero/lib-api-client";
import { ActionReducerMapBuilder, createAsyncThunk } from "@reduxjs/toolkit";
import { RequestSliceState } from "../request-form.slice";

export interface CreateTransactionsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  request: ApiRequest | null;
  transaction: CreateApiTransaction;
  lineItems?: CreateApiPurchaseTransactionLineItem[];
}

export const createTransaction = createAsyncThunk(
  "request/createTransaction",
  async (params: CreateTransactionsThunkParams, thunkAPI) => {
    const { apiClient, account, request, transaction } = params;
    const { type } = transaction;
    switch (type) {
      case ApiTransactionType.labor: {
        const laborTransation = transaction as ApiLaborTransaction;

        const result = apiClient.createRequestTransaction(
          account.id,
          request!.key,
          {
            type: ApiTransactionType.labor,
            laborer: laborTransation.laborer.id,
            datePerformed: laborTransation.datePerformed,
            hours: laborTransation.hours,
            hourlyRate: laborTransation.hourlyRate,
            laborType: laborTransation.laborType,
            budget: laborTransation.budget,
          }
        );
        return result;
      }
      case ApiTransactionType.purchase: {
        const purchaseTransaction = transaction as CreateApiPurchaseTransaction;
        const result = apiClient.createRequestTransaction(
          account.id,
          request!.key,
          {
            ...purchaseTransaction,
          }
        );
        return result;
      }
      default:
        return Promise.resolve();
    }
  }
);

export const createTransactionHandlers = (
  builder: ActionReducerMapBuilder<RequestSliceState>
) => {
  builder.addCase(createTransaction.fulfilled, (state, action) => {
    if (!action.payload) {
      return;
    }
    const type = action.payload.type;
    if (type === ApiTransactionType.labor) {
      state.transactions.labors.laborsData.unshift(action.payload);
      state.transactions.labors.laborsTotal += 1;
      state.laborTotal = state.transactions.labors.laborsTotal;
    } else if (type === ApiTransactionType.purchase) {
      state.transactions.purchases.purchasesData.unshift(action.payload);
      state.transactions.purchases.purchasesTotal += 1;
      state.expensesTotal = state.transactions.purchases.purchasesTotal;
    }
  });
};
