import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Budgets } from "./budgets/Budgets";
import { FundingSources } from "./funding-sources/FundingSources";
import { Projects } from "./projects/Projects";

type PlanningHQTab = { label: string; index: number; value: string };
const tabs: PlanningHQTab[] = [
  { label: "Projects", index: 0, value: "projects" },
  { label: "Funding sources", index: 1, value: "funding-sources" },
  { label: "Budgets", index: 2, value: "budgets" },
];

export type ProjectRouteParams = {
  section: string;
};

export const PlanningHQHome = () => {
  const [tabIndex, setTabIndex] = useState<number>(-1);

  const navigate = useNavigate();
  const { section } = useParams<ProjectRouteParams>();

  const handleTabsChange = useCallback(
    (index: number) => {
      const tab = tabs.find((tab) => tab.index === index);
      if (!tab) return;
      setTabIndex(index);
      navigate(`/planning/${tab.value.toLowerCase()}`);
    },
    [navigate]
  );

  useEffect(() => {
    if (tabIndex !== -1) return;
    const findTab = tabs.find((t) => t.value === section);
    if (!findTab) {
      navigate(`/planning/projects/`);
      setTabIndex(0);
      return;
    }
    setTabIndex(findTab.index);
  }, [section, navigate, tabIndex]);

  return (
    <Container maxWidth="8xl" display="flex" gap={4} flexDirection="column">
      <Tabs
        position="relative"
        variant="unstyled"
        index={tabIndex}
        onChange={handleTabsChange}
        isLazy={true}
      >
        <Flex alignItems="end" gap="20" flexWrap="wrap">
          <Box
            display={["none", "none", "none", "flex"]}
            gap={2}
            flexDir="column"
          >
            <Heading mr={4} fontSize="3xl" w="max-content">
              Planning
            </Heading>
          </Box>
          <Box position="relative">
            <TabList>
              {tabs.map((t) => {
                return (
                  <Tab key={t.label} _focus={{ boxShadow: "none" }}>
                    {t.label}
                  </Tab>
                );
              })}
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="blue.500"
              borderRadius="1px"
            />
          </Box>
        </Flex>

        <Divider mt={2} mb={6} />
        <TabPanels>
          <TabPanel p={0}>
            <Projects />
          </TabPanel>
          <TabPanel p={0}>
            <FundingSources />
          </TabPanel>
          <TabPanel p={0}>
            <Budgets />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};
