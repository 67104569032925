import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiFrequency,
  ApiReason,
  ApiReportingCategory,
  ApiWorkflow,
} from "@operations-hero/lib-api-client";
import { ChangeEvent, FC, useCallback, useMemo } from "react";
import { BiSearch } from "react-icons/bi";
import { useSelector } from "react-redux";
import {
  LocationFilter,
  LocationFilterValue,
} from "../../../components/filters/LocationFilter";
import useReviewersManagement from "../../../hooks/useReviewersManagement";
import { RootState } from "../../../store";
import { ScheduledRequestFilters } from "../../../store/schedule-request-list.slice";
import { CategoryFilter } from "../../requests/filters/CategoryFilter";
import { ReasonFilter } from "../../requests/filters/ReasonFilter";
import { ScheduledRequestFrequencyFilter } from "../../requests/filters/ScheduledFrequencyFilter";
import { WorkflowFilter } from "../../requests/filters/WorkflowFilter";
import { Products } from "../location-list/LocationList";
import { PersonFilter } from "./AssisgneePersonFilter";

interface ScheduleFiltersProps {
  filter: ScheduledRequestFilters;
  updateFilters: (newValue: Partial<ScheduledRequestFilters>) => void;
  filterInitialized: boolean;
}

export const ScheduleFilters: FC<ScheduleFiltersProps> = ({
  filter,
  updateFilters,
  filterInitialized,
}) => {
  const { locations } = useSelector((state: RootState) => state.localCache);
  const inputBgColor = useColorModeValue("white", "transparent");

  const {
    locations: newLocations,
    categories,
    workflows,
    showTaskbooksSchedules,
  } = useReviewersManagement(
    filter.workflow && Array.isArray(filter.workflow)
      ? filter.workflow
      : filter.workflow
        ? [filter.workflow]
        : []
  );

  const allowedLocations = useMemo(() => {
    if (showTaskbooksSchedules && newLocations && newLocations?.length > 0) {
      return locations.filter(
        (l) =>
          !l.hiddenProducts.some((h) => h === Products.HeroHQ) &&
          newLocations.some((r) => r === l.id)
      );
    }
    const result = locations.filter(
      (l) => !l.hiddenProducts.some((h) => h === Products.HeroHQ)
    );
    return result;
  }, [locations, newLocations, showTaskbooksSchedules]);

  const handleOnChangeSearch = useCallback(
    (value: string) => {
      updateFilters({ search: value });
    },
    [updateFilters]
  );

  const handleOnChangeLocation = useCallback(
    ({ value }: LocationFilterValue) => {
      updateFilters({
        location: value.map((loc) => loc.id).sort(),
      });
    },
    [updateFilters]
  );

  const handleOnChangeCategory = useCallback(
    (values: ApiReportingCategory[]) => {
      const categoryIds = values.map((c) => c.id);
      updateFilters({ category: categoryIds });
    },
    [updateFilters]
  );

  const handleOnChangeReason = useCallback(
    (values: ApiReason[]) => {
      const reasonIds = values.map((r) => r.id);
      updateFilters({ reason: reasonIds });
    },
    [updateFilters]
  );

  const handleOnChangeFrequency = useCallback(
    (values: ApiFrequency[]) => {
      updateFilters({ frequency: values });
    },
    [updateFilters]
  );

  const handleOnChangeExecuteEvery = useCallback(
    (value: number | undefined) => {
      if (!value || (value && isNaN(value))) {
        updateFilters({ executeEvery: undefined });
        return;
      }
      updateFilters({ executeEvery: value });
    },
    [updateFilters]
  );
  const handleOnChangeAssignee = useCallback(
    (value: any) => {
      updateFilters({ persons: value });
    },
    [updateFilters]
  );

  const handleOnChangeWorkflow = useCallback(
    (value: ApiWorkflow[]) => {
      updateFilters({ workflow: value.map((val) => val.id) });
    },
    [updateFilters]
  );

  return (
    <Box minW="100%">
      <Flex gap={4} minW="100%" justifyContent="space-between">
        <Flex gap={2} flexWrap="wrap" alignItems="center">
          <Box w={["100%", "100%", "300px"]}>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Icon color="gray.400" as={BiSearch} />}
              />
              <Input
                bgColor={inputBgColor}
                placeholder="Search for a schedule by keyword"
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleOnChangeSearch(e.target.value)
                }
              />
            </InputGroup>
          </Box>

          <LocationFilter
            onChange={handleOnChangeLocation}
            value={filter.location || []}
            allowedLocations={allowedLocations}
          />
          <CategoryFilter
            onChange={handleOnChangeCategory}
            value={filter.category || []}
            allowedCategories={categories}
          />
          <ReasonFilter
            onChange={handleOnChangeReason}
            value={filter.reason || []}
          />

          <ScheduledRequestFrequencyFilter
            executeEvery={filter.executeEvery}
            frequency={filter.frequency || []}
            onEveryChange={handleOnChangeExecuteEvery}
            onFrequencyChange={handleOnChangeFrequency}
          />
          <PersonFilter
            value={filter.persons || []}
            onChange={handleOnChangeAssignee}
            filterInitialized={filterInitialized}
          />

          <WorkflowFilter
            value={
              Array.isArray(filter.workflow)
                ? filter.workflow
                : filter.workflow
                  ? [filter.workflow]
                  : []
            }
            onChange={handleOnChangeWorkflow}
            optionsIds={showTaskbooksSchedules ? workflows : undefined}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
