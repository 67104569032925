import { ModalFooter } from "@chakra-ui/modal";
import { Button, Heading, HStack, Text, VStack } from "@chakra-ui/react";
import { ApiBudget, CreateApiBudget } from "@operations-hero/lib-api-client";
import { FormikProps } from "formik";
import { FC, useCallback, useRef, useState } from "react";
import { AccountModal } from "../../../account-settings/account-modal/AccountModal";
import { NewBudgetForm, NewBudgetProps } from "./NewBudgetForm";

export type NewBudgetModalProps = {
  onClose: () => void;
  onSubmit: (budget: CreateApiBudget) => Promise<ApiBudget | void>;
  isOpen: boolean;
};

export const NewBudgetModal: FC<NewBudgetModalProps> = ({
  onClose,
  onSubmit,
  isOpen,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const newBudgetFormRef = useRef<FormikProps<NewBudgetProps>>(null);

  const handleSubmit = useCallback(() => {
    if (newBudgetFormRef.current) {
      setIsSubmitting(true);
      newBudgetFormRef.current.submitForm().then(() => {
        setIsSubmitting(false);
        if (newBudgetFormRef.current?.isValid) onClose();
      });
    }
  }, [onClose]);

  return (
    <AccountModal
      size="3xl"
      title={
        <VStack align="stretch" gap={1}>
          <Heading size="lg">New budget</Heading>
          <Text fontSize="small" color="gray.500" fontWeight="normal">
            Budgets represent an amount that could be used in the allocation of
            requests.
          </Text>
        </VStack>
      }
      content={<NewBudgetForm onSubmit={onSubmit} formRef={newBudgetFormRef} />}
      isOpen={isOpen}
      onClose={onClose}
      footer={
        <ModalFooter>
          <HStack w="full" justify="space-between">
            <Button
              size="md"
              variant="link"
              colorScheme="blue"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              size="md"
              colorScheme="blue"
              onClick={handleSubmit}
              isLoading={isSubmitting}
            >
              Create
            </Button>
          </HStack>
        </ModalFooter>
      }
    />
  );
};
