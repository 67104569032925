import { FormControl, FormErrorMessage } from "@chakra-ui/react";
import { ApiFundingSource } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { FC, useCallback, useState } from "react";
import { useAllocationsContext } from "../../../../../components/allocations/AllocationsContext";
import {
  FundingSourceAllocation,
  FundingSourceAllocationAmount,
} from "../../../../../components/allocations/types";
import { CurrencyInput } from "../../../../../components/inputs/CurrencyInput";

type AllocationAmountControlProps = {
  value: FundingSourceAllocationAmount;
  index: number;
  isDisabled?: boolean;
  onAllocationChange?: () => void;
};

export const AllocationAmountControl: FC<AllocationAmountControlProps> = ({
  value,
  index,
  isDisabled = false,
  onAllocationChange,
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, meta] = useField(`allocations.${index}.amount`);
  const { submitCount } = useFormikContext();

  const { allocations, setAllocations } =
    useAllocationsContext<ApiFundingSource>();

  const [localAllocationAmount, setLocalAllocationAmount] = useState(value);

  const handleAmountChange = useCallback(
    (valueAsString?: string, valueAsNumber?: number) => {
      if (!valueAsNumber || Number.isNaN(valueAsNumber)) {
        setLocalAllocationAmount(0);
      } else {
        setLocalAllocationAmount(valueAsNumber);
      }
    },
    []
  );

  const updateAllocation = useCallback(() => {
    const tempList: FundingSourceAllocation[] = [...allocations];
    tempList[index] = {
      ...tempList[index],
      amount: localAllocationAmount,
    };
    setAllocations(tempList);
    onAllocationChange && onAllocationChange();
  }, [
    allocations,
    onAllocationChange,
    index,
    localAllocationAmount,
    setAllocations,
  ]);

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <CurrencyInput
        value={value}
        onChange={handleAmountChange}
        onBlur={updateAllocation}
        isDisabled={isDisabled}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
