import {
  Center,
  Checkbox,
  createIcon,
  Flex,
  FlexProps,
  SystemStyleObject,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ChakraStylesConfig,
  DropdownIndicatorProps,
  GroupBase,
  OptionProps,
  Size,
  SizeProps,
} from "chakra-react-select";
import { FC, useMemo } from "react";
import { CustomSelectComponentProp } from "./select-overrides-types";

const ChevronDown = createIcon({
  displayName: "ChevronDownIcon",
  d: "M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z",
});

export const DropdownIndicator = (props: DropdownIndicatorProps) => {
  const { innerProps, selectProps } = props;
  const { size } = selectProps;

  const iconSizes: SizeProps = {
    sm: 4,
    md: 5,
    lg: 6,
  };
  const iconSize = iconSizes[size as Size];

  return (
    <Center
      {...innerProps}
      sx={{
        h: "100%",
        paddingRight: 4,
        borderRadius: 0,
        borderWidth: 0,
        cursor: "pointer",
      }}
    >
      <ChevronDown h={iconSize} w={iconSize} />
    </Center>
  );
};
export interface InnerComponentProps<T> {
  value: T;
  isSelected: boolean;
}

export function createOptionComponent<T>(
  InnerComponent: FC<InnerComponentProps<T>>,
  flexProps?: FlexProps
) {
  return ({
    innerRef,
    innerProps,
    data,
    children,
    isFocused,
    isDisabled,
    isSelected,
    selectProps: {
      size,
      isMulti,
      hideSelectedOptions,
      selectedOptionStyle,
      selectedOptionColor,
    },
  }: OptionProps<T, boolean, GroupBase<T>>) => {
    // Use the same selected color as the border of the select component
    // https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/input.ts#L73
    const selectedBg = useColorModeValue("blue.500", "blue.300");
    const selectedColor = useColorModeValue("black", "white");
    const focusBgColor = useColorModeValue("gray.100", "whiteAlpha.100");

    // Don't create exta space for the checkmark if using a multi select with
    // options that dissapear when they're selected
    const showCheckIcon: boolean =
      selectedOptionStyle === "check" &&
      (!isMulti || hideSelectedOptions === false);

    const { hoverBg, textColor } = useMemo(() => {
      const hoverBg = isSelected
        ? selectedBg
        : isFocused
          ? focusBgColor
          : "transparent";
      const textColor = isSelected ? "white" : selectedColor;
      return { hoverBg, textColor };
    }, [focusBgColor, isFocused, isSelected, selectedBg, selectedColor]);

    return (
      <Flex
        p={2}
        w="100%"
        role="button"
        fontSize={size}
        textAlign="start"
        color={textColor}
        alignItems="center"
        cursor="pointer"
        bgColor={isSelected ? selectedBg : "transparent"}
        _hover={{
          bgColor: hoverBg,
        }}
        _active={{
          bg: selectedBg,
        }}
        ref={innerRef}
        {...flexProps}
        {...innerProps}
      >
        {showCheckIcon && (
          <Checkbox
            pointerEvents="none"
            isChecked={isSelected}
            iconColor={selectedColor}
            bgColor={isSelected ? "blue.500" : "transparent"}
          />
        )}
        <InnerComponent value={data} isSelected={isSelected} />
      </Flex>
    );
  };
}

export const getCustomSelectComponents = (): CustomSelectComponentProp => {
  const defaultComponents = {
    DropdownIndicator: (props: DropdownIndicatorProps) =>
      DropdownIndicator(props),
    IndicatorSeparator: null,
  };
  return defaultComponents;
};

// Here all common styles for all common selects
export const commonStyles = {
  container: (base: SystemStyleObject) => ({
    ...base,
  }),
  valueContainer: (base: SystemStyleObject) => ({
    ...base,
    display: "flex",
  }),
};

// Following styles are for custom selectors that are used at the top of the request form view
export const CustomSelectStyles = (): ChakraStylesConfig => ({
  container: () => ({
    height: 6,
    border: "0px solid transparent",
  }),
  control: () => ({
    border: 0,
    boxShadow: 0,
  }),
  valueContainer: () => ({
    height: 6,
  }),
  menu: (styles: SystemStyleObject) => ({
    ...styles,
    minW: 36,
    maxW: 36,
    borderRadius: 4,
  }),
  menuList: (styles: SystemStyleObject) => ({
    ...styles,
    minW: 36,
    maxW: 36,
    border: "1px solid whiteAlpha.300",
  }),
});
