import {
  Box,
  Grid,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { ApiPurchaseTransactionLineItem } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect } from "react";
import { IoTrashOutline } from "react-icons/io5";
import { removeLeadingZeros } from "../../../../utils/removeLeadingZeros";

interface ItemsSectionProps {
  value: ApiPurchaseTransactionLineItem[];
  setValues: (value: ApiPurchaseTransactionLineItem[]) => void;
  isNameDisabled?: boolean;
}

export const LineItemsSection: FC<ItemsSectionProps> = ({
  value,
  setValues,

  isNameDisabled,
}) => {
  const errorTextColor = useColorModeValue("red.500", "red.300");
  const bgC = useColorModeValue("gray.50", "gray.600");
  const onChangeText = useCallback(
    (
      text: string,
      index: number,
      key: keyof ApiPurchaseTransactionLineItem
    ) => {
      let newValue: ApiPurchaseTransactionLineItem = { ...value[index] };

      switch (key) {
        case "description":
          newValue.description = text;
          break;
        case "quantity":
          newValue.quantity = Number(text);
          newValue.amount = newValue.quantity * newValue.unitCost;
          break;
        case "unitCost":
          newValue.unitCost = Number(text);
          newValue.amount = newValue.quantity * newValue.unitCost;
          break;
      }

      if (index === value.length - 1 || value.length === 1) {
        value.splice(value.length - 1, 0, newValue);
      } else {
        const isEmpty =
          newValue.description.trim() === "" &&
          newValue.quantity === 0 &&
          newValue.unitCost === 0;

        if (isEmpty) {
          value.splice(index, 1);
        } else {
          value[index] = newValue;
        }
      }
      const res = [...value];
      setValues(res);
    },
    [value, setValues]
  );

  const handleDeleteLabel = (index: number) => {
    if (index === value.length - 1) {
      return;
    }
    value.splice(index, 1);
    setValues([...value]);
  };

  useEffect(() => {
    let newValue: ApiPurchaseTransactionLineItem[] = [];
    if (
      !value ||
      value.length === 0 ||
      (value && value[value.length - 1].description !== "")
    ) {
      newValue = [...value];
      newValue.push({
        description: "",
        quantity: 0,
        unitCost: 0,
        amount: 0,
        id: "",
      });
      setValues(newValue);
    }
  }, [value, setValues]);

  return (
    <Box>
      <HStack>
        <Box>
          {!isNameDisabled ? (
            <>
              <Text>Items</Text>
              <Text fontSize="xs" color="gray.500">
                {`Use fields to add extra information about the Receipt.`}
              </Text>
            </>
          ) : null}
        </Box>
      </HStack>
      {value.map((label, index) => (
        <Grid
          gap={4}
          maxWidth="99%"
          justifyContent="center"
          alignItems="center"
          key={index}
        >
          <HStack pt={4}>
            <Box w="95%" backgroundColor={bgC} rounded="md" padding={2}>
              <Input
                id={`description${index}`}
                className="description"
                placeholder="Item Name"
                value={label.description ? label.description : ""}
                onChange={(element) => {
                  onChangeText(element.target.value, index, "description");
                }}
                isInvalid={
                  label.description === "" && index !== value.length - 1
                }
              />
              {label.description === "" && index !== value.length - 1 && (
                <Text fontSize="sm" textColor={errorTextColor}>
                  Item Name is a required field
                </Text>
              )}
              <HStack pt={4}>
                <VStack alignItems="flex-start">
                  <InputGroup>
                    <InputLeftAddon fontSize="sm" paddingLeft="5px" maxW={9}>
                      Qty
                    </InputLeftAddon>
                    <Input
                      textAlign={"right"}
                      id={`quantity${index}`}
                      type="number"
                      placeholder="Quantity"
                      value={label.quantity}
                      pl={0.5}
                      pr={0.5}
                      min={0}
                      onChange={async (element) => {
                        onChangeText(
                          await removeLeadingZeros(
                            element.target.value,
                            label.quantity === 0
                          ),
                          index,
                          "quantity"
                        );
                      }}
                      isInvalid={
                        label.quantity === 0 && index !== value.length - 1
                      }
                    />
                  </InputGroup>
                </VStack>
                <VStack alignItems="flex-start">
                  <InputGroup>
                    <InputLeftAddon maxW={9} fontSize="sm" paddingLeft="2px">
                      Cost
                    </InputLeftAddon>
                    <Input
                      id={`unitCost${index}`}
                      textAlign={"right"}
                      type="number"
                      placeholder="Unit Cost"
                      value={label.unitCost}
                      min={0}
                      step="0.01"
                      pl={0.5}
                      pr={0.5}
                      onChange={async (element) => {
                        onChangeText(
                          await removeLeadingZeros(
                            element.target.value,
                            label.unitCost === 0
                          ),
                          index,
                          "unitCost"
                        );
                      }}
                      isInvalid={
                        label.unitCost === 0 && index !== value.length - 1
                      }
                    />
                  </InputGroup>
                </VStack>
                <VStack alignItems="flex-start" maxW="25%">
                  <InputGroup>
                    <InputLeftElement pointerEvents="none" maxW={1}>
                      <Text>$</Text>
                    </InputLeftElement>
                    <Input
                      maxW="100%"
                      variant="flushed"
                      id={`amount${index}`}
                      type="number"
                      placeholder="Total"
                      isDisabled={true}
                      textAlign={"left"}
                      value={(label.unitCost * label.quantity).toFixed(2)}
                      min={0}
                      pl="1rem"
                    />
                  </InputGroup>
                </VStack>
              </HStack>
            </Box>
            <Box w="1%">
              <IconButton
                backgroundColor={bgC}
                variant="ghost"
                aria-label="Delete label"
                icon={<IoTrashOutline fontSize={20} />}
                color="grey.500"
                onClick={() => handleDeleteLabel(index)}
              />
            </Box>
          </HStack>
        </Grid>
      ))}
    </Box>
  );
};
