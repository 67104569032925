import {
  NumberInput,
  NumberInputField,
  NumberInputProps,
} from "@chakra-ui/react";
import { useCallback, useRef, useState } from "react";
import { formatCurrency } from "../../utils/formatCurrency";

export interface CurrencyInputProps {
  value: number;

  onChange?: (valueAsString?: string, valueAsNumber?: number) => void;
  onBlur?: (
    e: React.FocusEvent<HTMLInputElement>,
    valueAsString?: string,
    valueAsNumber?: number
  ) => void;
  isDisabled?: boolean;
  inputProps?: NumberInputProps;
}

export const CurrencyInput = ({
  value: inputValue,

  onChange,
  onBlur,

  isDisabled,
  inputProps,
}: CurrencyInputProps) => {
  const [localValue, setLocalValue] = useState<{
    valueAsString?: string;
    valueAsNumber?: number;
  }>({
    valueAsNumber: inputValue,
    valueAsString: `$ ${formatCurrency(inputValue).substring(1)}`,
  });

  const valueFormatted = useRef<boolean>(false);

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (!localValue.valueAsNumber) {
        setLocalValue({
          ...localValue,
          valueAsString: `$ ${formatCurrency(0).substring(1)}`,
        });
      } else {
        setLocalValue({
          ...localValue,
          valueAsString: `$ ${formatCurrency(localValue.valueAsNumber).substring(1)}`,
        });
      }

      valueFormatted.current = true;

      if (onBlur) onBlur(e, localValue.valueAsString, localValue.valueAsNumber);
    },
    [localValue, onBlur]
  );

  const handleOnChange = useCallback(
    (valueAsString: string, valueAsNumber: number) => {
      if (valueFormatted.current) {
        valueFormatted.current = false;
        return;
      }

      setLocalValue({
        valueAsNumber,
        valueAsString: `$ ${valueAsString}`,
      });

      if (onChange) onChange(valueAsString, valueAsNumber);
    },
    [onChange]
  );

  const handleOnFocus = useCallback(() => {
    if (!localValue.valueAsNumber) {
      setLocalValue({
        ...localValue,
        valueAsString: `$ `,
      });
    } else {
      setLocalValue({
        ...localValue,
        valueAsString: `$ ${localValue.valueAsNumber}`,
      });
    }
  }, [localValue]);

  return (
    <NumberInput
      w="100%"
      min={0}
      onChange={handleOnChange}
      alignItems="left"
      inputMode="numeric"
      value={localValue.valueAsString}
      onBlur={(e) => {
        handleOnBlur(e);
      }}
      isDisabled={isDisabled}
      onFocus={handleOnFocus}
      pattern=".*"
      {...inputProps}
    >
      <NumberInputField textAlign="left" pr="3" />
    </NumberInput>
  );
};
