import { BoxProps, Grid, GridItem, HStack, Icon, Text } from "@chakra-ui/react";
import {
  ApiPurchaseTransaction,
  UpdateApiPurchaseTransaction,
} from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import React, { useCallback, useMemo, useState } from "react";
import { IoMdAttach } from "react-icons/io";
import { IoTrashSharp } from "react-icons/io5";
import { MdEdit, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { useSelector } from "react-redux";
import { OutlinedIconButton } from "../../../../components/custom-icons/OutlinedIconButton";
import {
  DataTable,
  DataTableColumn,
} from "../../../../components/data-table/DataTable";
import { RootState } from "../../../../store";
import { getVisibleFields } from "../../../../utils/getVisibleFields";
import { TRANSACTION_DATE_FORMAT } from "../transactions-helper";
import { ExpensesDataProps } from "./ExpensesData";
import { RiArrowDropDownLine } from "react-icons/ri";

export enum TransactionEnum {
  EDIT = "EDIT",
  REMOVE = "REMOVE",
}

export const ExpensesTable: React.FC<ExpensesDataProps> = ({
  setWorkingPurchase,
  onOpenEditModal,
  openRemoveModal,
  canEditAndDelete,
}) => {
  const { purchasesData } = useSelector(
    (state: RootState) => state.requestForm.transactions.purchases
  );
  let total: number = 0;
  purchasesData.map((expenseItem: any) => {
    return (total += expenseItem.total);
  });

  const visibleFields = useSelector(
    (state: RootState) => state.requestForm.visibleFields
  );

  const { showExpenses } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const [isCellAccordionOpen, setCellOpenAccordion] = useState<string | null>(
    null
  );

  const handleClickButtons = useCallback(
    (purchase: UpdateApiPurchaseTransaction, action: TransactionEnum) => {
      if (!showExpenses) {
        return;
      }
      setWorkingPurchase(purchase as ApiPurchaseTransaction);
      TransactionEnum.EDIT === action && onOpenEditModal();
      TransactionEnum.REMOVE === action && openRemoveModal && openRemoveModal();
    },
    [setWorkingPurchase, onOpenEditModal, openRemoveModal, showExpenses]
  );

  const columns = useMemo<DataTableColumn<ApiPurchaseTransaction>[]>(() => {
    const cellProps: BoxProps = {
      py: "4",
    };
    return [
      {
        Header: "Where",
        Cell: (cell: any) => (
          <Text whiteSpace="break-spaces" justifyContent="center">
            {cell.row.original.vendor?.name}
          </Text>
        ),
        maxWidth: 90,
        boxProps: cellProps,
      },
      {
        Header: "Date",
        Cell: (cell: any) => (
          <Text>
            {format(
              new Date(cell.row.original.datePerformed),
              TRANSACTION_DATE_FORMAT
            )}
          </Text>
        ),
        maxWidth: 90,
        boxProps: cellProps,
      },
      {
        Header: "Item",
        Cell: (cell: any) => (
          <Grid pt={2} templateColumns="1fr" gap={4} whiteSpace="break-spaces">
            {cell.row.original.id !== isCellAccordionOpen ? (
              <Text fontWeight="bold">
                Line Items: {Math.max(cell.row.original.lineItems.length, 1)}
              </Text>
            ) : (
              <>
                {!cell.row.original.lineItems ||
                cell.row.original.lineItems.length === 0 ? (
                  <Text>{cell.row.original.description}</Text>
                ) : (
                  <>
                    {cell.row.original.lineItems.map(
                      (item: any, index: number) => (
                        <Text
                          key={index}
                          maxHeight={10}
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {item.description}
                        </Text>
                      )
                    )}
                    {cell.row.original.shipping > 0 && (
                      <Text textAlign="left">Shipping</Text>
                    )}
                    {cell.row.original.tax > 0 && (
                      <Text textAlign="left">Tax</Text>
                    )}
                    {cell.row.original.additionalFees > 0 && (
                      <Text textAlign="left">Additional Fees</Text>
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        ),
      },

      {
        Header: "Quantity",
        Cell: (cell: any) => (
          <Grid templateColumns="1fr" gap={4} whiteSpace="break-spaces">
            {cell.row.original.id !== isCellAccordionOpen ? (
              <Text fontWeight="bold">
                {cell.row.original.quantity < 1
                  ? "1"
                  : cell.row.original.lineItems.reduce(
                        (sum: number, item: any) => sum + item.quantity,
                        0
                      ) > 0
                    ? cell.row.original.lineItems.reduce(
                        (sum: number, item: any) => sum + item.quantity,
                        0
                      )
                    : "1"}
              </Text>
            ) : (
              <>
                {!cell.row.original.lineItems ||
                cell.row.original.lineItems.length === 0 ? (
                  <Text>
                    {cell.row.original.quantity > 1
                      ? cell.row.original.quantity
                      : "1"}
                  </Text>
                ) : (
                  <>
                    {cell.row.original.lineItems.map(
                      (item: any, index: number) => (
                        <Text
                          key={index}
                          maxHeight={10}
                          overflow="hidden"
                          textOverflow="ellipsis"
                          whiteSpace="nowrap"
                        >
                          {item.quantity}
                        </Text>
                      )
                    )}
                  </>
                )}
              </>
            )}
          </Grid>
        ),
        maxWidth: 80,
        boxProps: cellProps,
      },
      {
        Header: "Total Cost",
        Cell: (cell: any) => (
          <Grid templateColumns="1fr" gap={4} whiteSpace="break-spaces">
            {!cell.row.original.lineItems ||
            cell.row.original.lineItems.length === 0 ||
            isCellAccordionOpen !== cell.row.original.id ? (
              <Text textAlign="left" fontWeight="bold">
                {cell.row.original.total.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                })}
              </Text>
            ) : (
              <>
                {cell.row.original.lineItems.map((item: any, index: number) => (
                  <Text key={index}>
                    {item.unitCost.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "USD",
                    })}
                  </Text>
                ))}
                {cell.row.original.shipping > 0 && (
                  <Text textAlign="left">
                    {cell.row.original.shipping.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "USD",
                    })}
                  </Text>
                )}
                {cell.row.original.tax > 0 && (
                  <Text textAlign="left">
                    {cell.row.original.tax.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "USD",
                    })}
                  </Text>
                )}
                {cell.row.original.additionalFees > 0 && (
                  <Text textAlign="left">
                    {cell.row.original.additionalFees.toLocaleString(
                      undefined,
                      {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "USD",
                      }
                    )}
                  </Text>
                )}
                <Text textAlign="left" fontWeight="bold">
                  {cell.row.original.total.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  })}
                </Text>
              </>
            )}
          </Grid>
        ),
        maxWidth: 90,
        boxProps: cellProps,
      },
      {
        Header: " ",
        Cell: (cell: any) => (
          <HStack spacing="1.5" alignItems="center" justifyContent="right">
            {canEditAndDelete && canEditAndDelete(cell.row.original) && (
              <>
                {cell.row.original.attachmentCount > 0 ? (
                  <OutlinedIconButton
                    icon={<Icon as={IoMdAttach} boxSize={5} />}
                  />
                ) : null}
                {isCellAccordionOpen === cell.row.original.id && (
                  <>
                    <OutlinedIconButton
                      icon={<Icon as={IoTrashSharp} boxSize={5} />}
                      onClick={() =>
                        handleClickButtons(
                          cell.row.original,
                          TransactionEnum.REMOVE
                        )
                      }
                    />
                    <OutlinedIconButton
                      icon={<Icon as={MdEdit} boxSize={5} />}
                      onClick={() =>
                        handleClickButtons(
                          cell.row.original,
                          TransactionEnum.EDIT
                        )
                      }
                    />
                  </>
                )}
                {isCellAccordionOpen === cell.row.original.id ? (
                  <OutlinedIconButton
                    icon={<Icon as={MdOutlineKeyboardArrowUp} boxSize={5} />}
                    onClick={() => {
                      setCellOpenAccordion(null);
                    }}
                  />
                ) : (
                  <OutlinedIconButton
                    icon={<Icon as={RiArrowDropDownLine} boxSize={5} />}
                    onClick={() => {
                      setCellOpenAccordion(cell.row.original.id);
                    }}
                  />
                )}
              </>
            )}
          </HStack>
        ),
        maxWidth: 110,
        boxProps: cellProps,
      },
    ];
  }, [handleClickButtons, canEditAndDelete, isCellAccordionOpen]);
  return (
    <GridItem colSpan={8} pt={4}>
      <DataTable columns={columns} data={purchasesData} />
    </GridItem>
  );
};
