import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Circle,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { ApiRequest, ApiRequestStatus } from "@operations-hero/lib-api-client";
import { forwardRef, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocationTwoLine } from "../../../components/badges/LocationTwoLine";
import { priorityColorMap } from "../../../components/badges/PriorityBadge";
import { RootState } from "../../../store";
import {
  setChangeType,
  setSource,
  setTarget,
} from "../../../store/request-column-view.slice";
import { RequestAssignees } from "../../calendar/requests/RequestInfo";
import { RequestRowIcons } from "../request-row/RequestRowIcons";
import { RequestSchedulingSummary } from "../request-row/RequestSchedulingSummary";
import { useColumnViewContext } from "./ColumnViewContext";
import { State } from "./DraggableRequest";

export type RequestBaseProps = {
  request: ApiRequest;
  state: State;
  index: number;
};

export const RequestBase = forwardRef<HTMLDivElement, RequestBaseProps>(
  ({ request, state, index }, ref) => {
    const [selectedStatus, setSelectedStatus] = useState<ApiRequestStatus>();

    const {
      initWorkingRequest,
      changeStatusValidation,
      workingRequest,
      setSelectedRequest,
      enabledStatuses,
    } = useColumnViewContext();
    const { transitionsByRequest } = useSelector(
      (state: RootState) => state.requestsColumnViewSlice
    );

    const dispatch = useDispatch();
    const { colorMode } = useColorMode();

    const changeStatus = useCallback(
      (status: ApiRequestStatus) => {
        setSelectedRequest(request);
        setSelectedStatus(status);
        initWorkingRequest(request).then(() => {
          dispatch(setChangeType("lazy"));
          dispatch(
            setSource({
              request,
              index: index,
              edge: null,
            })
          );

          dispatch(
            setTarget({
              request: {
                status,
              } as ApiRequest,
              index: 0,
              edge: null,
            })
          );
        });
      },
      [request, initWorkingRequest, dispatch, index, setSelectedRequest]
    );

    useEffect(() => {
      if (changeStatusValidation && selectedStatus)
        changeStatusValidation(selectedStatus);
    }, [workingRequest, changeStatusValidation, selectedStatus]);

    return (
      <>
        <Box
          opacity={state.type === "dragging" ? "0.8" : "1"}
          w="full"
          ref={ref}
          pb="1"
        >
          <VStack
            w="full"
            boxShadow="md"
            borderRadius="md"
            backgroundColor={colorMode === "light" ? "white" : "gray.800"}
            align="stretch"
            p="1"
          >
            <VStack w="full" align="stretch">
              <HStack
                bgColor={priorityColorMap[request.priority]}
                justifyContent="space-between"
                py="1"
                px="2"
                roundedTop="md"
                fontSize="smaller"
                fontWeight="semibold"
              >
                <Text color="white">{request.key}</Text>
                <Circle
                  color={priorityColorMap[request.priority]}
                  bgColor="white"
                  size="1.2rem"
                >
                  <Text casing="uppercase">{request.priority[0]}</Text>
                </Circle>
              </HStack>
              <RequestSchedulingSummary request={request} />
              <Text
                color={colorMode === "light" ? "blue.600" : "white"}
                casing="uppercase"
                fontWeight="semibold"
              >
                {request.type}
              </Text>
              {request.reportingCategory && (
                <Text
                  color={colorMode === "light" ? "gray.700" : "white"}
                  fontSize="medium"
                >
                  {request.reportingCategory.name}
                </Text>
              )}
              {request.location && <LocationTwoLine value={request.location} />}
              {request.summary && <Text noOfLines={3}>{request.summary}</Text>}
              <Box w="fit-content">
                <RequestRowIcons request={request} />
              </Box>
              <RequestAssignees assignees={request.assignees} />
            </VStack>
            <VStack
              w="full"
              justify="stretch"
              alignContent="center"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Menu placement="end-end">
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronRightIcon boxSize="5" />}
                  size="sm"
                  variant="ghost"
                  sx={{
                    _hover: {},
                    _active: {},
                  }}
                  width="fit-content"
                  color="gray.500"
                >
                  Move to
                </MenuButton>
                <Portal>
                  <MenuList zIndex="2">
                    {enabledStatuses.map((key) => {
                      return (
                        <MenuItem
                          isDisabled={
                            transitionsByRequest &&
                            !!transitionsByRequest[request.id][key] === false
                          }
                          key={`menuItem::${request.key}::${key}`}
                          onClick={(e) => {
                            changeStatus(key);
                          }}
                        >
                          <Text casing="uppercase">{key}</Text>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Portal>
              </Menu>
            </VStack>
          </VStack>
        </Box>
      </>
    );
  }
);
