import { FormControl, FormLabel, HStack, VStack } from "@chakra-ui/react";
import {
  ApiFundingSource,
  CreateApiFundingSource,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { formatToFullHexColorCode } from "../../../components/color-picker/color-code-utils";
import {
  ColorPicker,
  ColorPickerEventArgs,
} from "../../../components/color-picker/ColorPicker";
import { DEFAULT_COLOR_CODES } from "../../../components/color-picker/defaults";
import { AutoSavingInput } from "../../../components/inputs/AutoSavingInput";
import { FiscalYearSelect } from "../../../components/selects/FiscalYearSelect";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import {
  setUpdateStatus,
  setWorkingFundingSource,
  unload,
  updateFundingSource,
} from "../../../store/planning-hq/funding-sources/funding-source-form.slice";
import { AutoSavingCurrencyInput } from "../components/AutoSavingCurrencyInput";

type ContactOnlyProps = {
  [K in keyof CreateApiFundingSource as K extends `contact${string}`
    ? K
    : never]: CreateApiFundingSource[K];
};

export type EditFundingSourceFormProps = {
  fundingSource: ApiFundingSource;
  isDisabled?: boolean;
};

export type EditFundingSourceValues = ApiFundingSource;

export const EditFundingSourceForm: FC<EditFundingSourceFormProps> = ({
  fundingSource,
  isDisabled,
}) => {
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const toast = useShowToast();

  const { apiClient } = useAuthentication();
  const { workingFundingSource } = useSelector(
    (state: RootState) => state.fundingSourceFormSlice
  );

  const requiredFields: (keyof Partial<ApiFundingSource>)[] = useMemo(() => {
    return ["name", "fiscalYearStart", "colorId", "contactName", "amount"];
  }, []);

  const requiredFieldKeys = useMemo(() => {
    return requiredFields as string[];
  }, [requiredFields]);

  const handlePropertyUpdate = useCallback(
    (delta: Partial<ApiFundingSource>) => {
      const fieldsToUpdate = Object.keys(delta);
      if (fieldsToUpdate.length === 0) return;

      const singleField = fieldsToUpdate[0] as keyof ApiFundingSource;
      const singleFieldValue = delta[singleField];

      const isValueNull = Array.isArray(singleFieldValue)
        ? singleFieldValue.length === 0
        : Boolean(singleFieldValue) === false;

      if (requiredFieldKeys.includes(singleField) && isValueNull) {
        toast("error", `Field ${singleField.toUpperCase()} is required`);
        const oldValue = fundingSource[singleField];
        dispatch(
          setWorkingFundingSource({
            ...fundingSource,
            [singleField]: oldValue,
          })
        );

        return;
      }

      if (workingFundingSource === null) return;
      thunkDispatch(
        updateFundingSource({
          delta,
          apiClient,
          id: fundingSource.id,
        })
      )
        .then(() => {
          toast("success", "Funding source was updated successfully");
        })
        .finally(() => {
          dispatch(setUpdateStatus("idle"));
        });
    },
    [
      apiClient,
      workingFundingSource,
      thunkDispatch,
      toast,
      dispatch,
      fundingSource,
      requiredFieldKeys,
    ]
  );

  const updateName = useCallback(
    async (value: string) => {
      handlePropertyUpdate({ name: value });
    },
    [handlePropertyUpdate]
  );

  const updateAmount = useCallback(
    async (value?: number) => {
      handlePropertyUpdate({ amount: value });
    },
    [handlePropertyUpdate]
  );

  const updateContactProperty = useCallback(
    async (delta: { [key in keyof Partial<ContactOnlyProps>]: string }) => {
      handlePropertyUpdate({ ...delta });
    },
    [handlePropertyUpdate]
  );

  const handleOnColorSelected = useCallback(
    (args: ColorPickerEventArgs) => {
      if (!workingFundingSource) return;
      const { selectedColor } = args;
      if (workingFundingSource?.colorId !== selectedColor) {
        handlePropertyUpdate({
          colorId: formatToFullHexColorCode(selectedColor),
        });
      }
    },
    [handlePropertyUpdate, workingFundingSource]
  );

  useEffect(() => {
    if (workingFundingSource === null) {
      dispatch(setWorkingFundingSource(fundingSource));
    }
  }, [fundingSource, dispatch, workingFundingSource]);

  useEffect(() => {
    return () => {
      dispatch(unload());
    };
  }, [dispatch]);

  return workingFundingSource && fundingSource ? (
    <VStack gap={4}>
      <HStack w="full">
        <FormControl isDisabled={isDisabled}>
          <FormLabel>Funding Source Name *</FormLabel>
          <AutoSavingInput
            value={workingFundingSource.name}
            onSave={(value) => updateName(value)}
            isRequired={true}
            isDisabled={isDisabled}
          />
        </FormControl>
        <FormControl w="max-content">
          <FormLabel w="max-content">Color ID *</FormLabel>
          <ColorPicker
            value={workingFundingSource?.colorId}
            options={DEFAULT_COLOR_CODES}
            onColorSelected={handleOnColorSelected}
            isDisabled={isDisabled}
          />
        </FormControl>
      </HStack>
      <FormControl isDisabled={isDisabled}>
        <FormLabel>Fiscal Year *</FormLabel>
        <FiscalYearSelect
          fiscalYear={{
            start: workingFundingSource.fiscalYearStart,
            end: workingFundingSource.fiscalYearEnd,
          }}
          onChange={(value) => {
            handlePropertyUpdate({
              fiscalYearStart: value.start,
              fiscalYearEnd: value.end,
            });
          }}
        />
      </FormControl>
      <FormControl
        isDisabled={isDisabled || fundingSource.fundsUnallocated > 0}
      >
        <FormLabel>Amount *</FormLabel>
        <AutoSavingCurrencyInput
          value={workingFundingSource.amount}
          isRequired={true}
          onBlur={async (e, valueAsString, valueAsNumber) => {
            updateAmount(valueAsNumber ?? 0);
          }}
          isDisabled={isDisabled || fundingSource.fundsUnallocated > 0}
        />
      </FormControl>
      <FormControl isDisabled={isDisabled}>
        <FormLabel>Contact Name *</FormLabel>
        <AutoSavingInput
          value={workingFundingSource.contactName}
          onSave={(value) => updateContactProperty({ contactName: value })}
          isRequired={true}
          isDisabled={isDisabled}
        />
      </FormControl>
      <FormControl isDisabled={isDisabled}>
        <FormLabel>Position</FormLabel>
        <AutoSavingInput
          value={workingFundingSource.contactPosition ?? ""}
          onSave={(value) => updateContactProperty({ contactPosition: value })}
          isRequired={false}
          isDisabled={isDisabled}
        />
      </FormControl>
      <FormControl isDisabled={isDisabled}>
        <FormLabel>Email</FormLabel>
        <AutoSavingInput
          value={workingFundingSource.contactEmail ?? ""}
          onSave={(value) => updateContactProperty({ contactEmail: value })}
          isRequired={false}
          isDisabled={isDisabled}
        />
      </FormControl>
      <FormControl isDisabled={isDisabled}>
        <FormLabel>Phone</FormLabel>
        <AutoSavingInput
          value={workingFundingSource.contactPhone ?? ""}
          onSave={(value) => updateContactProperty({ contactPhone: value })}
          isRequired={false}
          isDisabled={isDisabled}
        />
      </FormControl>
    </VStack>
  ) : null;
};
