import { Button } from "@chakra-ui/react";
import { ApiFundingSource } from "@operations-hero/lib-api-client";
import { FC, useCallback } from "react";
import { RiAddLine } from "react-icons/ri";
import { useAllocationsContext } from "../../../../../components/allocations/AllocationsContext";
import { FundingSourceAllocation } from "../../../../../components/allocations/types";
type AddAllocationButtonProps = {
  onAddAllocation?: () => void;
};

export const AddAllocationButton: FC<AddAllocationButtonProps> = ({
  onAddAllocation,
}) => {
  const { allocations, setAllocations } =
    useAllocationsContext<ApiFundingSource>();
  const addAllocation = useCallback(() => {
    const tempList: FundingSourceAllocation[] = [
      ...allocations,
      { source: null, amount: 0 },
    ];
    setAllocations(tempList);
    onAddAllocation && onAddAllocation();
  }, [allocations, onAddAllocation, setAllocations]);

  return (
    <Button
      onClick={addAllocation}
      leftIcon={<RiAddLine />}
      colorScheme="blue"
      variant="outline"
      size="sm"
    >
      Add Fund
    </Button>
  );
};
