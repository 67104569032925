import * as yup from "yup";

export const defaultEventFormSchema = {
  name: yup.string().required("Name is required").nullable(),
  eventGroup: yup.object().required("Group is required").nullable(),
  venue: yup.object().required("Venue is required").nullable(),
  spaces: yup
    .array()
    .min(1, "You must select at least 1 space")
    .required("Spaces is required")
    .nullable(),
  eventContact: yup.object().nullable().required("Event Contact is required"),
};

export const getEventFormSchema = (requiredAttendees: boolean) => {
  if (!requiredAttendees) {
    return yup.object().shape(defaultEventFormSchema);
  }

  const eventSchema = {
    ...defaultEventFormSchema,
    attendees: yup
      .number()
      .min(1, "The number of attendes is required, and must be greater than 0")
      .required(),
  };

  return yup.object().shape(eventSchema);
};
