import { HStack, Text } from "@chakra-ui/react";
import {
  ApiDelta,
  ApiProject,
  ApiTimelineEntry,
  ApiTimelineEntryObjectRelation,
  DeltaObject,
  TimelineEntryAction,
} from "@operations-hero/lib-api-client";
import moment from "moment";
import { FC, Fragment } from "react";
import { MdArrowRightAlt } from "react-icons/md";
import { StatusBadge } from "../../../../components/badges/StatusBadge";
import { UserBadge } from "../../../../components/badges/UserBadge";
import {
  DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS,
  LocaleDate,
} from "../../../../components/dates/LocaleDate";

const FISCAL_YEAR_FORMAT = "MMM YYYY";
export interface DeltaElementProps {
  delta: ApiDelta;
  relationObject: ApiTimelineEntryObjectRelation | null;
}

const DeltaElement: FC<DeltaElementProps> = ({ delta, relationObject }) => {
  const { field } = delta as DeltaObject<ApiProject>;
  switch (field) {
    case "name": {
      const oldName = delta.oldValue as ApiProject["name"];
      const newName = delta.newValue as ApiProject["name"];

      return (
        <HStack>
          <Text>{oldName}</Text>
          <MdArrowRightAlt />
          <Text>{newName}</Text>
        </HStack>
      );
    }

    case "description": {
      const oldName = delta.oldValue as ApiProject["description"];
      const newName = delta.newValue as ApiProject["description"];

      return (
        <HStack>
          <Text>{oldName}</Text>
          <MdArrowRightAlt />
          <Text>{newName}</Text>
        </HStack>
      );
    }

    case "start": {
      const oldStart = delta.oldValue as ApiProject["start"];
      const newStart = delta.newValue as ApiProject["start"];

      return (
        <HStack>
          {oldStart ? (
            <LocaleDate
              date={oldStart}
              options={{
                ...DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS,
                month: "short",
              }}
            />
          ) : (
            <Text>Undefined</Text>
          )}
          <MdArrowRightAlt />
          {newStart ? (
            <LocaleDate
              date={newStart}
              options={{
                ...DEFAULT_LOCALE_MONTH_DATE_TIME_OPTS,
                month: "short",
              }}
            />
          ) : (
            <Text>Undefined</Text>
          )}
        </HStack>
      );
    }

    case "end": {
      const oldEnd = delta.oldValue as ApiProject["end"];
      const newEnd = delta.newValue as ApiProject["end"];

      return (
        <HStack>
          {oldEnd ? (
            <LocaleDate
              date={oldEnd}
              options={{
                month: "short",
                day: "2-digit",
                year: "numeric",
              }}
            />
          ) : (
            <Text>Undefined</Text>
          )}
          <MdArrowRightAlt />
          {newEnd ? (
            <LocaleDate
              date={newEnd}
              options={{
                month: "short",
                day: "2-digit",
                year: "numeric",
              }}
            />
          ) : (
            <Text>Undefined</Text>
          )}
        </HStack>
      );
    }

    case "status": {
      const oldStatus = delta.oldValue as ApiProject["status"];
      const newStatus = delta.newValue as ApiProject["status"];
      return (
        <HStack>
          <StatusBadge status={oldStatus}></StatusBadge>
          <MdArrowRightAlt />
          <StatusBadge status={newStatus}></StatusBadge>
        </HStack>
      );
    }

    case "supervisors": {
      const oldSupervisor = delta.oldValue as
        | ApiProject["supervisors"][0]
        | null;
      if (oldSupervisor) {
        return <UserBadge value={oldSupervisor} />;
      }
      const newSupervisor = delta.newValue as
        | ApiProject["supervisors"][0]
        | null;
      if (newSupervisor) {
        return <UserBadge value={newSupervisor} />;
      }
      return <Text>-</Text>;
    }
    case "fiscalYearStart": {
      const oldFiscalYearStart =
        delta.oldValue as ApiProject["fiscalYearStart"];
      const oldFiscalYearEnd = moment(oldFiscalYearStart)
        .add({ year: 1 })
        .toISOString();

      const newFiscalYearStart =
        delta.newValue as ApiProject["fiscalYearStart"];
      const newFiscalYearEnd = moment(newFiscalYearStart)
        .add({ year: 1 })
        .toISOString();

      let oldFiscalYear = "To be determined";
      let newFiscalYear = "To be determined";

      if (oldFiscalYearStart)
        oldFiscalYear = `${moment(oldFiscalYearStart).format(FISCAL_YEAR_FORMAT)}- ${moment(oldFiscalYearEnd).format(FISCAL_YEAR_FORMAT)}`;
      if (newFiscalYearStart)
        newFiscalYear = `${moment(newFiscalYearStart).format(FISCAL_YEAR_FORMAT)} - ${moment(newFiscalYearEnd).format(FISCAL_YEAR_FORMAT)}`;

      return (
        <HStack>
          <Text>{oldFiscalYear}</Text>
          <MdArrowRightAlt />
          <Text>{newFiscalYear}</Text>
        </HStack>
      );
    }
    default:
      return null;
  }
};

type EntryBodyProps = {
  entry: ApiTimelineEntry;
};

export const ProjectTimelineBody: FC<EntryBodyProps> = ({ entry }) => {
  const { action, delta, relationObject } = entry;

  if (action === TimelineEntryAction.create || !delta) {
    return null;
  }

  return (
    <>
      {delta.map((deltaItem, idx) => (
        <Fragment key={`${deltaItem.field}::${idx}`}>
          <DeltaElement delta={deltaItem} relationObject={relationObject} />
        </Fragment>
      ))}
    </>
  );
};
