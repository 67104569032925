import { createSlice } from "@reduxjs/toolkit";

import {
  ApiBudget,
  ApiBudgetFundingSource,
  ApiBudgetWithFundingSources,
  ApiClient,
  ApiFundingSource,
  ApiFundingSourceSummary,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../..";
import { Allocation } from "../../../components/allocations/types";
import { LoadingStatus } from "../types";
import { updateBudget } from "./budget-form.slice";

type GetBudgetParams = {
  apiClient: ApiClient;
  id: string;
};

export const getBudget = createAsyncThunk<
  ApiBudgetWithFundingSources,
  GetBudgetParams
>("budget/get", async ({ apiClient, id }, thunkAPI) => {
  const rootState = thunkAPI.getState() as RootState;
  const { rejectWithValue } = thunkAPI;
  const { currentAccount } = rootState.auth;
  const fundingSourcesResponse = await apiClient.findBudgetsWithFundingSources(
    currentAccount.id,
    {
      budgetIds: [id],
      pageSize: 50,
    }
  );
  if (fundingSourcesResponse.data.length > 0) {
    const budgetWithFundingSources = fundingSourcesResponse.data[0];
    return budgetWithFundingSources;
  } else {
    return rejectWithValue(null);
  }
});

type FindBudgetFundingSourcesParams = {
  apiClient: ApiClient;
  budgetId: string;
};

export const findBudgetFundingSources = createAsyncThunk<
  ApiBudgetFundingSource[],
  FindBudgetFundingSourcesParams
>("budget/funding-source/find", async ({ apiClient, budgetId }, thunkAPI) => {
  const rootState = thunkAPI.getState() as RootState;
  const { rejectWithValue } = thunkAPI;
  const { currentAccount } = rootState.auth;
  const fundingSourcesResponse = await apiClient.findBudgetFundingSources(
    currentAccount.id,
    budgetId,
    {}
  );
  if (fundingSourcesResponse.data.length > 0) {
    const budgetWithFundingSources = fundingSourcesResponse.data;
    return budgetWithFundingSources;
  } else {
    return rejectWithValue(null);
  }
});

type BudgetPageSliceProps = {
  budget: ApiBudget | null;
  allocations: Allocation<ApiFundingSource | ApiFundingSourceSummary>[];
  budgetLoadingStatus: LoadingStatus;
};

const initialState: BudgetPageSliceProps = {
  budget: null,
  allocations: [],
  budgetLoadingStatus: "idle",
};

const budgetPageSlice = createSlice({
  name: "budget-page",
  initialState: initialState,
  reducers: {
    unload: (state) => {
      state.budget = initialState.budget;
      state.budgetLoadingStatus = initialState.budgetLoadingStatus;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBudget.pending, (state) => {
      state.budgetLoadingStatus = "pending";
    });
    builder.addCase(getBudget.rejected, (state) => {
      state.budgetLoadingStatus = "rejected";
    });
    builder.addCase(getBudget.fulfilled, (state, action) => {
      state.budget = action.payload;
      state.allocations = action.payload.fundingSources.map((fs) => {
        return {
          source: {
            ...fs.source,
            maxAllocation: fs.source.fundsUnallocated,
            remaining: fs.source.fundsUnallocated,
          },
          amount: fs.amount,
        };
      });
      state.budgetLoadingStatus = "fulfilled";
    });
    builder.addCase(updateBudget.fulfilled, (state, action) => {
      state.budget = action.payload;
    });

    builder.addCase(findBudgetFundingSources.fulfilled, (state, action) => {
      state.allocations = action.payload.map((bfs) => ({
        source: {
          ...(bfs.fundingSource as ApiFundingSource),
          remaining: (bfs.fundingSource as ApiFundingSource).fundsUnallocated,
        },
        amount: bfs.amountAllocated,
      }));
    });
  },
});

export const { unload } = budgetPageSlice.actions;
export default budgetPageSlice.reducer;
