import {
  Flex,
  Icon,
  IconButton,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { FC, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store";
import {
  DEFAULT_FILTERS_INVENTORY,
  updateFilters,
} from "../../../store/inventory/inventory-order-item-list.slice";

import { MdQrCode } from "react-icons/md";
import { RiFilterOffLine } from "react-icons/ri";
import { AccountSearchBox } from "../../../components/inputs/SearchBox";
import { QrQuickScanModal } from "../../../components/qr-quick-scan/QrQuickScanModal";
import { checkArray } from "../../../utils/compareObjects";
import { getId } from "../../../utils/getId";
import { VendorSupplierFilter } from "../filters/SupplierFilter";
import LowStockFilter from "./filters/LowStockFilter";

export const InventoryOrderFilters: FC = () => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const { isOpen: isOpenQrScanner, onOpen, onClose } = useDisclosure();
  const isDesktop = useBreakpointValue({ xs: false, sm: false, md: true });
  const bgColor = useColorModeValue("blue.50", "blue.900");
  const dispatch = useDispatch();
  const { filters } = useSelector(
    (state: RootState) => state.inventoryOrderItemsListSlice
  );

  const handleOnChangeSupplier = useCallback(
    (values: string[]) => {
      const newValues = checkArray<string[]>(values);
      dispatch(updateFilters({ supplier: newValues }));
    },
    [dispatch]
  );

  const handleOnChangeLowStock = useCallback(
    (showLowStock: boolean) => {
      dispatch(updateFilters({ showLowStock }));
    },
    [dispatch]
  );
  const handleRefreshFilter = useCallback(() => {
    dispatch(updateFilters(DEFAULT_FILTERS_INVENTORY));
    if (searchInputRef && searchInputRef.current)
      searchInputRef.current.value = "";
  }, [dispatch, searchInputRef]);

  const handleQrData = useCallback(
    (search: string) => {
      dispatch(updateFilters({ search }));
      onClose();
    },
    [dispatch, onClose]
  );

  return (
    <Flex
      py={2}
      px={4}
      gap={4}
      w="100%"
      minH="20px"
      bgColor={bgColor}
      alignItems="center"
    >
      <Flex
        w="100%"
        display={["none", "none", "flex"]}
        justifyContent="space-between"
      >
        <Flex gap={4}>
          <VendorSupplierFilter
            values={
              filters.supplier
                ? Array.isArray(filters.supplier)
                  ? filters.supplier.map((sup) => getId(sup))
                  : [getId(filters.supplier)]
                : []
            }
            onChange={handleOnChangeSupplier}
            title="All Suppliers"
          />
          <LowStockFilter
            showLowStock={filters.showLowStock || true}
            onChange={handleOnChangeLowStock}
          />
        </Flex>

        <Flex
          gap={2}
          alignItems="center"
          w={["100%", "100%", "auto"]}
          justifyContent="space-between"
        >
          <IconButton
            colorScheme="blue"
            variant="outline"
            onClick={onOpen}
            aria-label="Search by QR"
            display={["none", "none", "block"]}
            icon={<Icon as={MdQrCode} boxSize="26px" />}
          />

          <AccountSearchBox
            rest={{ display: ["none", "none", "block"] }}
            searchPlaceholder="Search for an item"
            onInputChange={(search) =>
              dispatch(updateFilters({ search, page: 1 }))
            }
            ref={searchInputRef}
          />

          <IconButton
            ml={2}
            icon={<Icon as={RiFilterOffLine} />}
            aria-label="Sort"
            bgColor={bgColor}
            colorScheme="blue"
            variant="outline"
            size={isDesktop ? "md" : "sm"}
            onClick={handleRefreshFilter}
          />
        </Flex>
      </Flex>
      {isOpenQrScanner && (
        <QrQuickScanModal
          isOpen={isOpenQrScanner}
          onClose={onClose}
          onData={handleQrData}
        />
      )}
    </Flex>
  );
};
