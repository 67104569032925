// Regex for full hex color codes : #FFFFFF, #000
export const HEX_COLOR_CODE_FULL = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

// Regex for short hex color code format : FFFFFF, 000
export const HEX_COLOR_CODE_SHORT = /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

/**
 *
 * @param s Any string
 * @returns The boolean value if the strings matches the Full Hex Color Code pattern: #FFFFFF, #000
 */
export const isHexColorCodeFull = (s: string) => {
  return !!s.match(HEX_COLOR_CODE_FULL);
};

/**
 *
 * @param s Any string
 * @returns The boolean value if the strings matches the Short Hex Color Code pattern: FFFFFF, 000
 */
export const isHexColorCodeShort = (s: string) => {
  return !!s.match(HEX_COLOR_CODE_SHORT);
};

/**
 *
 * @param hexCode The value to check
 * @param validate If true, it will check if the provided hexCode is a valid (Full | Short) Hex Color Code. True by default
 * @returns The formatted hex code. If it did not pass validation, will return an empty string
 */
export const formatToFullHexColorCode = (
  hexCode: string,
  validate: boolean = true
) => {
  if (!validate) return `#${hexCode}`;
  if (isHexColorCodeFull(hexCode)) return hexCode;
  if (isHexColorCodeShort(hexCode)) return `#${hexCode}`;
  return "";
};

/**
 *
 * @param hexCode The value to check
 * @param validate If true, it will check if the provided hexCode is a valid (Full | Short) Hex Color Code. True by default
 * @returns The formatted hex code. If it did not pass validation, will return an empty string
 */
export const formatToShortHexColorCode = (
  hexCode: string,
  validate: boolean = true
) => {
  if (!validate && hexCode.length > 0) return hexCode.substring(1);
  if (isHexColorCodeShort(hexCode)) return hexCode;
  if (validate && hexCode.length > 0 && isHexColorCodeFull(hexCode))
    return hexCode.substring(1);

  return "";
};

/**
 *
 * @param hex An hex color code
 * @param alpha A custom transparency value, RGBA
 * @returns The equivalent of the hex color code in RGB or RGBA
 */
export function hexToRGB(hex: string, alpha?: number) {
  if (typeof hex !== "string" || hex[0] !== "#") return null;

  const stringValues =
    hex.length === 4
      ? [hex.slice(1, 2), hex.slice(2, 3), hex.slice(3, 4)].map(
          (n) => `${n}${n}`
        )
      : [hex.slice(1, 3), hex.slice(3, 5), hex.slice(5, 7)];
  const intValues = stringValues.map((n) => parseInt(n, 16));

  return typeof alpha === "number"
    ? `rgba(${intValues.join(", ")}, ${alpha})`
    : `rgb(${intValues.join(", ")})`;
}

/**
 *
 * @param hex A hex color code: #FFF #FFFFFF
 * @param lightness A number value that represents how much lightness will added to the color. Adds to the L of HSL
 * @param darkness A number value that represents how much darkness will added to the color. Substracts from the L of HSL
 * @param autoAdjust A boolean flag to let the function correct the new HSL code in case it gets too close to plain white or black (#FFFF or #000)
 * @returns The equivalent of the hex color code in HSL
 */
export function hexToHSL(
  hex: string,
  lightness?: number,
  darkness?: number,
  autoAdjust?: boolean
) {
  const isHexCodeValid = isHexColorCodeFull(hex);

  if (!isHexCodeValid) return undefined;

  // Convert hex to RGB
  let r: any = 0;
  let g: any = 0;
  let b: any = 0;

  if (hex.length === 4) {
    r = "0x" + hex[1] + hex[1];
    g = "0x" + hex[2] + hex[2];
    b = "0x" + hex[3] + hex[3];
  } else if (hex.length === 7) {
    r = "0x" + hex[1] + hex[2];
    g = "0x" + hex[3] + hex[4];
    b = "0x" + hex[5] + hex[6];
  }
  // Convert RGB to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  let cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin,
    h = 0,
    s = 0,
    l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100);

  if (lightness && l + lightness > 95 && autoAdjust) {
    l += 20;
  } else {
    l += lightness ?? 0;
  }

  if (darkness && l - darkness <= 10 && autoAdjust) {
    l -= 55;
  } else {
    l -= darkness ?? 0;
  }

  return "hsl(" + h + "," + s + "%," + l.toFixed(1) + "%)";
}
