import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiInventoryOrderStatus,
  ApiInventoryOrderSummary,
  ExportType,
} from "@operations-hero/lib-api-client";
import { FC, MouseEvent, useCallback } from "react";
import { TbDotsVertical } from "react-icons/tb";
import { useDispatch } from "react-redux";
import { ExportModal } from "../../../../components/export/ExportModal";
import { setModalState } from "../../../../store/inventory/inventory-order.slice";
import PrintInventoryOrder from "./PrintInventoryOrder";

interface InventoryOrderMenuProps {
  order: ApiInventoryOrderSummary;
}

export const InventoryOrderMenu: FC<InventoryOrderMenuProps> = ({ order }) => {
  const dispatch = useDispatch();

  const {
    isOpen: isExportModalOpen,
    onOpen: onExportModalOpen,
    onClose: onExportModalClose,
  } = useDisclosure();

  const {
    isOpen: isPrintModalOpen,
    onOpen: onPrintModalOpen,
    onClose: onPrintModalClose,
  } = useDisclosure();

  const avoidPropagation = useCallback(
    (e: MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
    },
    []
  );

  const handleOnOpenTimelineModal = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      avoidPropagation(e);
      dispatch(
        setModalState({ workingOrder: order, isOpenTimelineModal: true })
      );
    },
    [avoidPropagation, dispatch, order]
  );

  const handleOnOpenUpdateModal = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      avoidPropagation(e);
      dispatch(setModalState({ workingOrder: order, isOpenUpdateModal: true }));
    },
    [avoidPropagation, dispatch, order]
  );

  const handleOnOpenDeclineModal = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      avoidPropagation(e);
      dispatch(
        setModalState({ workingOrder: order, isOpenDeclineModal: true })
      );
    },
    [avoidPropagation, dispatch, order]
  );

  const handleOnOpenReviewModal = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      avoidPropagation(e);
      dispatch(setModalState({ workingOrder: order, isOpenReviewModal: true }));
    },
    [avoidPropagation, dispatch, order]
  );
  const handleOnOpenRepeatModal = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      avoidPropagation(e);
      dispatch(setModalState({ workingOrder: order, isOpenRepeatModal: true }));
    },
    [avoidPropagation, dispatch, order]
  );
  return (
    <Flex
      flex={1}
      justifyContent="flex-end"
      onClick={(e) => {
        avoidPropagation(e);
      }}
    >
      <Menu>
        <MenuButton>
          <IconButton
            size="sm"
            aria-label="options"
            icon={<TbDotsVertical />}
          />
        </MenuButton>

        <MenuList>
          {(order.status === ApiInventoryOrderStatus.partialDelivery ||
            order.status === ApiInventoryOrderStatus.ordered) && (
            <MenuItem onClick={(e) => handleOnOpenUpdateModal(e)}>
              {order.status === ApiInventoryOrderStatus.partialDelivery
                ? "Update"
                : "Recieve"}{" "}
              Order
            </MenuItem>
          )}
          {order.status !== ApiInventoryOrderStatus.declined &&
            order.status !== ApiInventoryOrderStatus.delivered && (
              <MenuItem onClick={(e) => handleOnOpenDeclineModal(e)}>
                Decline Order
              </MenuItem>
            )}
          {order.status === ApiInventoryOrderStatus.delivered && (
            <MenuItem onClick={(e) => handleOnOpenReviewModal(e)}>
              Review Order
            </MenuItem>
          )}
          {(order.status === ApiInventoryOrderStatus.declined ||
            order.status === ApiInventoryOrderStatus.delivered) && (
            <MenuItem onClick={(e) => handleOnOpenRepeatModal(e)}>
              Repeat Order
            </MenuItem>
          )}
          <MenuItem onClick={() => onExportModalOpen()}>Export Order</MenuItem>
          <MenuItem onClick={() => onPrintModalOpen()}>Print Order</MenuItem>
          <MenuItem onClick={(e) => handleOnOpenTimelineModal(e)}>
            Order History
          </MenuItem>
        </MenuList>
      </Menu>
      {isExportModalOpen && (
        <ExportModal
          total={order.totalItems}
          options={{ inventoryOrderId: order.id }}
          isOpen={isExportModalOpen}
          onClose={onExportModalClose}
          type={ExportType.orders}
        />
      )}
      {isPrintModalOpen && (
        <PrintInventoryOrder
          inventoryOrderId={order.id}
          onClose={onPrintModalClose}
          downloadName="order"
        />
      )}
    </Flex>
  );
};
