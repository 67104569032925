import { RestrictedPolicy } from "@operations-hero/lib-api-client";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../components/auth/AuthProvider";
import { RootState } from "../store";
import { useCategoryUtils } from "../utils/categoryUtils";
import { useLocationUtils } from "../utils/locationUtils";

const useReviewersManagement = (workflows?: string[]) => {
  const { isReviewer, isAdmin, accountSettings, policyMap, locationMap } =
    useSelector((state: RootState) => state.localCache);

  const { isProductAdmin, isProductStandard } = useAuthentication();
  const { findAllChildrenForNodesRecursive } = useCategoryUtils();
  const { findAllChildrenForNodes } = useLocationUtils();

  const { categories, locations, resWorkflows, showTaskbooksSchedules } =
    useMemo(() => {
      const hasSetting =
        accountSettings["allow-reviewers-to-manage-schedules-and-taskbooks"] ===
        true;

      const showTaskbooksSchedules =
        hasSetting &&
        isProductStandard &&
        !isProductAdmin &&
        (isReviewer || isAdmin);

      if (!showTaskbooksSchedules) {
        return {
          categories: undefined,
          locations: undefined,
          workflows: undefined,
          showTaskbooksSchedules: false,
        };
      }

      const extractUniqueValues = (
        map: Record<string, any>,
        workflowKeys: string[],
        key: "categories" | "locations"
      ): string[] | undefined => {
        const values: string[] = [];

        for (const policyKey of workflowKeys) {
          const reviewer = map[policyKey]?.reviewer;

          if (
            (typeof reviewer === "boolean" && reviewer) ||
            (typeof reviewer !== "boolean" &&
              !(reviewer as RestrictedPolicy)[key]?.length)
          ) {
            return [];
          }

          const keyValues = (reviewer as RestrictedPolicy)[key];
          if (keyValues?.length) {
            if (key === "categories") {
              const allCategories = findAllChildrenForNodesRecursive(keyValues);
              values.push(...Array.from(allCategories).map((cat) => cat.id));
            } else if (key === "locations") {
              const hidratedLocations = keyValues.map(
                (val) => locationMap[val]
              );
              const allLocations = findAllChildrenForNodes(hidratedLocations);
              values.push(...allLocations.map((l) => l.id));
            }
          }
        }

        return Array.from(new Set(values));
      };

      const resWorkflows: string[] = [];

      Object.keys(policyMap).forEach((key) => {
        if (policyMap[key].reviewer) {
          resWorkflows.push(key);
        }
      });

      const workflowKeys = workflows?.length
        ? workflows
        : Object.keys(policyMap);

      return {
        categories: policyMap
          ? extractUniqueValues(policyMap, workflowKeys, "categories")
          : undefined,
        locations: policyMap
          ? extractUniqueValues(policyMap, workflowKeys, "locations")
          : undefined,
        resWorkflows,
        showTaskbooksSchedules: true,
      };
    }, [
      accountSettings,
      isReviewer,
      isAdmin,
      isProductStandard,
      isProductAdmin,
      policyMap,
      workflows,
      findAllChildrenForNodesRecursive,
      findAllChildrenForNodes,
      locationMap,
    ]);

  return {
    categories: categories && categories.length === 0 ? undefined : categories,
    locations: locations && locations.length === 0 ? undefined : locations,
    workflows:
      resWorkflows && resWorkflows.length === 0 ? undefined : resWorkflows,
    showTaskbooksSchedules,
  };
};

export default useReviewersManagement;
