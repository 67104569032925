import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Portal,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import {
  ApiProject,
  ApiResponseWithErrors,
  BulkUpdateApiRequest,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useRef } from "react";
import { RiAddLine, RiArrowDownSLine, RiUploadLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../../components/auth/AuthProvider";
import { RootState, useThunkDispatch } from "../../../../../store";
import { setNewRequestDialogIsOpen } from "../../../../../store/new-request-form.slice";
import { findProjectRequests } from "../../../../../store/planning-hq/requests/findRequests.thunk";
import { addBulkRequestsToSchedule } from "../../../../../store/planning-hq/scheduling/scheduling-requests.thunk";
import { RequestListModal } from "../../../requests/RequestListModal";
import { RequestItem } from "./RequestItem";
import { RequestList } from "./RequestList";
import { FilterTags } from "./filters/FilterTags";
import { RequestFilters } from "./filters/RequestFilters";

type RequestsProps = { project: ApiProject };

export const Requests: FC<RequestsProps> = ({ project }) => {
  const { apiClient } = useAuthentication();
  const { sort, filters, requests } = useSelector(
    (state: RootState) => state.requestList
  );

  const { loadingStatus, total } = useSelector(
    (state: RootState) => state.projectRequests
  );

  const { requestAdded, schedulingCache } = useSelector(
    (state: RootState) => state.projectScheduling
  );

  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const init = useRef<{
    triggered: boolean;
    status: "idle" | "pending" | "fulfilled";
  }>({ triggered: false, status: "idle" });

  const {
    isOpen: isAddExistingRequestOpen,
    onClose: onAddExistingRequestClose,
    onOpen: onAddExistingRequestOpen,
  } = useDisclosure();

  const handleNewRequestClick = useCallback(() => {
    dispatch(
      setNewRequestDialogIsOpen({ isOpen: true, source: "planning.scheduling" })
    );
  }, [dispatch]);

  const onRequestsAdded = useCallback(
    async (
      values: ApiResponseWithErrors<
        BulkUpdateApiRequest[],
        BulkUpdateApiRequest
      >
    ) => {
      thunkDispatch(
        findProjectRequests({ apiClient, projectId: project.id })
      ).then(() => {
        thunkDispatch(
          addBulkRequestsToSchedule({
            apiClient,
            projectId: project.id,
            requests: values.data,
          })
        );
      });
    },
    [thunkDispatch, apiClient, project.id]
  );

  useEffect(() => {
    if (init.current.triggered === false && init.current.status === "idle") {
      init.current = {
        triggered: true,
        status: "pending",
      };

      thunkDispatch(
        findProjectRequests({
          apiClient,
          projectId: project.id,
        })
      ).then(() => {
        init.current["status"] = "fulfilled";
      });
    }
  }, [apiClient, project, thunkDispatch]);

  useEffect(() => {
    if (init.current["triggered"] && init.current["status"] !== "fulfilled")
      return;
    thunkDispatch(findProjectRequests({ apiClient, projectId: project.id }));
  }, [
    thunkDispatch,
    project.id,
    apiClient,
    sort,
    filters.date,
    filters.search,
  ]);

  useEffect(() => {
    if (requestAdded.status === "fulfilled") {
      thunkDispatch(findProjectRequests({ apiClient, projectId: project.id }));
    }
  }, [requestAdded, apiClient, project.id, thunkDispatch]);

  return (
    <>
      <HStack gap={4} justifyContent="space-between">
        <Heading as="h3" fontSize="larger">
          Requests
        </Heading>
        <Menu>
          <MenuButton
            as={Button}
            rightIcon={<RiArrowDownSLine />}
            colorScheme="blue"
            leftIcon={<RiAddLine />}
            size="sm"
          >
            Add request
          </MenuButton>
          <Portal>
            <MenuList>
              <MenuItem onClick={handleNewRequestClick}>
                <HStack>
                  <AddIcon w={3} h={3} />
                  <Text>Create new request</Text>
                </HStack>
              </MenuItem>
              <MenuDivider />
              <MenuItem onClick={onAddExistingRequestOpen}>
                <HStack>
                  <Icon as={RiUploadLine} w={3} h={3} />
                  <Text>Add existing requests</Text>
                </HStack>
              </MenuItem>
            </MenuList>
          </Portal>
        </Menu>
      </HStack>
      <RequestFilters project={project} />
      <HStack w="full" justifyContent="space-between">
        <Text fontSize="sm">{`Showing ${total}`}</Text>
        <FilterTags project={project} />
      </HStack>
      <Box
        sx={{
          flex: "1 1 auto",
          overflowY: "auto",
          minH: "0px",
        }}
      >
        <RequestList>
          {loadingStatus === "fulfilled" && (
            <>
              {requests.length === 0 ? (
                <Text color="gray.500">
                  There are no requests to show in this project
                </Text>
              ) : (
                requests.map((r) => {
                  return (
                    <RequestItem
                      request={r}
                      key={`prj-request::${r.id}`}
                      draggable={
                        r.scheduling.start &&
                        r.scheduling.due &&
                        schedulingCache[r.id]
                          ? false
                          : true
                      }
                    />
                  );
                })
              )}
            </>
          )}

          {loadingStatus === "pending" && (
            <Text color="gray.500">Loading...</Text>
          )}

          {loadingStatus === "rejected" && (
            <Text color="gray.500">
              An error ocurred while loading requests
            </Text>
          )}
        </RequestList>
      </Box>
      {isAddExistingRequestOpen && (
        <RequestListModal
          isOpen={isAddExistingRequestOpen}
          onClose={onAddExistingRequestClose}
          projectId={project.id}
          onRequestsAdded={onRequestsAdded}
        />
      )}
    </>
  );
};
