import { useDisclosure } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { PdfViewerModalNative } from "../../../../components/pdf-viewer-modal/PdfViewerModalNative";
import { useShowToast } from "../../../../hooks/showToast";
import { fromBase64ToBlob } from "../../../../utils/pdf";

export interface PrintInventoryOrderProps {
  downloadName: string;
  inventoryOrderId: string;
  onClose?: () => void;
}

const PrintInventoryOrder: FC<PrintInventoryOrderProps> = ({
  inventoryOrderId,
  downloadName,
  onClose,
}) => {
  const { currentAccount, apiClient } = useAuthentication();
  const [isLoading, setIsLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const showToast = useShowToast();

  const {
    isOpen: pdfModalIsOpen,
    onOpen: pdfModalOnOpen,
    onClose: pdfModalOnClose,
  } = useDisclosure();

  useEffect(() => {
    apiClient
      .generateInventoryOrderPdf(currentAccount.id, {
        base64: true,
        inventoryOrderId,
      })
      .then((base64STR: string) => {
        const blob = fromBase64ToBlob(base64STR);
        const pdfUrl = URL.createObjectURL(blob);
        setIsLoading(false);
        setPdfUrl(pdfUrl);
        pdfModalOnOpen();
      })
      .catch(() => {
        showToast("error", "Something went wrong, please try again");
        setIsLoading(false);
      });
  }, [
    apiClient,
    currentAccount.id,
    inventoryOrderId,
    pdfModalOnOpen,
    showToast,
  ]);

  const revokeObjectURL = useCallback(() => {
    if (pdfUrl) {
      URL.revokeObjectURL(pdfUrl);
      pdfModalOnClose();
      onClose && onClose();
    }
  }, [pdfUrl, pdfModalOnClose, onClose]);

  return pdfModalIsOpen && pdfUrl !== null && !isLoading ? (
    <PdfViewerModalNative
      isOpen={pdfModalIsOpen}
      onClose={revokeObjectURL}
      pdfUrl={pdfUrl}
      downloadName={downloadName}
    />
  ) : null;
};

export default PrintInventoryOrder;
