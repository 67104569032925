import {
  Box,
  Button,
  Center,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Stack,
  Text,
  useInterval,
} from "@chakra-ui/react";
import {
  ApiExport,
  ExportType,
  FindExportOptions,
  OutputType,
} from "@operations-hero/lib-api-client";
import { useCallback, useState } from "react";
import { useShowToast } from "../../hooks/showToast";
import { useAuthentication } from "../auth/AuthProvider";

const EXPORT_MAX = 50000;

export interface ExportModalProps {
  isOpen: boolean;
  onClose: () => void;
  total: number;
  options: FindExportOptions;
  type: ExportType;
}

const title: Record<ExportType, string> = {
  request: "Requests",
  asset: "Assets",
  transaction: "Transactions",
  user: "Users",
  workflow: "Workflows",
  event: "Events",
  location: "Locations",
  scheduledRequest: "Scheduled Requests",
  invoice: "Invoices",
  payment: "Payments",
  userGroupMembers: "User Group Members",
  inventoryAdjustmentsLog: "Inventory Adjustments",
  portalUsers: "Portal Users",
  eventGroup: "Event Groups",
  eventOccurences: "Event Occurrences",
  orders: "Inventory Order Items",
};

export const ExportModal = ({
  isOpen,
  onClose,
  total,
  options,
  type,
}: ExportModalProps) => {
  const { currentAccount, apiClient } = useAuthentication();
  const [isExporting, setIsExporting] = useState(false);

  const [exportType, setExportType] = useState<ApiExport>();

  const showToast = useShowToast();

  const startExport = useCallback(() => {
    setIsExporting(true);

    "pageSize" in options && delete options.pageSize;
    "page" in options && delete options.page;

    apiClient
      .createExport(currentAccount.id, {
        type,
        output: OutputType.xlsx,
        options,
      })
      .then((r) => setExportType(r))
      .catch(() => {
        setIsExporting(false);
      });
  }, [apiClient, currentAccount, options, type]);

  useInterval(
    () => {
      if (!exportType) {
        return;
      }
      apiClient.getExport(currentAccount.id, exportType.id).then((e) => {
        if (!e.url) {
          if (e.errorMessage) {
            showToast("error", e.errorMessage);
            onClose();
          }
          return;
        }
        // trigger a download
        // see https://stackoverflow.com/questions/50694881/how-to-download-file-in-react-js
        const link = document.createElement("a");
        link.href = e.url;
        link.setAttribute("download", "Transaction-Export.xlsx");
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode?.removeChild(link);
        setExportType(undefined);
        onClose();
      });
    },
    exportType ? 1000 : null
  );

  return (
    <Modal
      blockScrollOnMount={false}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior={"inside"}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text>Export {title[type]}</Text>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {!isExporting ? (
            <Stack>
              <Box>
                <Text>You can export the current filtered list to .xlsx</Text>
              </Box>
              <Box>
                <Text>
                  Export {total > EXPORT_MAX ? EXPORT_MAX : total} {title[type]}
                  ?
                </Text>
              </Box>
            </Stack>
          ) : (
            <Stack>
              <Text>Please wait while we prepare the export</Text>
              <Center py={5}>
                <Spinner size="lg" />
              </Center>
            </Stack>
          )}
        </ModalBody>
        <ModalFooter>
          {!isExporting && (
            <Button colorScheme="blue" onClick={startExport}>
              Begin Export
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
