import {
  ApiRequest,
  ApiRequestPriority,
} from "@operations-hero/lib-api-client";

export const ViewType = {
  MONTHLY: "resourceTimelineMonth",
  WEEKLY: "resourceTimelineWeek",
  YEARLY: "resourceTimelineYear",
} as const;

export type ViewTypeKey = keyof typeof ViewType;
export type ViewTypeValue = (typeof ViewType)[ViewTypeKey];

export type TimelineItemProps = {
  request: ApiRequest;
};

export const ColorMapBase = (colorMode: "light" | "dark") => {
  return {
    [ApiRequestPriority.expedite]: [
      colorMode === "light" ? "red.50" : "rgb(155, 44, 44,0.2)",
      colorMode === "light" ? "red.100" : "red.700",
      colorMode === "light" ? "red.600" : "red.600",
      colorMode === "light" ? "red.700" : "red.600",
      colorMode === "light" ? "red.700" : "red.200",
    ],
    [ApiRequestPriority.important]: [
      colorMode === "light" ? "orange.50" : "rgb(156, 66, 33,0.2)", //background
      colorMode === "light" ? "orange.100" : "orange.700", //sec background
      colorMode === "light" ? "orange.300" : "orange.300", //border
      colorMode === "light" ? "orange.700" : "orange.500", //font
      colorMode === "light" ? "orange.700" : "orange.200", //font
    ],
    [ApiRequestPriority.standard]: [
      colorMode === "light" ? "blue.50" : "rgb(44, 82, 130,0.3)", //background
      colorMode === "light" ? "blue.100" : "blue.500", //sec background
      colorMode === "light" ? "blue.500" : "blue.500", // border
      colorMode === "light" ? "blue.600" : "blue.400", //font
      colorMode === "light" ? "blue.600" : "blue.100", //sec font
    ],
    [ApiRequestPriority.low]: [
      colorMode === "light" ? "gray.200" : "rgb(74, 85, 104,0.3)",
      colorMode === "light" ? "gray.100" : "gray.600",
      colorMode === "light" ? "gray.500" : "gray.500",
      colorMode === "light" ? "gray.600" : "gray.400",
      colorMode === "light" ? "gray.600" : "gray.200",
    ],
  };
};

export type ScheduleItem = {
  id: string;
  rowId: string;
  start: string;
  end: string;
};

export type ScheduleItems = { [key: string]: ScheduleItem };

export type ScheduleState = { items: ScheduleItems };
