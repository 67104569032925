import {
  Box,
  Button,
  Icon,
  Text,
  useColorModeValue,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axios, { AxiosProgressEvent } from "axios";
import { FormikProps, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { RiArrowUpDownFill } from "react-icons/ri";
import { useParams } from "react-router-dom";
import {
  Attachment,
  Attachments,
} from "../../../../../components/attachments/Attachments";
import { useAuthentication } from "../../../../../components/auth/AuthProvider";
import { TextEditorControl } from "../../../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import {
  SwitchControl,
  SwitchPosition,
} from "../../../../../components/form-helpers/SwitchControl";
import { TextInputControl } from "../../../../../components/form-helpers/TextInputControl";
import {
  ApiTaskbookTask,
  TaskBookFormValues,
} from "../../../../../store/taskbook.slice";
import { NumberCircle } from "../common-components/NumberCircle";
import { newTaskPrefix } from "../TaskBookForm";
import { CollapseTask } from "./CollapseTask";

export interface TaskFormProps {
  task: ApiTaskbookTask;
  provided?: any;
  index: number;
  deleteTaskStep: (value: number) => void;
}

export const TaskForm: React.FC<TaskFormProps> = ({
  task,
  index,
  provided,
  deleteTaskStep,
}) => {
  const toast = useToast();
  const { id } = useParams<{ id?: string }>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = useColorModeValue("black", "white");
  const { apiClient, currentAccount } = useAuthentication();
  const {
    values,
    setValues,
    setFieldTouched,
  }: FormikProps<TaskBookFormValues> = useFormikContext();

  const handleAddAttachment = useCallback(
    (files: Attachment[]) => {
      files.forEach(async (item) => {
        if (!item.file) return;
        try {
          const response = await apiClient.createUpload(currentAccount.id);
          const newAttachment: Attachment = {
            ...item,
            isNew: true,
            isUploading: true,
            progress: 0,
            uploadId: response.id,
          };

          const tasksToUpdate = [...values.tasks];
          tasksToUpdate[index].taskAttachments.push(newAttachment);
          setValues({ ...values, tasks: tasksToUpdate });

          const fileIndex = values.tasks[index].taskAttachments.findIndex(
            (item) => item.uploadId === newAttachment.uploadId
          );
          await axios.put(response.url, newAttachment.file, {
            headers: { "Content-type": newAttachment.file?.type },
            onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
              const tasksToUpdate = [...values.tasks];
              const updatedAttachment = {
                ...newAttachment,
                progress: Math.round(
                  (progressEvent.loaded * 100) /
                    (progressEvent.total ? progressEvent.total : 1)
                ),
              };

              tasksToUpdate[index].taskAttachments[fileIndex] =
                updatedAttachment;
              setValues({ ...values, tasks: tasksToUpdate });
            },
          });

          const newUpdate = [...values.tasks];
          newUpdate[index].taskAttachments[fileIndex] = {
            ...newAttachment,
            progress: undefined,
            isUploading: false,
          };
          setValues({ ...values, tasks: newUpdate });
        } catch (error) {
          toast({
            position: "top",
            duration: 3000,
            isClosable: true,
            status: "error",
            title: "Error uploading a attachment, please try again",
          });
        }
      });
    },
    [apiClient, currentAccount, index, values, setValues, toast]
  );

  const removeAttachmentTaskFormik = useCallback(
    (file: Attachment) => {
      const valuesCopy = values;
      const attachmentIndex = values.tasks[index].taskAttachments.findIndex(
        (attachment) => attachment.uploadId === file.uploadId
      );
      if (attachmentIndex !== -1) {
        valuesCopy.tasks[index].taskAttachments.splice(attachmentIndex, 1);
        setValues({ ...valuesCopy });
      }
    },
    [index, setValues, values]
  );

  const handleDeleteAttachment = useCallback(
    (file: Attachment) => {
      if (file.uploadId) {
        if (task.id.includes(newTaskPrefix)) {
          removeAttachmentTaskFormik(file);
        } else {
          try {
            if (id) {
              apiClient.removeTaskbookTaskAttachment(
                currentAccount.id,
                id,
                task.id,
                file.uploadId
              );
            }
            removeAttachmentTaskFormik(file);
          } catch (error) {
            toast({
              position: "top",
              duration: 3000,
              isClosable: true,
              status: "error",
              title: "Error saving a taskbook",
            });
          }
        }
      }
    },
    [task.id, toast, removeAttachmentTaskFormik, apiClient, currentAccount, id]
  );

  const handleOnDone = useCallback(() => {
    onOpen();
    setFieldTouched(`tasks[${index}].name`, true, true);
    setFieldTouched(`tasks[${index}].description`, true, true);
  }, [onOpen, setFieldTouched, index]);

  return (
    <Box>
      {isOpen ? (
        <CollapseTask
          task={task}
          onClose={onClose}
          provided={provided}
          index={index}
          deleteTaskStep={deleteTaskStep}
        />
      ) : (
        <Box>
          <Box display="inline-flex" w="100%" maxW="100%" my={4}>
            <Box pt={2}>
              <NumberCircle stepNumber={task.order} />
            </Box>
            <Box marginX={4} w="100%">
              <TextInputControl
                name={`tasks[${index}].name`}
                value={task.name || null}
              />
            </Box>
            <Box {...provided.dragHandleProps} pt={2}>
              <Icon as={RiArrowUpDownFill} w={6} h={5} color={textColor} />
            </Box>
          </Box>

          <Box my={2}>
            <SwitchControl
              size="sm"
              isChecked={task.isRequired}
              label="This step is required"
              name={`tasks[${index}].isRequired`}
              switchPosition={SwitchPosition.SIDE}
            />
          </Box>

          <Box my={2}>
            <Text fontSize="sm" fontWeight="700">
              Enter the step instructions
            </Text>
            <TextEditorControl
              name={`tasks[${index}].description`}
              value={task.description || null}
            />
          </Box>

          <Attachments
            attachments={task.taskAttachments}
            onNewAttachments={handleAddAttachment}
            onDeleteAttachment={handleDeleteAttachment}
            hideDraggable={true}
            displayAccordion={true}
            title="Attachments"
          />

          <Button colorScheme="blue" size="sm" mt={4} onClick={handleOnDone}>
            Done
          </Button>
        </Box>
      )}
    </Box>
  );
};
