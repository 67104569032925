import { Box, Heading, Stack, Text } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import {
  Attachment,
  Attachments,
} from "../../../components/attachments/Attachments";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState, useThunkDispatch } from "../../../store";
import { findAttachments } from "../../../store/planning-hq/funding-sources/attachments/findAttachments.thunk";
import { removeAttachment } from "../../../store/planning-hq/funding-sources/attachments/removeAttachments.thunk";
import { uploadAttachment } from "../../../store/planning-hq/funding-sources/attachments/uploadAttachment.thunk";
import {
  addAttachment,
  updateAttachment,
} from "../../../store/planning-hq/funding-sources/funding-source-page.slice";
import FundingSourceAttachmentsSkeleton from "./FundingSourceAttachmentsSkeleton";
type FundingSourceAttachmentsProps = {};

export const FundingSourceAttachments: FC<
  FundingSourceAttachmentsProps
> = () => {
  const { apiClient, currentAccount } = useAuthentication();
  const thunkDispatch = useThunkDispatch();
  const init = useRef(false);

  const { fundingSource, attachments, attachmentsLoadingStatus } = useSelector(
    (state: RootState) => state.fundingSourcePageSlice
  );

  const handleDeleteAttachment = useCallback(
    (attachment: Attachment, fundingSourceId: string) => {
      if (!attachment.uploadId) return;
      thunkDispatch(
        removeAttachment({
          apiClient,
          account: currentAccount,
          fundingSourceId,
          attachmentId: attachment.uploadId,
        })
      );
    },
    [thunkDispatch, apiClient, currentAccount]
  );

  const handleNewAttachments = useCallback(
    (attachments: Attachment[], fundingSourceId: string) => {
      attachments.forEach((attachment) => {
        thunkDispatch(
          uploadAttachment({
            apiClient,
            fundingSourceId,
            attachment: attachment,
            createAttachmentAction: addAttachment,
            updateAttachmentAction: updateAttachment,
          })
        );
      });
    },
    [thunkDispatch, apiClient]
  );

  useEffect(() => {
    if (fundingSource && !init.current) {
      thunkDispatch(
        findAttachments({ apiClient, fundingSourceId: fundingSource.id })
      );
      init.current = true;
    }
  }, [thunkDispatch, apiClient, fundingSource]);

  return (
    <Stack gap={4} flexDirection="column" w="full" align="stretch">
      {attachmentsLoadingStatus === "pending" && (
        <FundingSourceAttachmentsSkeleton />
      )}
      {attachmentsLoadingStatus === "rejected" && (
        <Text>Could not load information</Text>
      )}
      {attachmentsLoadingStatus === "fulfilled" && fundingSource && (
        <>
          <Heading as="h3" size="md">
            Files
          </Heading>
          <Box w="fit-content">
            <Attachments
              display="grid"
              heading={false}
              attachments={attachments}
              onDeleteAttachment={(attachment) =>
                handleDeleteAttachment(attachment, fundingSource.id)
              }
              onNewAttachments={(attachment) =>
                handleNewAttachments(attachment, fundingSource.id)
              }
              displayActions={true}
            />
          </Box>
        </>
      )}
    </Stack>
  );
};
