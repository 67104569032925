import { SimpleGrid, Skeleton } from "@chakra-ui/react";
import React from "react";

const FundingSourceAttachmentsSkeleton = () => {
  return (
    <SimpleGrid columns={5} spacingX="10px" width="fit-content">
      {[...Array(5)].map((_, i) => {
        return <Skeleton w="170px" h="170px" key={`skeleton::${i}`} />;
      })}
    </SimpleGrid>
  );
};

export default React.memo(FundingSourceAttachmentsSkeleton);
