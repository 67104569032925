import { AddIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  ModalFooter,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { FC } from "react";
import { RiFilterLine, RiFilterOffLine } from "react-icons/ri";
import { QuickFilterButton } from "../../../../components/buttons/QuickFilterButton";
import { AccountModal } from "../../../account-settings/account-modal/AccountModal";
import { FundingSourceFilter } from "../filters/FundingSourceFilter";
import { HeaderProps } from "./Header";

type MobileHeaderProps = HeaderProps;

export const MobileHeader: FC<MobileHeaderProps> = ({
  filters,
  updateFilters,
  clearFilters,
  onNewBudgetClick,
}) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <>
      <HStack justify="space-between" wrap="wrap">
        <QuickFilterButton
          activeText="Include Inactive"
          isActive={filters.includeInactive || false}
          onClick={() =>
            updateFilters({
              includeInactive: !filters.includeInactive,
            })
          }
        />
        <HStack wrap="wrap">
          <Button
            leftIcon={<AddIcon />}
            colorScheme="blue"
            variant="solid"
            onClick={onNewBudgetClick}
            size="sm"
          >
            Add budget
          </Button>
          <Button
            leftIcon={<Icon as={RiFilterLine} />}
            size="sm"
            colorScheme="blue"
            onClick={onOpen}
            aria-label="Filters"
          >
            Filters
          </Button>
          <IconButton
            icon={<Icon as={RiFilterOffLine} />}
            aria-label="Clear"
            colorScheme="blue"
            variant="outline"
            size="sm"
            onClick={clearFilters}
          />
        </HStack>
      </HStack>
      {isOpen && (
        <AccountModal
          isOpen={isOpen}
          onClose={onClose}
          title={<Text>Select filters</Text>}
          bodyProps={{ m: 0 }}
          content={
            <VStack align="stretch" w="full" gap={6}>
              <InputGroup flexGrow={1}>
                <InputLeftElement children={<SearchIcon color="gray.300" />} />
                <Input
                  type="text"
                  placeholder="Search"
                  value={filters.search}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    updateFilters({ search: e.target.value });
                  }}
                />
              </InputGroup>
              <HStack>
                <FundingSourceFilter
                  values={
                    filters.fundingSource
                      ? Array.isArray(filters.fundingSource)
                        ? filters.fundingSource
                        : [filters.fundingSource]
                      : []
                  }
                  onChange={(values) => {
                    updateFilters({ fundingSource: values.map((v) => v.id) });
                  }}
                />
              </HStack>
            </VStack>
          }
          footer={
            <ModalFooter>
              <Button colorScheme="blue" onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          }
        />
      )}
    </>
  );
};
