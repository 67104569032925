import { FormControl, FormErrorMessage, FormLabel } from "@chakra-ui/react";
import { useField, useFormikContext } from "formik";
import { FC, useCallback, useState } from "react";
import { CurrencyInput } from "../inputs/CurrencyInput";

type CurrencyInputControlProps = {
  label: string;
  name: string;
  value?: number;
  placeholder?: string;
  onBlur?: () => void;
};

export const CurrencyInputControl: FC<CurrencyInputControlProps> = ({
  value,
  name,
  placeholder,
  label,
  onBlur,
}) => {
  const [field, meta, helper] = useField({ name, value, placeholder });
  const { submitCount } = useFormikContext();

  const [localAmount, setLocalAmount] = useState(value ?? 0);

  const handleAmountChange = useCallback(
    (valueAsString?: string, valueAsNumber?: number) => {
      if (!valueAsNumber || Number.isNaN(valueAsNumber)) {
        setLocalAmount(0);
      } else {
        setLocalAmount(valueAsNumber);
      }

      helper.setTouched(true);
      helper.setValue(valueAsNumber || 0);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <CurrencyInput
        {...field}
        value={localAmount}
        onChange={handleAmountChange}
        onBlur={onBlur}
      />
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
