import {
  Container,
  Heading,
  HStack,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/layout";
import { Alert, AlertIcon, Button, Grid, GridItem } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useShowToast } from "../../../hooks/showToast";
import { RootState } from "../../../store";
import { DeactivateActivate } from "./actions/DeactivateActivate";
import BudgetDetailsSkeleton from "./BudgetDetailsSkeleton";
import { EditBudgetForm } from "./edit-budget/EditBudgetForm";
import { FundingSourceAllocations } from "./edit-budget/FundingSourceAllocations";

export const BudgetDetails = () => {
  const { budget, budgetLoadingStatus, allocations } = useSelector(
    (state: RootState) => state.budgetPageSlice
  );

  const [active, setActive] = useState<boolean>(true);

  const toast = useShowToast();

  const onActivateDeactivate = useCallback(
    (active: boolean) => {
      setActive(active);

      if (active) {
        toast("success", "Budget was successfully reactivated");
      } else {
        toast("success", "Budget was successfully deactivated");
      }
    },
    [toast]
  );

  useEffect(() => {
    if (budgetLoadingStatus === "fulfilled") {
      setActive(Boolean(budget?.active));
    }
  }, [budgetLoadingStatus, budget]);

  return (
    <Container maxWidth="3xl">
      <Grid gridTemplateRows="repeat(3, min-content)" columnGap={10} rowGap={4}>
        <GridItem rowStart={3}>
          {budgetLoadingStatus === "pending" && <BudgetDetailsSkeleton />}
          <Stack flexDir="column" gap={7} w="full" align="stretch">
            {budgetLoadingStatus === "rejected" && (
              <Text>Could not load information</Text>
            )}
            {budgetLoadingStatus === "fulfilled" && budget && (
              <VStack w="full" align="stretch" gap="3">
                <EditBudgetForm budget={budget} isDisabled={active === false} />
                <FundingSourceAllocations
                  budget={budget}
                  allocations={allocations}
                  isDisabled={active === false}
                />
              </VStack>
            )}
          </Stack>
        </GridItem>

        <GridItem rowStart={1}>
          <VStack w="full" align="stretch" gap="3">
            {active === false && (
              <Alert status="info">
                <AlertIcon />
                This budget has been deactivated and can no longer be edited.
              </Alert>
            )}
          </VStack>
        </GridItem>
        {
          <GridItem rowStart={2}>
            {budget && (
              <HStack justifyContent="space-between" wrap="wrap-reverse">
                <Heading as="h3" size="md">
                  About the budget
                </Heading>
                <DeactivateActivate
                  budget={budget}
                  onActivateDeactivate={onActivateDeactivate}
                >
                  {({ onOpen, active }) => (
                    <Button
                      ml="auto"
                      colorScheme={active ? "red" : "blue"}
                      size="sm"
                      onClick={onOpen}
                    >
                      {active ? "Deactivate Budget" : "Reactivate Budget"}
                    </Button>
                  )}
                </DeactivateActivate>
              </HStack>
            )}
          </GridItem>
        }
      </Grid>
    </Container>
  );
};
