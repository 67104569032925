export type SchedulingItemMetadata = {
  id: string;
  start: string;
  end: string;
  rowId: string;
};

export type SchedulingMetadata = {
  items: { [key: string]: SchedulingItemMetadata };
} & { [key: string]: any };

export function isValidSchedulingMetadata(
  metadata: any
): metadata is SchedulingMetadata {
  if ("items" in metadata) return true;
  return false;
}
