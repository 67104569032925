import {
  ApiAccountSettings,
  ApiClient,
  InventoryRequestSummary,
  InventorySettingsKeys,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { SignModes } from "../../components/e-sign/E-Sign";
import { CustomCameraMode } from "../../components/images/camera/CustomCamera";
import {
  inventoryRequestDefaultFilters,
  InventoryRequestFiltersValues,
} from "../../pages/inventory/inventory-request/inventory-request-list/iventoryRequestDefault";

interface LoadInventoryRequestsParams {
  apiClient: ApiClient;
  accountId: string;
  filters?: Partial<InventoryRequestFiltersValues>;
}

export interface InventoryDeliveryProps {
  eSignMode: SignModes;
  isSignCanvaOpen: boolean;
  isDeviceLandScape: boolean;
  cameraMode: CustomCameraMode;
  displayESignSection: boolean;
  workingInventoryRequest: InventoryRequestSummary | null;
}

const DEFAULT_INVENTORY_DELIVERY_PROPS: InventoryDeliveryProps = {
  eSignMode: "off",
  cameraMode: "off",
  isSignCanvaOpen: false,
  isDeviceLandScape: false,
  displayESignSection: false,
  workingInventoryRequest: null,
};

export const loadInventoryRequests = createAsyncThunk(
  "iventory-request/load",
  async (params: LoadInventoryRequestsParams, ThunkAPI) => {
    const { apiClient, accountId } = params;
    const { filters } = (ThunkAPI.getState() as RootState).inventoryRequest;
    const newFilters = params.filters
      ? {
          ...filters,
          ...params.filters,
          catalog: Array.isArray(params.filters.catalog)
            ? params.filters.catalog.map((item) =>
                typeof item === "string" ? item : item.id
              )
            : undefined,
        }
      : filters;
    const response = await apiClient.findInventoryRequests(
      accountId,
      newFilters
    );
    const inventorySettings = await apiClient.getAccountSettings(accountId, [
      InventorySettingsKeys.ALLOW_MANDATORY_E_SIGN_FOR_REQUEST_DELIVERY,
    ]);

    return { response, inventorySettings };
  }
);

interface InventoryRequestListSlice {
  loading: "idle" | "pending" | "succeeded" | "failed";
  data: InventoryRequestSummary[];
  total: number;
  filters: Partial<InventoryRequestFiltersValues>;
  selectedRequests: string[];
  inventorySettings: ApiAccountSettings;

  // to handle delivery stuffs
  deliveryProps: InventoryDeliveryProps;
}

const DEFAULT_INVENTORY_REQUEST: InventoryRequestListSlice = {
  loading: "idle",
  data: [],
  total: 0,
  filters: { ...inventoryRequestDefaultFilters },
  selectedRequests: [],
  inventorySettings: {},
  deliveryProps: { ...DEFAULT_INVENTORY_DELIVERY_PROPS },
};

export const inventoryRequestListSlice = createSlice({
  name: "inventory-request",
  initialState: { ...DEFAULT_INVENTORY_REQUEST },
  reducers: {
    unloadInventoryRequest: (state: InventoryRequestListSlice) => {
      state.loading = "idle";
      state.data = [];
      state.total = 0;
      state.filters = { ...inventoryRequestDefaultFilters };
      state.deliveryProps = { ...DEFAULT_INVENTORY_DELIVERY_PROPS };
    },
    updateInventoryRequestFilters: (
      state: InventoryRequestListSlice,
      action: PayloadAction<Partial<InventoryRequestFiltersValues>>
    ) => {
      state.filters = { ...state.filters, ...action.payload };
      state.selectedRequests = [];
    },
    handleSelectAllRequests: (state: InventoryRequestListSlice) => {
      state.selectedRequests = state.data.map((request) => request.id);
    },
    handleClearSelectedRequest: (state: InventoryRequestListSlice) => {
      state.selectedRequests = [];
    },
    handleOnSelectRequest: (
      state: InventoryRequestListSlice,
      action: PayloadAction<string>
    ) => {
      const index = state.selectedRequests.findIndex(
        (id) => id === action.payload
      );
      const selectedRequestsCopy = [...state.selectedRequests];
      if (index !== -1) {
        selectedRequestsCopy.splice(index, 1);
        state.selectedRequests = selectedRequestsCopy;
        return;
      }
      selectedRequestsCopy.push(action.payload);
      state.selectedRequests = selectedRequestsCopy;
    },
    handleSetInventoryDeliveryProps: (
      state: InventoryRequestListSlice,
      action: PayloadAction<Partial<InventoryDeliveryProps>>
    ) => {
      state.deliveryProps = { ...state.deliveryProps, ...action.payload };
    },
    handleUnloadInventoryDeliveryProps: (state: InventoryRequestListSlice) => {
      state.deliveryProps = { ...DEFAULT_INVENTORY_DELIVERY_PROPS };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadInventoryRequests.fulfilled, (state, action) => {
      state.data = action.payload.response.data;
      state.loading = "succeeded";
      state.total = action.payload.response.total;
      state.inventorySettings = action.payload.inventorySettings;
    });
    builder.addCase(loadInventoryRequests.pending, (state) => {
      state.loading = "pending";
    });
    builder.addCase(loadInventoryRequests.rejected, (state) => {
      state.loading = "failed";
      state.data = [];
    });
  },
});

export const {
  unloadInventoryRequest,
  updateInventoryRequestFilters,
  handleOnSelectRequest,
  handleSelectAllRequests,
  handleClearSelectedRequest,
  handleSetInventoryDeliveryProps,
  handleUnloadInventoryDeliveryProps,
} = inventoryRequestListSlice.actions;

export default inventoryRequestListSlice.reducer;
