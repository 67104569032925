import { WarningTwoIcon } from "@chakra-ui/icons";
import { Button, HStack, ModalFooter, Text, VStack } from "@chakra-ui/react";
import { ApiBudget } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { AccountModal } from "../../../account-settings/account-modal/AccountModal";
export type DeactivateActivateModalProps = {
  budget: ApiBudget;
  active: boolean;
  isOpen: boolean;
  onClose: () => void;
  onActivateDeactivate: () => void;
};

export const DeactivateActivateModal: FC<DeactivateActivateModalProps> = ({
  isOpen,
  onClose,
  onActivateDeactivate,
  budget,
  active,
}) => {
  return !isOpen ? null : (
    <AccountModal
      header={false}
      closeButton={false}
      isOpen={isOpen}
      onClose={onClose}
      isCentered={true}
      bodyProps={{
        mb: "unset",
        py: "6",
      }}
      content={
        <HStack gap="2">
          <WarningTwoIcon w="8" h="8" color="orange" alignSelf="baseline" />
          <VStack gap="4">
            <VStack alignItems="left">
              {active ? (
                <>
                  <Text fontSize="lg" fontWeight="bold">
                    Deactivate Budget?
                  </Text>
                  <Text>
                    The budget
                    <Text as="span" fontStyle="italic">
                      {` ${budget.name} `}
                    </Text>
                    will only be disabled and data will not be deleted. You can
                    reactivate it at anytime.
                  </Text>
                </>
              ) : (
                <>
                  <Text fontSize="lg" fontWeight="bold">
                    Activate Budget?
                  </Text>
                  <Text>
                    The budget
                    <Text as="span" fontStyle="italic">
                      {` ${budget.name} `}
                    </Text>
                    will be enabled.
                  </Text>
                </>
              )}
            </VStack>
          </VStack>
        </HStack>
      }
      footer={
        <ModalFooter gap={2}>
          <Button variant="outline" size="sm" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme={active ? "red" : "blue"}
            variant="outline"
            size="sm"
            onClick={onActivateDeactivate}
          >
            {active ? "Deactivate" : "Activate"}
          </Button>
        </ModalFooter>
      }
    />
  );
};
