import { FormControl, FormLabel, HStack, VStack } from "@chakra-ui/react";
import {
  ApiProject,
  ApiProjectStatus,
  ApiUserSummary,
  FindUsersOptions,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MultiValue } from "react-select";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { TextEditorAutoSave } from "../../../components/form-helpers/rich-text-editor/RichTextEditorAutoSave";
import { AutoSavingInput } from "../../../components/inputs/AutoSavingInput";
import { StyledDatePicker } from "../../../components/inputs/StyledDatePicker";
import { FiscalYearSelect } from "../../../components/selects/FiscalYearSelect";
import { MultiUserAutocomplete } from "../../../components/selects/MultiUserAutocomplete";
import { ProjectStatusSelect } from "../../../components/selects/ProjectStatusSelect";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import {
  setUpdateStatus,
  setWorkingProject,
  unload,
  updateProject,
} from "../../../store/planning-hq/project-form";
export type EditProjectFormProps = {
  project: ApiProject;
  isDisabled?: boolean;
};

export type EditProjectValues = ApiProject;

const multiUserFilterOptions: FindUsersOptions = {
  excludeRole: "Portal User",
};

export const EditProjectForm: FC<EditProjectFormProps> = ({
  project,
  isDisabled,
}) => {
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();

  const toast = useShowToast();

  const { apiClient } = useAuthentication();
  const { workingProject } = useSelector(
    (state: RootState) => state.projectFormSlice
  );

  const requiredFields: (keyof Partial<ApiProject>)[] = useMemo(() => {
    const initRequiredFields: (keyof Partial<ApiProject>)[] = [
      "name",
      "status",
    ];
    if (workingProject?.status !== ApiProjectStatus.pending) {
      initRequiredFields.push("start");
      initRequiredFields.push("end");
    }
    return initRequiredFields;
  }, [workingProject]);

  const requiredFieldKeys = useMemo(() => {
    return requiredFields as string[];
  }, [requiredFields]);

  const handlePropertyUpdate = useCallback(
    (delta: Partial<ApiProject>) => {
      const fieldsToUpdate = Object.keys(delta);
      if (fieldsToUpdate.length === 0) return;

      const singleField = fieldsToUpdate[0] as keyof ApiProject;
      const singleFieldValue = delta[singleField];

      const isValueNull = Array.isArray(singleFieldValue)
        ? singleFieldValue.length === 0
        : !!singleFieldValue === false;

      if (requiredFieldKeys.includes(singleField) && isValueNull) {
        toast("error", `Field ${singleField.toUpperCase()} is required`);
        const oldValue = project[singleField];
        dispatch(
          setWorkingProject({
            ...project,
            [singleField]: oldValue,
          })
        );

        return;
      }

      if (workingProject === null) return;
      thunkDispatch(
        updateProject({
          delta,
          apiClient,
          id: project.id,
        })
      )
        .then(() => {
          toast("success", "Project updated successfully");
        })
        .finally(() => {
          dispatch(setUpdateStatus("idle"));
        });
    },
    [
      apiClient,
      workingProject,
      thunkDispatch,
      toast,
      dispatch,
      project,
      requiredFieldKeys,
    ]
  );

  const updateName = useCallback(
    async (value: string) => {
      handlePropertyUpdate({ name: value });
    },
    [handlePropertyUpdate]
  );

  const updateAssignees = useCallback(
    (value: MultiValue<ApiUserSummary> | null) => {
      if (value === null) return;
      const assignees = value.map((v) => v);
      handlePropertyUpdate({ supervisors: assignees });
    },
    [handlePropertyUpdate]
  );

  const updateDescription = useCallback(
    (value: string) => {
      if (value === project.description) return;
      handlePropertyUpdate({ description: value });
    },
    [handlePropertyUpdate, project.description]
  );

  const updateStatus = useCallback(
    (value: ApiProjectStatus) => {
      handlePropertyUpdate({ status: value });
    },
    [handlePropertyUpdate]
  );

  const updateDate = useCallback(
    (value: Date | null, type: "end" | "start") => {
      if (type === "start")
        handlePropertyUpdate({ start: value ? value.toISOString() : null });
      if (type === "end")
        handlePropertyUpdate({ end: value ? value.toISOString() : null });
    },
    [handlePropertyUpdate]
  );

  useEffect(() => {
    if (workingProject === null) {
      dispatch(setWorkingProject(project));
    }
  }, [project, dispatch, workingProject]);

  useEffect(() => {
    return () => {
      dispatch(unload());
    };
  }, [dispatch]);

  return workingProject && project ? (
    <VStack gap={4}>
      <FormControl isDisabled={isDisabled}>
        <FormLabel>Project Name *</FormLabel>
        <AutoSavingInput
          value={workingProject.name}
          onSave={(value) => updateName(value)}
          isRequired={true}
          isDisabled={isDisabled}
        />
      </FormControl>
      <FormControl isDisabled={isDisabled}>
        <FormLabel>Status</FormLabel>
        <ProjectStatusSelect
          status={workingProject.status}
          onChange={(value) => updateStatus(value)}
        />
      </FormControl>
      <FormControl isDisabled={isDisabled}>
        <FormLabel>Assignee</FormLabel>
        <MultiUserAutocomplete
          name="assignee"
          value={workingProject.supervisors}
          onChange={(value) => updateAssignees(value)}
          filterOptions={multiUserFilterOptions}
          isDisabled={isDisabled}
        />
      </FormControl>
      <FormControl isDisabled={isDisabled}>
        <FormLabel> Description</FormLabel>
        <TextEditorAutoSave
          id="working-project::description"
          value={workingProject.description || ""}
          initialValue={workingProject.description}
          isRequired={true}
          onBlur={(value) => {
            updateDescription(value || "");
          }}
          isDisabled={isDisabled}
        />
      </FormControl>
      <FormControl isDisabled={isDisabled}>
        <FormLabel>Fiscal Year</FormLabel>
        <FiscalYearSelect
          fiscalYear={{
            start: workingProject.fiscalYearStart,
            end: workingProject.fiscalYearEnd,
          }}
          onChange={(value) => {
            handlePropertyUpdate({
              fiscalYearStart: value.start ?? null,
              fiscalYearEnd: value.end ?? null,
            });
          }}
          allowNull={true}
        />
      </FormControl>
      <HStack w="full">
        <FormControl isDisabled={isDisabled}>
          <FormLabel>Start Date</FormLabel>
          <StyledDatePicker
            name="startDate"
            value={workingProject.start}
            onChange={(value) => updateDate(value, "start")}
            isDisabled={isDisabled}
            isClearable={true}
          />
        </FormControl>
        <FormControl isDisabled={isDisabled}>
          <FormLabel>End Date</FormLabel>
          <StyledDatePicker
            name="endDate"
            value={workingProject.end}
            onChange={(value) => updateDate(value, "end")}
            isDisabled={isDisabled}
            isClearable={true}
          />
        </FormControl>
      </HStack>
    </VStack>
  ) : null;
};
