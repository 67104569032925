import {
  ApiAccount,
  ApiClient,
  ApiSafetyNote,
  ApiTask,
  ApiTaskBook,
  ApiTaskbookDetail,
  ApiTaskBookType,
  CreateApiTaskBook,
  UpdateApiTaskBook,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { Attachment } from "../components/attachments/Attachments";
import { newTaskPrefix } from "../pages/account-settings/taskbook/taskbook-form/TaskBookForm";

export interface ApiTaskbookTask extends ApiTask {
  taskAttachments: Attachment[];
}

export interface TaskBookFormValues {
  taskbook: (CreateApiTaskBook | UpdateApiTaskBook) & { active: boolean };
  safetyNotes: ApiSafetyNote[];
  tasks: ApiTaskbookTask[];
  safetyNotesToDelete?: ApiSafetyNote[];
}

export interface TaskbookSliceState {
  taskbooks: ApiTaskBook[];
  taskbook: ApiTaskbookDetail | null;
  taskbooksCurrentPage: number;
  taskbooksTotalPages: number;
  taskbooksPageSize: number;
  filters: {
    type?: ApiTaskBookType;
    search?: string;
    includeInactive: string | undefined;
  };
}
export interface FindTaskbookParams {
  apiClient: ApiClient;
  currentAccount: ApiAccount;
  taskbookId: string;
}
export interface FindTaskbooksTypeParams {
  apiClient: ApiClient;
  currentAccount: ApiAccount;
  category?: string | string[];
}
export interface FindTaskAttachmentsParams {
  apiClient: ApiClient;
  currentAccount: ApiAccount;
  taskbookId: string;
  taskId: string;
}

export const findTaskbooks = createAsyncThunk(
  "taskbooks/findTaskBooks",
  async (params: FindTaskbooksTypeParams, thunkAPI) => {
    const { apiClient, currentAccount, category } = params;
    const state = thunkAPI.getState() as RootState;

    const taskbooks = await apiClient.findTaskbooks(currentAccount.id, {
      page: state.taskbook.taskbooksCurrentPage,
      pageSize: state.taskbook.taskbooksPageSize,
      search: state.taskbook.filters.search,
      type: state.taskbook.filters.type,
      includeInactive: state.taskbook.filters.includeInactive,
      category,
    });
    return {
      taskbooks,
    };
  }
);

export const findTaskbook = createAsyncThunk(
  "taskbooks/findTaskBook",
  async (params: FindTaskbookParams, thunkAPI) => {
    const { apiClient, currentAccount, taskbookId } = params;
    const taskbook = await apiClient.findTaskbook(
      currentAccount.id,
      taskbookId
    );
    return {
      taskbook,
    };
  }
);
export interface SaveTaskBookThunkParams {
  apiClient: ApiClient;
  accountId: string;
  taskBookId?: string;
  taskbookFormValues: TaskBookFormValues;
  saveTaskbookData?: boolean;
}

const createTaskAttachments = (
  apiClient: ApiClient,
  accountId: string,
  taskbookId: string,
  taskId: string,
  attachments: Attachment[]
) => {
  const filteredAttachments = attachments.filter(
    (attachment) => attachment.isNew === true
  );

  Promise.all(
    filteredAttachments.map((attachment) => {
      return attachment.uploadId
        ? apiClient.createTaskbookTaskAttachment(
            accountId,
            taskbookId,
            taskId,
            { name: attachment.name, uploadId: attachment.uploadId }
          )
        : null;
    })
  );
};

const createTaskbook = async (
  thunkApi: any,
  apiClient: ApiClient,
  accountId: string,
  taskbookFormValues: TaskBookFormValues
) => {
  const { taskbook, safetyNotes, tasks } = taskbookFormValues;

  const createdTaskbook = await apiClient.createTaskbook(
    accountId,
    taskbook as CreateApiTaskBook
  );
  thunkApi.dispatch(addTaskBook);

  if (safetyNotes.length > 0) {
    await Promise.all(
      safetyNotes.map((safetyNote) =>
        apiClient.createSafetyNote(accountId, createdTaskbook.id, safetyNote)
      )
    );
  }

  if (tasks.length > 0) {
    await Promise.all(
      tasks.map((task) => {
        return apiClient
          .createTask(accountId, createdTaskbook.id, task)
          .then((createdTask) => {
            const { taskAttachments } = task;
            createTaskAttachments(
              apiClient,
              accountId,
              createdTaskbook.id,
              createdTask.id,
              taskAttachments
            );
          });
      })
    );
  }
  return createdTaskbook.id;
};

const updateTaskbook = async (
  thunkApi: any,
  apiClient: ApiClient,
  accountId: string,
  taskbookFormValues: TaskBookFormValues,
  taskbookId: string,
  saveTaskbookData: boolean | undefined
) => {
  const { taskbook, safetyNotes, tasks, safetyNotesToDelete } =
    taskbookFormValues;
  const currentTasks = await apiClient.findTasks(accountId, taskbookId);
  saveTaskbookData &&
    (await apiClient.updateTaskbook(accountId, taskbookId, taskbook));
  thunkApi.dispatch(updateTaskBook);

  if (safetyNotes.length > 0) {
    const safetyNotesToUpdate = safetyNotes.filter(
      (sn) => sn.id !== undefined && !sn.id.includes("new")
    );
    const safetyNotesToCreate = safetyNotes.filter(
      (sn) => sn.id === undefined || sn.id.includes("new")
    );

    safetyNotesToUpdate.length > 0 &&
      Promise.all(
        safetyNotesToUpdate.map((updateItem) =>
          apiClient.updateSafetyNote(accountId, taskbookId, updateItem.id, {
            type: updateItem.type,
            description: updateItem.description,
          })
        )
      );

    safetyNotesToCreate.length > 0 &&
      Promise.all(
        safetyNotesToCreate.map((createItem) =>
          apiClient.createSafetyNote(accountId, taskbookId, createItem)
        )
      );
  }

  safetyNotesToDelete &&
    safetyNotesToDelete.length > 0 &&
    Promise.all(
      safetyNotesToDelete.map((sn) =>
        apiClient.removeSafetyNote(accountId, taskbookId, sn.id)
      )
    );

  if (tasks.length === 0) {
    if (currentTasks.data.length > 0) {
      await Promise.all(
        currentTasks.data.map((taskToDelete) =>
          apiClient.removeTask(accountId, taskbookId, taskToDelete.id)
        )
      );
    }
    return taskbookId;
  }
  const tasksToCreate: ApiTaskbookTask[] = [];
  const tasksToUpdate: ApiTaskbookTask[] = [];
  tasks.forEach((item) => {
    if (item.id.includes(newTaskPrefix)) {
      tasksToCreate.push(item);
    } else {
      tasksToUpdate.push(item);
    }
  });

  if (tasksToCreate.length > 0) {
    await Promise.all(
      tasksToCreate.map((taskToCreate, index) =>
        apiClient
          .createTask(accountId, taskbookId, taskToCreate)
          .then((createdTask) => {
            const { taskAttachments } = taskToCreate;
            createTaskAttachments(
              apiClient,
              accountId,
              taskbookId,
              createdTask.id,
              taskAttachments
            );
          })
      )
    );
  }

  if (tasksToUpdate.length > 0) {
    await Promise.all(
      tasksToUpdate.map((taskToUpdate) =>
        apiClient
          .updateTask(accountId, taskbookId, taskToUpdate.id, taskToUpdate)
          .then((createdTask) => {
            const { taskAttachments } = taskToUpdate;
            createTaskAttachments(
              apiClient,
              accountId,
              taskbookId,
              createdTask.id,
              taskAttachments
            );
          })
      )
    );
    const currentTasksIds = currentTasks.data.map((ct) => ct.id);
    const currentTasksToUpdateIds = tasksToUpdate.map((tu) => tu.id);
    const difference = currentTasksIds.filter(
      (x) => !currentTasksToUpdateIds.includes(x)
    );
    await Promise.all(
      difference.map((taskToDelete) =>
        apiClient.removeTask(accountId, taskbookId, taskToDelete)
      )
    );
  }

  return taskbookId;
};

export const saveTaskBook = createAsyncThunk(
  "taskbooks/save",
  async (params: SaveTaskBookThunkParams, thunkApi) => {
    const {
      apiClient,
      accountId,
      taskbookFormValues,
      taskBookId,
      saveTaskbookData,
    } = params;
    return taskBookId
      ? updateTaskbook(
          thunkApi,
          apiClient,
          accountId,
          taskbookFormValues,
          taskBookId,
          saveTaskbookData
        )
      : createTaskbook(thunkApi, apiClient, accountId, taskbookFormValues);
  }
);

export const taskBookSlice = createSlice({
  name: "taskbook",
  initialState: {
    taskbooks: [],
    taskbook: null,
    taskAttachments: [],
    taskbooksCurrentPage: 1,
    taskbooksTotalPages: 1,
    taskbooksPageSize: 20,
    filters: {
      type: undefined,
      search: "",
      includeInactive: "false",
    },
  } as TaskbookSliceState,
  reducers: {
    setTaskbooksCurrentPage: (state, payload: PayloadAction<number>) => {
      state.taskbooksCurrentPage = payload.payload;
    },
    setTaskbookTypeFilter: (
      state,
      payload: PayloadAction<ApiTaskBookType | undefined>
    ) => {
      state.taskbooksCurrentPage = 1;
      state.filters.type = payload.payload;
    },
    setTaskbookInactiveOption: (
      state,
      payload: PayloadAction<string | undefined>
    ) => {
      state.taskbooksCurrentPage = 1;
      state.filters.includeInactive = payload.payload;
    },
    setTaskbookSearchFilter: (
      state,
      payload: PayloadAction<string | undefined>
    ) => {
      state.taskbooksCurrentPage = 1;
      state.filters.search = payload.payload;
    },
    unloadTaskbooks: (state) => {
      state.taskbooks = [];
      state.taskbook = null;
      state.taskbooksCurrentPage = 1;
      state.taskbooksTotalPages = 1;
      state.taskbooksPageSize = 20;
      state.filters.type = undefined;
      state.filters.search = "";
    },
    addTaskBook: (state, action: PayloadAction<ApiTaskBook>) => {
      state.taskbooks.unshift(action.payload);
    },
    updateTaskBook: (state, action: PayloadAction<ApiTaskBook>) => {
      const taskBookIndex = state.taskbooks.findIndex(
        (taskbook) => taskbook.id === action.payload.id
      );
      if (taskBookIndex !== -1) {
        state.taskbooks[taskBookIndex] = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(findTaskbooks.fulfilled, (state, action) => {
      state.taskbooks = action.payload.taskbooks.data;
      state.taskbooksTotalPages = action.payload.taskbooks.total;
      state.taskbooksCurrentPage =
        action.payload.taskbooks.options.page || state.taskbooksCurrentPage;
    });
    builder.addCase(findTaskbook.fulfilled, (state, action) => {
      state.taskbook = action.payload.taskbook;
    });
  },
});

export const {
  setTaskbooksCurrentPage,
  setTaskbookTypeFilter,
  setTaskbookSearchFilter,
  unloadTaskbooks,
  addTaskBook,
  updateTaskBook,
  setTaskbookInactiveOption,
} = taskBookSlice.actions;

export default taskBookSlice.reducer;
