import {
  Box,
  Container,
  Divider,
  Flex,
  Heading,
  Icon,
  Link,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import { MdArrowBack } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { RootState, useThunkDispatch } from "../../../store";
import {
  getBudget,
  unload,
} from "../../../store/planning-hq/budgets/budget-page.slice";
import { BudgetDetails } from "./BudgetDetails";

export type BudgetPageRouteParams = {
  section: string;
  id: string;
};

type BudgetTab = { label: string; index: number; value: string };
const tabs: BudgetTab[] = [
  { label: "Budget details", index: 0, value: "details" },
];

export const BudgetPage: FC = () => {
  const [tabIndex, setTabIndex] = useState<number>(-1);
  const { budget } = useSelector((state: RootState) => state.budgetPageSlice);

  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { apiClient } = useAuthentication();
  const { section, id } = useParams<BudgetPageRouteParams>();

  const handleTabsChange = useCallback(
    (index: number) => {
      const tab = tabs.find((tab) => tab.index === index);
      if (!tab) return;
      setTabIndex(index);
      navigate(`/planning/budgets/${id}/${tab.value.toLowerCase()}`);
    },
    [navigate, id]
  );

  useEffect(() => {
    if (tabIndex !== -1) return;
    const findTab = tabs.find((t) => t.value === section);
    if (!findTab) {
      navigate(`/planning/budgets/${id}/details`);
      return;
    }
    setTabIndex(findTab.index);
  }, [section, navigate, tabIndex, id]);

  useEffect(() => {
    if (budget != null || !id) return;

    thunkDispatch(
      getBudget({
        apiClient,
        id: id,
      })
    );
  }, [budget, apiClient, id, thunkDispatch]);

  useEffect(() => {
    return () => {
      dispatch(unload());
    };
  }, [dispatch, thunkDispatch]);

  return !budget ? null : (
    <Container
      gap={2}
      maxWidth="8xl"
      sx={{
        display: "flex",
        flexDir: "column",
        flex: "1",
        height: "100%",
      }}
      position="relative"
    >
      <Box display={["flex", "flex", "flex", "none"]} gap={2} flexDir="column">
        <Link
          as={RouterLink}
          gap={2}
          display="flex"
          color="blue.500"
          alignItems="center"
          to="/planning/budgets"
          fontSize="small"
        >
          <Icon as={MdArrowBack} /> Back to Budget List
        </Link>
        <Heading mr={4} fontSize="3xl">
          {budget.name}
        </Heading>
        <Text fontSize="sm" color="gray">
          {budget.id}
        </Text>
      </Box>
      <Tabs variant="unstyled" index={tabIndex} onChange={handleTabsChange}>
        <Flex alignItems="end" gap="10" flexWrap="wrap">
          <Box
            display={["none", "none", "none", "flex"]}
            gap={2}
            flexDir="column"
          >
            <Link
              as={RouterLink}
              gap={2}
              display="flex"
              color="blue.500"
              alignItems="center"
              to="/planning/budgets"
              fontSize="small"
            >
              <Icon as={MdArrowBack} /> Back to Budget List
            </Link>
            <Heading mr={4} fontSize="3xl" isTruncated maxW={400}>
              {budget.name}
            </Heading>
            <Text fontSize="sm" color="gray">
              {budget.id}
            </Text>
          </Box>

          <Box position="relative">
            <TabList>
              {tabs.map((t) => {
                return (
                  <Tab key={t.label} _focus={{ boxShadow: "none" }}>
                    {t.label}
                  </Tab>
                );
              })}
            </TabList>
            <TabIndicator
              mt="-1.5px"
              height="2px"
              bg="blue.500"
              borderRadius="1px"
            />
          </Box>
        </Flex>

        <Divider my={2} />
        <TabPanels
          sx={{
            display: "flex",
            flexDir: "column",
            flex: "1",
            height: "100%",
            overflowY: "hidden",
          }}
        >
          <TabPanel p={0}>
            <Container maxWidth={["8xl"]} px={0}>
              <BudgetDetails />
            </Container>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Container>
  );
};
