import {
  NumberInput,
  NumberInputField,
  NumberInputProps,
} from "@chakra-ui/react";
import { FC, useCallback, useRef, useState } from "react";
import { formatCurrency } from "../../../utils/formatCurrency";

type AutoSavingCurrencyInputProps = Omit<
  NumberInputProps,
  "onBlur" | "value"
> & {
  value?: number;

  onBlur: (
    e: React.FocusEvent<HTMLInputElement>,
    valueAsString?: string,
    valueAsNumber?: number
  ) => Promise<void>;
  isRequired?: boolean;
  isDisabled?: boolean;

  inputProps?: NumberInputProps;
};

export const AutoSavingCurrencyInput: FC<AutoSavingCurrencyInputProps> = ({
  value,
  onBlur,
  isRequired = false,
  isDisabled = false,
  inputProps,
}) => {
  const saving = useRef<boolean>(false);
  const valueFormatted = useRef<boolean>(false);

  const [localValue, setLocalValue] = useState<{
    valueAsString?: string;
    valueAsNumber?: number;
  }>({
    valueAsNumber: value,
    valueAsString: `$ ${formatCurrency(value).substring(1)}`,
  });

  const handleOnChange = useCallback(
    (valueAsString: string, valueAsNumber: number) => {
      if (saving.current) return;

      if (valueFormatted.current) {
        valueFormatted.current = false;
        return;
      }

      setLocalValue({
        valueAsNumber,
        valueAsString: `$ ${valueAsString}`,
      });
    },
    [saving]
  );

  const handleOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      saving.current = true;
      if (isRequired && !localValue.valueAsNumber) {
        setLocalValue({
          valueAsNumber: value,
          valueAsString: `$ ${formatCurrency(value).substring(1)}`,
        });
      } else {
        setLocalValue({
          ...localValue,
          valueAsString: `$ ${formatCurrency(localValue.valueAsNumber).substring(1)}`,
        });
      }

      valueFormatted.current = true;

      onBlur(e, localValue.valueAsString, localValue.valueAsNumber).finally(
        () => {
          saving.current = false;
        }
      );
    },
    [localValue, onBlur, isRequired, value]
  );

  return (
    <NumberInput
      w="100%"
      min={0}
      onChange={handleOnChange}
      alignItems="left"
      inputMode="numeric"
      value={localValue.valueAsString}
      onBlur={(e) => {
        handleOnBlur(e);
      }}
      isDisabled={isDisabled}
      onFocus={() => {
        setLocalValue({
          ...localValue,
          valueAsString: `$ ${localValue.valueAsNumber}`,
        });
      }}
      pattern=".*"
      {...inputProps}
    >
      <NumberInputField textAlign="left" pr="3" />
    </NumberInput>
  );
};
