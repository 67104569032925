import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Divider,
  Flex,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiRequest,
  ApiRequestAssignee,
} from "@operations-hero/lib-api-client";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { DateBadge } from "../../../components/badges/DateBadge";
import { LocationTwoLine } from "../../../components/badges/LocationTwoLine";
import { StatusBadge } from "../../../components/badges/StatusBadge";
import { UserBadge } from "../../../components/badges/UserBadge";
import { LocationColorLabel } from "../../../components/color-picker/LocationColorLabel";
import { getCalendarDates } from "../../../store/calendar.slice";
import { RequestSchedulingSummary } from "../../requests/request-row/RequestSchedulingSummary";
import { SummarySection } from "../../requests/request-row/SummarySection";

interface RequestCalendarViewProps {
  request: ApiRequest;
  onCloseModal: () => void;
}

export const RequestInfo: FC<RequestCalendarViewProps> = ({
  request,
  onCloseModal,
}) => {
  const { currentAccount } = useAuthentication();
  const textColor = useColorModeValue("gray.200", "gray.600");
  const typeColor = useColorModeValue("blue.500", "whiteAlpha");
  const linkTextColor = useColorModeValue("blue.600", "blue.200");

  const { start, end } = getCalendarDates(request);

  return (
    <Flex flexWrap="wrap" gap={4} minW="320px">
      <Box
        w="100%"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <StatusBadge
          status={request.status}
          badgeProps={{
            maxH: "20px",
            minWidth: "80px",
            bgColor: textColor,
            textAlign: "center",
          }}
        />
        <RequestAssignees assignees={request.assignees} />
      </Box>

      <Box w="100%" display="flex" justifyContent="space-between">
        {request.reportingCategory ? (
          <Text fontSize="14px" fontWeight="semibold" isTruncated>
            {request.reportingCategory.name}
          </Text>
        ) : (
          <Text fontStyle={"italic"} color="gray.500">
            No category
          </Text>
        )}

        <Text
          color={typeColor}
          fontSize="14px"
          fontWeight="semibold"
          textAlign={"right"}
        >
          {request.type.toUpperCase()}
        </Text>
      </Box>

      <Divider />

      <Box w="100%">
        <LocationTwoLine value={request.location} />
        {request.location && (
          <LocationColorLabel location={request.location} hideChangeColor />
        )}
      </Box>

      <Box w="100%">
        <SummarySection request={request} />
      </Box>

      <Divider />

      <Box
        w="100%"
        gap={2}
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        flexDir={["column", "column", "row"]}
      >
        <Text as="span" display="inline-flex" fontWeight="bold" w="100%">
          Start:
          <DateBadge
            date={start}
            showRelative={false}
            rest={{ ml: 2, fontWeight: "normal" }}
          />
        </Text>
        <Text as="span" display="inline-flex" fontWeight="bold" w="50%">
          Due:
          <DateBadge
            date={end}
            showRelative={false}
            rest={{ ml: 2, fontWeight: "normal" }}
          />
        </Text>
        <RequestSchedulingSummary request={request} />
      </Box>

      <Box w="100%" display="flex" justifyContent="space-between">
        <Link
          to={`/requests/${request.key}?accountId=${currentAccount.id}`}
          target="_blank"
        >
          <Text
            fontSize="sm"
            textColor={linkTextColor}
            fontWeight="semibold"
            _hover={{ bgColor: "transparent", textDecoration: "underline" }}
            p={2}
          >
            View Request
          </Text>
        </Link>
        <Button
          size="sm"
          variant="solid"
          colorScheme="blue"
          onClick={onCloseModal}
        >
          Close
        </Button>
      </Box>
    </Flex>
  );
};

interface RequestAssigneesProps {
  assignees: ApiRequestAssignee[];
}

export const RequestAssignees: FC<RequestAssigneesProps> = ({ assignees }) => {
  return (
    <Box>
      {assignees.length === 0 && (
        <Text fontStyle="italic" color="gray.500" isTruncated>
          Unassigned
        </Text>
      )}

      {assignees.length === 1 && assignees[0].type === "user" && (
        <UserBadge value={assignees[0].assignee} />
      )}

      {assignees.length > 1 && (
        <AvatarGroup size="xs" max={4} justifyContent="right">
          {assignees.map((assignee) =>
            assignee.type === "group" ? (
              <Avatar
                key={assignee.assignee.id}
                name={assignee.assignee.name}
              />
            ) : (
              <Avatar
                key={assignee.assignee.id}
                src={assignee.assignee.profileImage}
                name={`${assignee.assignee.firstName} ${assignee.assignee.lastName}`}
              />
            )
          )}
        </AvatarGroup>
      )}
    </Box>
  );
};
