import { Badge, BadgeProps, useColorMode } from "@chakra-ui/react";
import { FC, PropsWithChildren, useMemo } from "react";
import { hexToHSL } from "../color-picker/color-code-utils";

type ColoredBadgeProps = BadgeProps & {
  hexColor: string;
  bgLightness?: number;
  colorDarkness?: number;
};

export const ColoredBadge: FC<PropsWithChildren<ColoredBadgeProps>> = ({
  hexColor,
  bgLightness = 0,
  colorDarkness = 0,
  children,
  ...props
}) => {
  const { colorMode } = useColorMode();

  const colorModeStyles: BadgeProps = useMemo(() => {
    if (colorMode === "light") {
      return {
        color: hexToHSL(hexColor, undefined, colorDarkness, true),
        backgroundColor: hexToHSL(hexColor, bgLightness, undefined, true),
      };
    }

    return {
      borderStyle: "solid",
      borderWidth: "thin",
      backgroundColor: hexToHSL(hexColor, undefined, colorDarkness + 15, true),
      borderColor: hexToHSL(hexColor, undefined, 0, true),
    };
  }, [colorMode, colorDarkness, bgLightness, hexColor]);
  return (
    <Badge {...colorModeStyles} {...props}>
      {children}
    </Badge>
  );
};
