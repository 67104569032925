import {
  ApiAsset,
  ApiLocation,
  ApiRequestPriority,
  ApiRequestStatus,
  ApiRequestType,
  ApiTaskBook,
  ApiWorkflow,
  ApiWorkflowReference,
  CreateApiRequest,
} from "@operations-hero/lib-api-client";
import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useDateTimeFormatter } from "../../../hooks/useIntlFormatter";
import { RootState } from "../../../store";
import {
  setAssets,
  setAutoCreateRequestData,
  setNewRequestDialogIsOpen,
  setTaskbook,
} from "../../../store/new-request-form.slice";
import { setQRLookupModalIsOpen } from "../../../store/qr-lookup.slice";
import { useLocationUtils } from "../../../utils/locationUtils";
import { useAuthentication } from "../../auth/AuthProvider";
import { DEFAULT_LOCALE_DATE_OPTS } from "../../dates/LocaleDate";

export type NewRequestFromURLQueryParams = {
  auto: boolean;
  accountId: string;
  newRequest: boolean;
  workflow: ApiWorkflow | null;
  taskbook: ApiTaskBook | null;
  location: ApiLocation | null;
  assets: ApiAsset[] | null;
};

export type NewRequestFromURLQueryError = {
  field: keyof NewRequestFromURLQueryParams;
  message: string;
};

/* This hook is to get query params from URL */
export const useNewRequestFromTaskbookParams = () => {
  const location = useLocation();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const parsedSearchParams = useMemo(() => {
    const accountId = searchParams.get("accountId");
    const workflowId = searchParams.get("workflow");
    const locationId = searchParams.get("locationId");
    const assetsIds = searchParams.getAll("assets[]");
    const auto = searchParams.get("auto") === "true";
    const newRequest = searchParams.get("newRequest") === "true";
    const taskbookId = searchParams.getAll("taskbooks[]")[0] || null;
    const result = {
      auto,
      newRequest,
      accountId,
      workflowId,
      locationId,
      taskbookId,
      assetsIds,
    };

    return { ...result };
  }, [searchParams]);

  return { parsedSearchParams };
};

/* This hook is to get necessary data from query params  */
export const useGetNewRequestParamsFromTaskbook = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { parsedSearchParams } = useNewRequestFromTaskbookParams();

  const navigate = useNavigate();
  const reactLocation = useLocation();
  const dispatch = useDispatch();
  const { locationHash } = useLocationUtils();
  const { formatDateTime } = useDateTimeFormatter();
  const { apiClient, currentAccount, currentUser } = useAuthentication();
  const { workflowMap } = useSelector((state: RootState) => state.localCache);

  const getNewRequestSummary = useCallback(
    (taskbook: ApiTaskBook | null) => {
      if (!taskbook) return "";

      const currentData = new Date();
      const formatedDate = formatDateTime(currentData, {
        ...DEFAULT_LOCALE_DATE_OPTS,
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      });

      return `By taskbook QR scan for: ${taskbook.name} - ${formatedDate}`;
    },
    [formatDateTime]
  );

  const getRequestToCreate = useCallback(
    (
      workflow: ApiWorkflow | null,
      location: ApiLocation | null,
      taskbook: ApiTaskBook | null
    ) => {
      if (!parsedSearchParams.newRequest) {
        return undefined;
      }

      const newRequestSummary = getNewRequestSummary(taskbook);
      const result: CreateApiRequestFromTaskbookQR = {
        metadata: {},
        reason: null,
        assignees: [],
        projectId: null,
        estimatedCost: null,
        estimatedHours: null,
        workflow: workflow,
        requester: currentUser,
        reportingCategory: null,
        scheduledRequestId: null,
        summary: newRequestSummary,
        status: ApiRequestStatus.new,
        location: location || null,
        type: ApiRequestType.corrective,
        priority: ApiRequestPriority.standard,
        scheduling: {
          due: null,
          completed: null,
          start: new Date().toISOString(),
        },
      };

      return result;
    },
    [currentUser, getNewRequestSummary, parsedSearchParams.newRequest]
  );

  const { auto, newRequest, workflowId, locationId, taskbookId, assetsIds } =
    parsedSearchParams;

  const getTaskbook = useCallback(async () => {
    if (!taskbookId) {
      return null;
    }

    const result = await apiClient.findTaskbook(currentAccount.id, taskbookId);
    return result;
  }, [apiClient, currentAccount.id, taskbookId]);

  const getAssets = useCallback(async () => {
    if (!assetsIds || assetsIds.length === 0) {
      return null;
    }

    const { data } = await apiClient.findAssets(currentAccount.id, {
      ids: assetsIds,
    });
    return data;
  }, [apiClient, assetsIds, currentAccount.id]);

  const getData = useCallback(async () => {
    let workflow: ApiWorkflow | null = null;
    let location: ApiLocation | null = null;

    if (workflowId) {
      workflow = workflowMap[workflowId];
    }

    if (locationId) {
      location = locationHash[locationId];
    }

    const taskbook = await getTaskbook();
    const assets = await getAssets();

    return { workflow, location, taskbook, assets };
  }, [
    getAssets,
    getTaskbook,
    locationHash,
    locationId,
    workflowId,
    workflowMap,
  ]);

  const handleNewRequestFromTaskbook = useCallback(async () => {
    if (!newRequest || isLoading) {
      return;
    }

    setIsLoading(true);
    const data = await getData();

    const { workflow, location, taskbook, assets } = data;
    const requestToCreate = getRequestToCreate(workflow, location, taskbook);

    dispatch(
      setAutoCreateRequestData({
        autoCreateRequest: {
          requestToCreate,
          shouldAutoCreate: auto,
        },
      })
    );

    if (taskbook) {
      dispatch(setTaskbook({ taskbook: [taskbook] }));
    }

    if (assets && assets.length > 0) {
      dispatch(setAssets({ assets }));
    }

    dispatch(setNewRequestDialogIsOpen(true));
    dispatch(setQRLookupModalIsOpen(false));
    setIsLoading(false);
    navigate(reactLocation.pathname, { replace: true });
  }, [
    auto,
    dispatch,
    getData,
    getRequestToCreate,
    isLoading,
    navigate,
    newRequest,
    reactLocation.pathname,
  ]);

  return { isLoading, handleNewRequestFromTaskbook };
};

export interface CreateApiRequestFromTaskbookQR
  extends Omit<CreateApiRequest, "workflow"> {
  workflow: ApiWorkflowReference | null;
}
