import { Button, ModalFooter } from "@chakra-ui/react";
import {
  ApiBudget,
  CreateApiBudgetFundingSource,
} from "@operations-hero/lib-api-client";
import { FormikProps } from "formik";
import { FC, useCallback, useRef, useState } from "react";
import { AccountModal } from "../../../../account-settings/account-modal/AccountModal";
import {
  NewFundingSourceAllocationForm,
  NewFundingSourceAllocationProps,
} from "./NewAllocationForm";

export type NewFundingSourceAllocationModalProps = {
  budget: ApiBudget;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    budget: CreateApiBudgetFundingSource
  ) => Promise<void | { success: true }>;
};

export const NewFundingSourceAllocationModal: FC<
  NewFundingSourceAllocationModalProps
> = ({ isOpen, onClose, onSubmit, budget }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const newAllocationFormRef =
    useRef<FormikProps<NewFundingSourceAllocationProps>>(null);

  const handleSubmit = useCallback(() => {
    if (newAllocationFormRef.current) {
      setIsSubmitting(true);
      newAllocationFormRef.current.submitForm().then(() => {
        setIsSubmitting(false);
        if (newAllocationFormRef.current?.isValid) onClose();
      });
    }
  }, [onClose]);

  return !isOpen ? null : (
    <AccountModal
      size="xl"
      title={"Allocate funds"}
      closeButton={false}
      isOpen={isOpen}
      onClose={onClose}
      content={
        <NewFundingSourceAllocationForm
          budget={budget}
          onSubmit={onSubmit}
          formRef={newAllocationFormRef}
        />
      }
      footer={
        <ModalFooter gap={2}>
          <Button variant="outline" size="sm" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme={"blue"}
            size="sm"
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Allocate funds
          </Button>
        </ModalFooter>
      }
    />
  );
};
