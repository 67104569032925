import { HStack, Skeleton, Stack, VStack } from "@chakra-ui/react";
import React from "react";

const BudgetDetailsSkeleton = () => {
  const SingleField = () => {
    return (
      <VStack w="full" align="stretch" gap="2">
        <Skeleton w="50%" h="20px" />
        <Skeleton w="100%" h="40px" />
      </VStack>
    );
  };

  const DoubleField = () => {
    return (
      <HStack w="full" gap={4}>
        <SingleField />
        <SingleField />
      </HStack>
    );
  };
  return (
    <>
      <Stack w="full" flexDirection="column" align="stretch" gap="4">
        <SingleField />
        <SingleField />
        <DoubleField />
        <SingleField />
        <SingleField />
        <SingleField />
        <SingleField />
      </Stack>
    </>
  );
};

export default React.memo(BudgetDetailsSkeleton);
