import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import {
  ApiRequest,
  ApiResponseWithErrors,
  BulkUpdateApiRequest,
} from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { Pager } from "../../../components/pager/Pager";
import { useShowToast } from "../../../hooks/showToast";
import { useMultiSelect } from "../../../hooks/useMultiSelectItems";
import { useScreenBreakpoints } from "../../../hooks/useScreenBreakpoints";
import { RootState, useThunkDispatch } from "../../../store";
import { LoadingStatus } from "../../../store/planning-hq/project-list";
import {
  loadBulkData,
  unload,
  updatePaginationFilters,
} from "../../../store/planning-hq/requests/project-add-bulk-requests";
import { setSelectMode } from "../../../store/request-form/request-bulk-actions.slice";
import { RequestRowContainer } from "../../requests/RequestRowContainer";
import { SkeletonRequests } from "../../requests/SkeletonRequests";
import { RequestList } from "../project/scheduling/request-list/RequestList";
import { RequestBaseItem } from "./RequestBaseItem";
import { RequestListModalHeader } from "./RequestListModalHeader";

type RequestListModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onRequestsAdded?: (
    value: ApiResponseWithErrors<BulkUpdateApiRequest[], BulkUpdateApiRequest>
  ) => void;
  projectId: string;
};

export const RequestListModal: FC<RequestListModalProps> = ({
  isOpen,
  onClose,
  onRequestsAdded,
  projectId,
}) => {
  const dispatch = useDispatch();
  const thunkDispatch = useThunkDispatch();

  const { apiClient, currentAccount } = useAuthentication();
  const {
    filters,
    requests,
    enginesMap,
    loadingStatus,
    pagination: { page, pageSize, total },
  } = useSelector((state: RootState) => state.projectBulkAddRequests);

  const init = useRef<boolean>(false);
  const [loadStatus, setLoadStatus] = useState<LoadingStatus>("idle");
  const [addIsLoading, setAddIsLoading] = useState<boolean>(false);
  const toast = useShowToast();

  const screenModes = useScreenBreakpoints();

  const allowSelectCondition = useCallback(
    (request: ApiRequest) => {
      const engine = enginesMap[request.workflow.id];
      if (!engine) return false;
      const visibleFields = engine.getVisibleFields({ request });
      if (visibleFields.find((vf) => vf.key === "SYSTEM-PROJECT")) return true;
      return false;
    },
    [enginesMap]
  );

  const {
    selectItems,
    totalSelected,
    initialize,
    handleSingleItemSelect,
    resetMultiSelect,
  } = useMultiSelect<ApiRequest>(allowSelectCondition);

  const handleOnAddRequests = useCallback(async () => {
    setAddIsLoading(true);
    try {
      let itemsToUpdate: BulkUpdateApiRequest[] = [];
      for (const item of Object.keys(selectItems)) {
        const { selected, canBeSelected, ...origItem } = selectItems[item];
        if (selected) {
          itemsToUpdate.push({ idOrKey: origItem.id, request: { projectId } });
        }
      }

      const updateResponses = await apiClient.bulkUpdateRequest(
        currentAccount.id,
        itemsToUpdate
      );

      const errors = updateResponses.errors;
      if ((!errors || errors.length === 0) && updateResponses.data.length > 0) {
        toast(
          "success",
          `${updateResponses.data.length} request(s) added successfully`
        );
      }
      if (errors && errors.length > 0)
        toast("error", `${errors.length} request(s) not added`);

      onRequestsAdded && onRequestsAdded(updateResponses);
    } catch (error) {
      toast("error", `An error ocurred`);
    } finally {
      setAddIsLoading(false);
      onClose();
    }
  }, [
    selectItems,
    onRequestsAdded,
    apiClient,
    currentAccount,
    onClose,
    projectId,
    toast,
  ]);

  useEffect(() => {
    if (init.current) return;
    dispatch(setSelectMode("bulk"));
    setLoadStatus("pending");
    thunkDispatch(loadBulkData({ apiClient, account: currentAccount }));
  }, [thunkDispatch, apiClient, currentAccount, dispatch]);

  useEffect(() => {
    if (!init.current) return;
    resetMultiSelect();
    setLoadStatus("pending");
    thunkDispatch(loadBulkData({ apiClient, account: currentAccount }));
  }, [
    thunkDispatch,
    resetMultiSelect,
    filters.workflows,
    filters.statuses,
    filters.locations,
    filters.categories,
    filters.reasons,
    filters.search,
    apiClient,
    currentAccount,
    page,
  ]);

  useEffect(() => {
    try {
      if (!init.current && loadingStatus === "fulfilled") {
        initialize(requests);
        init.current = true;
        setLoadStatus("fulfilled");
      }

      if (loadingStatus === "fulfilled" && init.current) {
        initialize(requests);
        setLoadStatus("fulfilled");
      }

      if (loadingStatus === "rejected") {
        setLoadStatus("rejected");
      }
    } catch (error) {
      setLoadStatus("rejected");
    }
  }, [loadingStatus, initialize, requests]);

  useEffect(() => {
    return () => {
      dispatch(unload());
    };
  }, [dispatch]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="full"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent px={[0, 0, 5, 5, 36]}>
          <ModalHeader>Request List</ModalHeader>
          <ModalCloseButton />
          <Box px={6} pb={4}>
            <RequestListModalHeader />
          </Box>
          <ModalBody>
            <RequestList>
              {loadStatus === "pending" && <SkeletonRequests />}
              {loadStatus === "rejected" && (
                <Text>An error ocurred while loading requests</Text>
              )}
              {loadStatus === "fulfilled" && requests.length === 0 && (
                <Text>No requests found for the applied filters</Text>
              )}
              {loadStatus === "fulfilled" &&
                requests.length > 0 &&
                Object.keys(selectItems).length > 0 &&
                requests.map((request) => {
                  return (
                    <RequestRowContainer
                      selected={selectItems[request.id].selected}
                      setSelectedState={() => {
                        handleSingleItemSelect(request.id);
                      }}
                      linkTo={""}
                      canBeSelected={selectItems[request.id].canBeSelected}
                      currentPage={1}
                      screenMode={screenModes}
                      key={`prj::${projectId}::request::${request.key}`}
                      notAllowedText="Request cannot be linked to a project"
                    >
                      <RequestBaseItem
                        request={request}
                        screenMode={screenModes}
                      />
                    </RequestRowContainer>
                  );
                })}
            </RequestList>
          </ModalBody>
          <ModalFooter
            display="flex"
            flexDir={["column", "column", "row"]}
            justifyContent="space-between"
            rowGap={5}
          >
            <Box display="flex" justifyContent="flex-start" w="full">
              <Pager
                showDetails
                total={total}
                currentPage={page ?? 1}
                pageSize={pageSize ?? 30}
                onPageChange={(page) => {
                  dispatch(updatePaginationFilters({ page }));
                }}
                isDisabled={totalSelected > 0}
              />
            </Box>
            <HStack justifyContent="flex-end" w="full">
              <Button
                colorScheme="blue"
                mr={3}
                onClick={handleOnAddRequests}
                isDisabled={totalSelected === 0}
                isLoading={addIsLoading}
              >
                {`Add (${totalSelected})`}
              </Button>
              <Button onClick={onClose} variant="ghost" colorScheme="blue">
                Cancel
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
