import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  ApiBudget,
  ApiFundingSource,
  CreateApiBudgetFundingSource,
} from "@operations-hero/lib-api-client";
import { Form, Formik, FormikProps } from "formik";
import { FC, MutableRefObject, useCallback, useMemo } from "react";
import * as yup from "yup";
import { useAllocationsContext } from "../../../../../components/allocations/AllocationsContext";
import { AllocationSource } from "../../../../../components/allocations/types";
import { CurrencyInput } from "../../../../../components/inputs/CurrencyInput";
import { FundingSourceAutocomplete } from "../../../../../components/selects/FundingSourceAutocomplete";
import { formatCurrency } from "../../../../../utils/formatCurrency";

type NewFundingSourceAllocationFormProps = {
  budget: ApiBudget;
  onSubmit: (allocation: CreateApiBudgetFundingSource) => Promise<any>;
  formRef: MutableRefObject<FormikProps<NewFundingSourceAllocationProps> | null>;
};

export type NewFundingSourceAllocationProps = {
  source: AllocationSource<ApiFundingSource> | null;
  amount: number;
};

export const NewFundingSourceAllocationForm: FC<
  NewFundingSourceAllocationFormProps
> = ({ budget, onSubmit, formRef }) => {
  const { allocations } = useAllocationsContext();

  const initialValues: NewFundingSourceAllocationProps = useMemo(() => {
    return {
      source: null,
      amount: 0,
    };
  }, []);

  const handleOnSubmit = useCallback(
    (values: NewFundingSourceAllocationProps) => {
      const { source, amount } = values;
      if (!source) return;

      const allocation: CreateApiBudgetFundingSource = {
        budgetId: budget.id,
        fundingSourceId: source?.id,
        amountToAllocate: amount,
      };

      return onSubmit(allocation);
    },
    [onSubmit, budget.id]
  );

  const validationSchema = useMemo(() => {
    const schema = yup.object().shape({
      source: yup.object().nullable().required("Funding source is required"),
      amount: yup
        .number()
        .transform((value) => (isNaN(value) ? undefined : value))
        .nullable()
        .test("allocated-amount-required", "Amount is required", (value) => {
          if (Boolean(value) === false) return false;
          return true;
        })
        .test(
          "allocated-amount-max",
          "Allocation exceeds funding source limit",
          (value, context) => {
            if (!value) return true;

            return value <= parseFloat(context.parent.source.fundsUnallocated);
          }
        ),
    });
    return schema;
  }, []);

  const excludeIds = useMemo(() => {
    const ids = allocations.map((alloc) => alloc.source?.id);
    return ids.filter((value): value is string => Boolean(value));
  }, [allocations]);

  return (
    <>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, errors, submitCount }) => {
          return (
            <Form>
              <VStack align="stretch">
                <FormControl
                  isInvalid={Boolean(errors.source) && submitCount > 0}
                >
                  <FormLabel>From Funding Source</FormLabel>
                  <FundingSourceAutocomplete
                    name="source"
                    value={values.source}
                    onChange={(value) => {
                      setFieldValue("source", value);
                    }}
                    loadOptionParams={{
                      excludeIds: excludeIds,
                      includeFullyAllocated: false,
                    }}
                  />

                  {values.source && (
                    <Text
                      ml={4}
                      fontWeight="bold"
                      fontSize="small"
                    >{`Available funds ${formatCurrency(values.source?.fundsUnallocated ?? 0)}`}</Text>
                  )}

                  <FormErrorMessage>{errors.source}</FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={Boolean(errors.amount) && submitCount > 0}
                >
                  <FormLabel>Amount to allocate</FormLabel>
                  <CurrencyInput
                    value={values.amount}
                    onChange={(valueAsString, valueAsNumber) => {
                      setFieldValue("amount", valueAsNumber);
                    }}
                    isDisabled={Boolean(values.source) === false}
                  />
                  <FormErrorMessage>{errors.amount}</FormErrorMessage>
                </FormControl>
              </VStack>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
