import {
  Box,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import axios, { AxiosProgressEvent } from "axios";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiNampleplatePhotoCapture } from "../../../../account-settings/asset-list/ai-create-modal-steps/AiNampleplatePhotoCapture";

import {
  ApiVendor,
  ApiScannedInvoiceTransaction,
  ApiParsedVendorLineItem,
} from "@operations-hero/lib-api-client";
import { Attachment } from "../../../../../components/attachments/Attachments";
import { useAuthentication } from "../../../../../components/auth/AuthProvider";
import { useShowToast } from "../../../../../hooks/showToast";
import AiProcessingAnimation from "../../../../account-settings/asset-list/ai-create-modal-steps/AiProcessingAnimation";
import { VerifyScannedReceipt } from "./VerifyScannedReceipt";

export interface ScanReceiptModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ScanReceiptModal = ({
  isOpen,
  onClose,
}: ScanReceiptModalProps) => {
  const { apiClient, currentAccount } = useAuthentication();
  const webcamRef = React.useRef(null);
  const showToast = useShowToast();
  const [stage, setStage] = useState("instructions");
  const [workingAttachments, setWorkingAttachments] = useState<Attachment[]>(
    []
  );
  const [itemList, setItemList] = useState<ApiParsedVendorLineItem[]>([]);
  const [scannedPurchase, setScannedPurchase] =
    useState<ApiScannedInvoiceTransaction | null>(null);
  const [manufacturer, setManufacturer] = useState<ApiVendor | null>(null);

  const videoConstraints = useMemo(
    () => ({
      facingMode: "environment",
    }),
    []
  );
  const isImageBlob = useMemo(
    () =>
      workingAttachments.length > 0 &&
      workingAttachments[0].file &&
      workingAttachments[0].url == null,
    [workingAttachments]
  );

  const handleAddAttachment = useCallback(
    (files: Attachment[]) => {
      setStage("loading");
      let allAttachments = [...workingAttachments];

      // todo: only handle 1 file at a time
      files.forEach(async (item) => {
        const response = await apiClient.createUpload(currentAccount.id);
        const newAttachment: Attachment = {
          ...item,
          isNew: true,
          isUploading: true,
          progress: 0,
          uploadId: response.id,
          scannedByAi: true,
        };
        allAttachments.push(newAttachment);

        setWorkingAttachments(allAttachments);
        await axios.put(response.url, newAttachment.file, {
          headers: { "Content-type": newAttachment.file?.type },
          onDownloadProgress: (progressEvent: AxiosProgressEvent) => {
            newAttachment.progress = Math.round(
              (progressEvent.loaded * 100) / (progressEvent.total || 1)
            );

            setWorkingAttachments([...allAttachments]);
          },
        });

        newAttachment.progress = undefined;
        newAttachment.isUploading = false;
        setWorkingAttachments([...allAttachments]);
      });
    },
    [apiClient, currentAccount, workingAttachments]
  );

  const captureReceipt = useCallback(() => {
    const imageSrc = (webcamRef.current as any)?.getScreenshot();
    if (imageSrc) {
      fetch(imageSrc)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], "captured-image.jpg", {
            type: "image/jpeg",
          });
          const attachment: Attachment = {
            file: file,
            created: new Date().toISOString(),
            url: null,
            name: "",
            type: "",
          };
          handleAddAttachment([attachment]);
        });
    }
  }, [webcamRef, handleAddAttachment]);

  // this has to be an effect so it can revoke the object url
  useEffect(() => {
    const file = workingAttachments[0]?.file;
    if (!file || !isImageBlob) {
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [isImageBlob, workingAttachments]);

  useEffect(() => {
    const file = workingAttachments[0]?.file;
    if (!file || !isImageBlob) {
      return;
    }
    const objectUrl = URL.createObjectURL(file);
    return () => {
      if (objectUrl) {
        URL.revokeObjectURL(objectUrl);
      }
    };
  }, [isImageBlob, workingAttachments]);

  // Parse the uploaded image and set the metadata for confirmation
  useEffect(() => {
    const image = workingAttachments[0];

    if (!image || !image.uploadId || image.isUploading || !image.file) {
      return;
    }

    new Promise<void>(async (resolve) => {
      if (!image || !image.file || !image.uploadId) {
        return resolve();
      }
      let receipt: ApiScannedInvoiceTransaction;

      try {
        const isPdf = image.file.type === "application/pdf";
        receipt = await apiClient.parseExpenseReceipt(currentAccount.id, {
          uploadId: image.uploadId!,
          isPdf,
        });
        const paymentDate = receipt?.paymentDate
          ? new Date(receipt?.paymentDate).toISOString()
          : "";
        const datePerformed = receipt?.datePerformed
          ? new Date(receipt?.datePerformed).toISOString()
          : "";

        receipt = { ...receipt, paymentDate, datePerformed };

        if (receipt?.manufacturer) {
          const searchResults = await apiClient.findVendors(currentAccount.id, {
            search: receipt.manufacturer,
          });
          if (searchResults.data.length > 0) {
            setManufacturer(searchResults.data[0]);
          }
        }
        setItemList(receipt?.itemList || []);
        setScannedPurchase(receipt);
        setStage("scanned");
      } catch (error: any) {
        showToast(
          "error",
          "Error with scanning receipt: " + (error?.message || "Unknown error")
        );
        onClose();
      }
      resolve();
    });
  }, [workingAttachments, apiClient, currentAccount, showToast, onClose]);

  return !isOpen ? null : (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size="md">Scan Receipt</Heading>
            {stage === "instructions" && (
              <>
                <AiNampleplatePhotoCapture
                  webcamRef={webcamRef}
                  videoConstraints={videoConstraints}
                  captureNameplate={captureReceipt}
                  workingAttachments={workingAttachments}
                  handleAddAttachment={handleAddAttachment}
                />
              </>
            )}
            {stage === "loading" && (
              <Box>
                <AiProcessingAnimation text="Scanning Receipt..." />
              </Box>
            )}
            {stage === "scanned" && scannedPurchase && (
              <Box>
                <VerifyScannedReceipt
                  scannedPurchase={scannedPurchase}
                  manufacturer={manufacturer}
                  handleCloseModal={onClose}
                  thumbnailPicture={workingAttachments[0]}
                  transactionId={null}
                  workingPurchase={null}
                  itemList={itemList}
                />
              </Box>
            )}
          </ModalHeader>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
};
