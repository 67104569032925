import { Container, useDisclosure } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { Pager } from "../../../components/pager/Pager";
import { useShowToast } from "../../../hooks/showToast";
import { useQueryString } from "../../../hooks/useQueryString";
import { RootState, useThunkDispatch } from "../../../store";

import { CreateApiBudget } from "@operations-hero/lib-api-client";
import {
  BudgetListFilters,
  clearFilters,
  loadBudgets,
  unload as unloadBudgetsList,
  updateFilters,
} from "../../../store/planning-hq/budgets/budget-list.slice";
import { unload as unloadBudgetsPage } from "../../../store/planning-hq/funding-sources/funding-sources-page.slice";
import { NewBudgetModal } from "../budget/new-budget/NewBudgetModal";
import BudgetsSkeleton from "./BudgetsSkeleton";
import { BudgetTable } from "./BudgetsTable";
import { Header } from "./header/Header";

export type BudgetsProps = {};
export const Budgets: FC<BudgetsProps> = () => {
  const { apiClient, currentAccount } = useAuthentication();

  const { loadingStatus, budgets, filters, total } = useSelector(
    (state: RootState) => state.budgetListSlice
  );

  const { queryStringFilter } = useSelector(
    (state: RootState) => state.budgetsPageSlice
  );

  const { query } = useQueryString({});
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const toast = useShowToast();

  const init = useRef<{
    triggered: boolean;
    status: "idle" | "pending" | "fulfilled";
  }>({ triggered: false, status: "idle" });

  const {
    isOpen: isNewBudgetModalOpen,
    onClose: onNewBudgetModalClose,
    onOpen: onNewBudgetModalOpen,
  } = useDisclosure();

  const handleNewBudgetSubmit = useCallback(
    async (budget: CreateApiBudget) => {
      const newBudget = await apiClient
        .createBudget(currentAccount.id, budget)
        .then((budget) => {
          toast("success", "Budget was created successfully");
          return budget;
        })
        .catch(() => {
          toast("error", "An error ocurred while creating a new budget");
        })
        .finally(() => {
          thunkDispatch(loadBudgets({ apiClient }));
          onNewBudgetModalClose();
        });

      return newBudget;
    },
    [apiClient, currentAccount.id, thunkDispatch, onNewBudgetModalClose, toast]
  );

  const handleUpdateFilters = useCallback(
    (value: Partial<BudgetListFilters>) => {
      thunkDispatch(updateFilters(value));
    },
    [thunkDispatch]
  );

  const handleClearFilters = useCallback(() => {
    thunkDispatch(clearFilters());
  }, [thunkDispatch]);

  useEffect(() => {
    if (init.current["triggered"]) return;
    init.current = {
      triggered: true,
      status: "pending",
    };

    let filterObj;
    try {
      filterObj = JSON.parse(atob(query.get("filter") || ""));
    } catch (e) {
      filterObj = null;
    }

    if (filterObj) {
      thunkDispatch(updateFilters(filterObj)).then(() => {
        thunkDispatch(loadBudgets({ apiClient })).then(() => {
          init.current["status"] = "fulfilled";
        });
      });
    } else {
      thunkDispatch(
        loadBudgets({
          apiClient,
          filters: filterObj ? filterObj : undefined,
        })
      ).then(() => {
        init.current = {
          triggered: true,
          status: "fulfilled",
        };
      });
    }
  }, [thunkDispatch, apiClient, query, navigate, dispatch]);

  useEffect(() => {
    if (init.current["triggered"] && init.current["status"] !== "fulfilled")
      return;
    thunkDispatch(loadBudgets({ apiClient }));
  }, [
    thunkDispatch,
    apiClient,
    filters.page,
    filters.search,
    filters.includeInactive,
    filters.fundingSource,
    filters.sort,
    filters.direction,
  ]);

  useEffect(() => {
    if (!queryStringFilter) {
      return;
    }

    navigate(`${pathname}?filter=${queryStringFilter}`, {
      replace: true,
    });
  }, [queryStringFilter, navigate, pathname]);

  useEffect(() => {
    return () => {
      dispatch(unloadBudgetsList());
      dispatch(unloadBudgetsPage());
    };
  }, [dispatch]);

  return (
    <Container
      maxWidth="8xl"
      display="flex"
      gap={4}
      flexDirection="column"
      p={0}
    >
      <Header
        filters={filters}
        updateFilters={handleUpdateFilters}
        clearFilters={handleClearFilters}
        onNewBudgetClick={onNewBudgetModalOpen}
      />

      {loadingStatus === "pending" && <BudgetsSkeleton />}

      {loadingStatus === "fulfilled" && (
        <>
          <BudgetTable budgets={budgets} />

          {filters.page && filters.pageSize && (
            <Pager
              showDetails
              total={total}
              currentPage={filters.page}
              pageSize={filters.pageSize}
              onPageChange={(page) => {
                thunkDispatch(
                  updateFilters({
                    page,
                  })
                );
              }}
            />
          )}
        </>
      )}

      {isNewBudgetModalOpen && (
        <NewBudgetModal
          onSubmit={handleNewBudgetSubmit}
          onClose={onNewBudgetModalClose}
          isOpen={isNewBudgetModalOpen}
        />
      )}
    </Container>
  );
};
