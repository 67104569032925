import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useProductSubscriptions } from "../components/shell/AppShell";
import { RootState } from "../store";

export const useNavActionItems = () => {
  const { hasRequests, hasEvents, hasInventory } = useProductSubscriptions();

  const { isProductAdmin, isEventAdmin, isEventStandard, isInventoryAdmin } =
    useSelector((state: RootState) => state.auth);

  const { isAdmin, isReviewer, isTechnician, reportsSummary } = useSelector(
    (state: RootState) => state.localCache
  );

  const { allowInternalUsersViewCalendar } = useSelector(
    (state: RootState) => state.eventSettingsSlice
  );

  const hasCalendar = useMemo(() => {
    if (
      isProductAdmin ||
      isAdmin ||
      isReviewer ||
      isEventAdmin ||
      isEventStandard ||
      isTechnician ||
      allowInternalUsersViewCalendar
    ) {
      return true;
    }
    return false;
  }, [
    isProductAdmin,
    isAdmin,
    isReviewer,
    isEventAdmin,
    isEventStandard,
    isTechnician,
    allowInternalUsersViewCalendar,
  ]);

  const hasReports = useMemo(() => {
    if (
      reportsSummary["Total"] &&
      reportsSummary["Total"] > 0 &&
      (isAdmin ||
        (hasInventory && isInventoryAdmin) ||
        (hasEvents && isEventAdmin) ||
        (hasRequests && isProductAdmin))
    ) {
      return true;
    }
    return false;
  }, [
    reportsSummary,
    isAdmin,
    hasInventory,
    isInventoryAdmin,
    hasEvents,
    isEventAdmin,
    hasRequests,
    isProductAdmin,
  ]);

  return {
    hasCalendar,
    hasReports,
  };
};
