import { Divider, Grid, GridItem, Icon, Text } from "@chakra-ui/react";
import {
  ApiPurchaseTransaction,
  UpdateApiPurchaseTransaction,
} from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import React, { useCallback, useState } from "react";
import { IoMdAttach } from "react-icons/io";
import { IoTrashSharp } from "react-icons/io5";
import { MdEdit, MdOutlineKeyboardArrowUp } from "react-icons/md";
import { useSelector } from "react-redux";
import { OutlinedIconButton } from "../../../../components/custom-icons/OutlinedIconButton";
import { RootState } from "../../../../store";
import { TRANSACTION_DATE_FORMAT } from "../transactions-helper";
import { ExpensesDataProps } from "./ExpensesData";
import { TransactionEnum } from "./ExpensesTable";
import { RiArrowDropDownLine } from "react-icons/ri";

export const MobileExpensesTable: React.FC<ExpensesDataProps> = ({
  onOpenEditModal,
  setWorkingPurchase,
  openRemoveModal,
  canEditAndDelete,
}) => {
  const { purchasesData } = useSelector(
    (state: RootState) => state.requestForm.transactions.purchases
  );
  const [isCellAccordionOpen, setCellOpenAccordion] = useState<string | null>(
    null
  );

  const purchases = purchasesData as ApiPurchaseTransaction[];

  const handleClickButtons = useCallback(
    (purchase: UpdateApiPurchaseTransaction, action: TransactionEnum) => {
      setWorkingPurchase(purchase as ApiPurchaseTransaction);
      TransactionEnum.EDIT === action && onOpenEditModal();
      TransactionEnum.REMOVE === action && openRemoveModal && openRemoveModal();
    },
    [setWorkingPurchase, onOpenEditModal, openRemoveModal]
  );

  return (
    <Grid templateColumns={["repeat(2, 2fr)"]} gap={2}>
      {purchases.map((purchase) => (
        <React.Fragment key={purchase.id}>
          <GridItem colSpan={1} fontWeight="700">
            {purchase.vendor?.name}
          </GridItem>
          <GridItem colSpan={1} fontWeight="700" textAlign="right">
            {format(new Date(purchase.datePerformed), TRANSACTION_DATE_FORMAT)}
          </GridItem>
          <GridItem colSpan={1} fontWeight="500">
            {purchase.id !== isCellAccordionOpen ? (
              <Text fontWeight="bold">
                Quantity:{" "}
                {purchase.lineItems.reduce(
                  (sum: number, item: any) => sum + item.quantity,
                  0
                ) > 1
                  ? purchase.lineItems.reduce(
                      (sum: number, item: any) => sum + item.quantity,
                      0
                    )
                  : purchase.quantity}
              </Text>
            ) : (
              <>
                {purchase.lineItems.length === 0 || !purchase.lineItems ? (
                  <Text>{purchase.description}</Text>
                ) : (
                  <>
                    {purchase.lineItems.map((item: any, index: number) => (
                      <Text
                        pt={0.5}
                        key={index}
                        maxHeight={10}
                        maxW={200}
                        overflow="hidden"
                        textOverflow="ellipsis"
                        whiteSpace="nowrap"
                      >
                        {item.description}
                      </Text>
                    ))}
                    {purchase.shipping > 0 && (
                      <Text pt={0.5} textAlign="left">
                        Shipping
                      </Text>
                    )}
                    {purchase.tax > 0 && (
                      <Text pt={0.5} textAlign="left">
                        Tax
                      </Text>
                    )}
                    {purchase.additionalFees > 0 && (
                      <Text pt={0.5} textAlign="left">
                        Additional Fees
                      </Text>
                    )}
                  </>
                )}
              </>
            )}
          </GridItem>
          <GridItem colSpan={1} fontWeight="500" textAlign="right">
            {!purchase.lineItems ||
            purchase.lineItems.length === 0 ||
            isCellAccordionOpen !== purchase.id ? (
              <Text pt={0.5} fontWeight="bold">
                {purchase.total.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "USD",
                })}
              </Text>
            ) : (
              <>
                {purchase.lineItems.map((item: any, index: number) => (
                  <Text pt={0.5} key={index}>
                    {item.quantity} {" x "}
                    {item.unitCost.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "USD",
                    })}
                  </Text>
                ))}
                {purchase.shipping > 0 && (
                  <Text pt={0.5}>
                    {purchase.shipping.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "USD",
                    })}
                  </Text>
                )}
                {purchase.tax > 0 && (
                  <Text pt={0.5}>
                    {purchase.tax.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "USD",
                    })}
                  </Text>
                )}
                {purchase.additionalFees > 0 && (
                  <Text pt={0.5}>
                    {purchase.additionalFees.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "USD",
                    })}
                  </Text>
                )}
                <Text fontWeight="bold">
                  {purchase.total.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "USD",
                  })}
                </Text>
              </>
            )}
          </GridItem>
          {canEditAndDelete && canEditAndDelete(purchase) && (
            <GridItem
              colSpan={2}
              textAlign="right"
              pt={2}
              display="flex"
              flexDirection="row"
              justifyContent="right"
              gap="2"
            >
              {purchase.attachmentCount > 0 ? (
                <OutlinedIconButton
                  icon={<Icon as={IoMdAttach} boxSize={5} />}
                />
              ) : null}
              {isCellAccordionOpen === purchase.id && (
                <>
                  <OutlinedIconButton
                    icon={<Icon as={IoTrashSharp} boxSize={5} />}
                    onClick={() =>
                      handleClickButtons(purchase, TransactionEnum.REMOVE)
                    }
                  />
                  <OutlinedIconButton
                    icon={<Icon as={MdEdit} boxSize={5} />}
                    onClick={() =>
                      handleClickButtons(purchase, TransactionEnum.EDIT)
                    }
                  />
                </>
              )}
              {isCellAccordionOpen === purchase.id ? (
                <OutlinedIconButton
                  icon={<Icon as={MdOutlineKeyboardArrowUp} boxSize={5} />}
                  onClick={() => {
                    setCellOpenAccordion(null);
                  }}
                />
              ) : (
                <OutlinedIconButton
                  icon={<Icon as={RiArrowDropDownLine} boxSize={5} />}
                  onClick={() => {
                    setCellOpenAccordion(purchase.id);
                  }}
                />
              )}
            </GridItem>
          )}
          <GridItem colSpan={2} mt={4} mb={4}>
            <Divider />
          </GridItem>
        </React.Fragment>
      ))}
    </Grid>
  );
};
