import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  NumberInput,
  NumberInputField,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  ApiWorkflowField,
  ApiWorkflowFieldDataType,
} from "@operations-hero/lib-api-client";
import React, { useEffect, useState } from "react";
import { Products } from "../../pages/account-settings/location-list/LocationList";
import { AutoGrowTextarea } from "../inputs/AutoGrowTextarea";
import { AutoSavingInput } from "../inputs/AutoSavingInput";
import { StyledDatePicker } from "../inputs/StyledDatePicker";
import { LocationAutocomplete } from "../selects/LocationAutocomplete";
import { StyledSelect } from "../selects/StyledSelect";
import { UserAutocomplete } from "../selects/UserAutocomplete";

export interface CustomFieldInputProps {
  field: ApiWorkflowField;
  value?: any;
  onChange?: (value: any) => void;
  isDisabled?: boolean;
  name: string;
  isInvalid?: boolean;
  errorMessage?: string;
}

export const CustomFieldInput = ({
  field,
  value,
  onChange,
  isDisabled,
  isInvalid,
  errorMessage,
  name,
}: CustomFieldInputProps) => {
  const [numberPrefix, setNumberPrefix] = useState("");
  const [workingValue, setWorkingValue] = useState<any>(value);

  const format = (val: string) => numberPrefix + val;
  const parse = (val: string) => val.replace(numberPrefix, "");

  const bgColor = useColorModeValue("white", "transparent");

  useEffect(() => {
    if (field.dataType === ApiWorkflowFieldDataType.number) {
      setNumberPrefix(field.numberPrefix || "");
    }
  }, [field]);

  const onCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    triggerChange(event.target.checked);
  };

  const handleInputChange = (newValue: string) => {
    triggerChange(newValue);
    return Promise.resolve();
  };

  const handleChange = (newValue: any) => {
    setWorkingValue(newValue);
  };

  const handleBlur = (newValue: any) => {
    setWorkingValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  const triggerChange = (newValue: any) => {
    setWorkingValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
  };

  useEffect(() => {
    if (field.dataType === ApiWorkflowFieldDataType.checkbox) {
      setWorkingValue(value);
    }
  }, [value, field.dataType]);

  return (
    <FormControl
      id={`field-${field.id}`}
      py={field.dataType === ApiWorkflowFieldDataType.checkbox ? 2 : undefined}
      isInvalid={isInvalid}
    >
      {field.dataType !== ApiWorkflowFieldDataType.checkbox && (
        <FormLabel>{field.name || "Custom Field"}</FormLabel>
      )}

      {field.dataType === ApiWorkflowFieldDataType.checkbox && (
        <Checkbox
          size="lg"
          isChecked={workingValue || null}
          onChange={onCheckboxChange}
          isDisabled={isDisabled}
          name={name}
          isInvalid={isInvalid}
        >
          {field.name || "Custom Field"}
        </Checkbox>
      )}

      {field.dataType === ApiWorkflowFieldDataType.date && (
        <StyledDatePicker
          value={value}
          onChange={handleChange}
          isDisabled={isDisabled}
          name={name}
        />
      )}

      {field.dataType === ApiWorkflowFieldDataType.location && (
        <LocationAutocomplete
          value={value}
          onChange={handleChange}
          isDisabled={isDisabled}
          name={name}
          isInvalid={isInvalid}
          bgColor={bgColor}
          productName={Products.HeroHQ}
        />
      )}

      {field.dataType === ApiWorkflowFieldDataType.number && (
        <NumberInput
          value={workingValue ? format(workingValue) : 0}
          onChange={(valueString) => handleChange(parse(valueString))}
          onBlur={(event) => {
            handleBlur(parse(event.target.value));
          }}
          min={field.numberMin}
          max={field.numberMax}
          precision={field.numberPercision}
          isDisabled={isDisabled}
          name={name}
          bgColor={bgColor}
        >
          <NumberInputField />
        </NumberInput>
      )}

      {field.dataType === ApiWorkflowFieldDataType.selection && (
        <>
          <StyledSelect
            isClearable={true}
            value={value || null}
            onChange={handleChange}
            isDisabled={isDisabled}
            options={(field.options || [])
              .filter((x) => x.name && x.value)
              .map((x) => ({ value: x.value, label: x.name }))}
          />
        </>
      )}

      {field.dataType === ApiWorkflowFieldDataType.text && (
        <>
          {field.textAllowMultiline && (
            <AutoGrowTextarea
              value={value}
              onChange={handleChange}
              isDisabled={isDisabled}
              name={name}
            />
          )}
          {!field.textAllowMultiline && (
            <AutoSavingInput
              value={value}
              maxLength={field.textMaxLength}
              onSave={handleInputChange}
              autoComplete="off"
              aria-autocomplete="none"
              isDisabled={isDisabled}
              name={name}
              bgColor={bgColor}
            />
          )}
        </>
      )}

      {field.dataType === ApiWorkflowFieldDataType.user && (
        <UserAutocomplete
          value={value}
          allowEmpty={true}
          onChange={handleChange}
          isDisabled={isDisabled}
          isInvalid={isInvalid}
          bgColor={bgColor}
        />
      )}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
