import { FormControl, FormLabel, VStack } from "@chakra-ui/react";
import { ApiBudget } from "@operations-hero/lib-api-client";
import { FC, useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { TextEditorAutoSave } from "../../../../components/form-helpers/rich-text-editor/RichTextEditorAutoSave";
import { AutoSavingInput } from "../../../../components/inputs/AutoSavingInput";
import { FiscalYearSelect } from "../../../../components/selects/FiscalYearSelect";
import { useShowToast } from "../../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../../store";
import {
  setUpdateStatus,
  setWorkingBudget,
  setWorkingData,
  unload,
  updateBudget,
} from "../../../../store/planning-hq/budgets/budget-form.slice";

export type EditBudgetFormProps = {
  budget: ApiBudget;
  isDisabled?: boolean;
};

export type EditBudgetValues = ApiBudget;

export const EditBudgetForm: FC<EditBudgetFormProps> = ({
  budget,
  isDisabled,
}) => {
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const toast = useShowToast();

  const { apiClient } = useAuthentication();
  const { workingBudget } = useSelector(
    (state: RootState) => state.budgetFormSlice
  );

  const requiredFields: (keyof Partial<ApiBudget>)[] = useMemo(() => {
    return ["name", "fiscalYearStart", "code"];
  }, []);

  const requiredFieldKeys = useMemo(() => {
    return requiredFields as string[];
  }, [requiredFields]);

  const handlePropertyUpdate = useCallback(
    (delta: Partial<ApiBudget>) => {
      const fieldsToUpdate = Object.keys(delta);
      if (fieldsToUpdate.length === 0) return;

      const singleField = fieldsToUpdate[0] as keyof ApiBudget;
      const singleFieldValue = delta[singleField];

      const isValueNull = Array.isArray(singleFieldValue)
        ? singleFieldValue.length === 0
        : Boolean(singleFieldValue) === false;

      if (requiredFieldKeys.includes(singleField) && isValueNull) {
        toast("error", `Field ${singleField.toUpperCase()} is required`);
        const oldValue = budget[singleField];
        dispatch(
          setWorkingBudget({
            ...budget,
            [singleField]: oldValue,
          })
        );

        return;
      }

      if (workingBudget === null) return;
      thunkDispatch(
        updateBudget({
          delta,
          apiClient,
          id: budget.id,
        })
      )
        .then(() => {
          toast("success", "Budget was updated successfully");
        })
        .finally(() => {
          dispatch(setUpdateStatus("idle"));
        });
    },
    [
      apiClient,
      workingBudget,
      thunkDispatch,
      toast,
      dispatch,
      budget,
      requiredFieldKeys,
    ]
  );

  const updateDescription = useCallback(
    (value: string) => {
      if (value === budget.description) return;
      handlePropertyUpdate({ description: value });
    },
    [handlePropertyUpdate, budget.description]
  );

  useEffect(() => {
    if (workingBudget === null) {
      dispatch(
        setWorkingData({
          workingBudget: budget,
        })
      );
    }
  }, [budget, dispatch, workingBudget]);

  useEffect(() => {
    return () => {
      dispatch(unload());
    };
  }, [dispatch]);

  return workingBudget && budget ? (
    <VStack gap={4} align="stretch">
      <FormControl isDisabled={isDisabled}>
        <FormLabel>Budget Name *</FormLabel>
        <AutoSavingInput
          value={workingBudget.name}
          onSave={async (value) => handlePropertyUpdate({ name: value })}
          isRequired={true}
          isDisabled={isDisabled}
        />
      </FormControl>
      <FormControl isDisabled={isDisabled}>
        <FormLabel>Budget code *</FormLabel>
        <AutoSavingInput
          value={workingBudget.code}
          onSave={async (value) => handlePropertyUpdate({ code: value })}
          isRequired={true}
          isDisabled={isDisabled}
        />
      </FormControl>

      <FormControl isDisabled={isDisabled}>
        <FormLabel> Description</FormLabel>
        <TextEditorAutoSave
          id="working-budget::description"
          value={workingBudget.description || ""}
          initialValue={workingBudget.description}
          isRequired={false}
          onBlur={(value) => {
            updateDescription(value || "");
          }}
          isDisabled={isDisabled}
        />
      </FormControl>

      <FormControl isDisabled={isDisabled}>
        <FormLabel>Fiscal Year *</FormLabel>
        <FiscalYearSelect
          fiscalYear={
            workingBudget.fiscalYearStart && workingBudget.fiscalYearEnd
              ? {
                  start: workingBudget.fiscalYearStart,
                  end: workingBudget.fiscalYearEnd,
                }
              : undefined
          }
          onChange={(value) => {
            handlePropertyUpdate({
              fiscalYearStart: value.start,
              fiscalYearEnd: value.end,
            });
          }}
        />
      </FormControl>
    </VStack>
  ) : null;
};
