import { useCallback, useRef } from "react";
import { PdfViewerModalBase } from "./PdfViewerModalBase";

export interface PdfViewerModalNativeProps {
  isOpen: boolean;
  onClose: () => void;
  pdfUrl: string;
  downloadName: string;
}

export const PdfViewerModalNative = ({
  isOpen,
  onClose,
  pdfUrl,
  downloadName,
}: PdfViewerModalNativeProps) => {
  const iframeRef = useRef<HTMLIFrameElement>(null);

  const downloadPdf = useCallback(() => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", downloadName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }, [pdfUrl, downloadName]);

  const printPdf = useCallback(() => {
    iframeRef.current?.contentWindow?.print();
  }, []);

  return (
    <PdfViewerModalBase
      onClose={onClose}
      isOpen={isOpen}
      showActions
      downloadPdf={downloadPdf}
      printPdf={printPdf}
    >
      <iframe
        ref={iframeRef}
        width="100%"
        height="100%"
        src={pdfUrl + "#toolbar=0"}
        allowFullScreen={true}
        title={downloadName}
      />
    </PdfViewerModalBase>
  );
};
