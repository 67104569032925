import { Flex, Text } from "@chakra-ui/react";
import adaptivePlugin from "@fullcalendar/adaptive";
import interactionPlugin, {
  EventReceiveArg,
  EventResizeDoneArg,
} from "@fullcalendar/interaction";
import FullCalendar, { EventDropArg } from "@fullcalendar/react";
import { ApiProject } from "@operations-hero/lib-api-client";
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { useOnRequestAddedToProject } from "../../../../hooks/useOnRequestAddedToProject";
import { RootState, useThunkDispatch } from "../../../../store";
import { findProjectRequests } from "../../../../store/planning-hq/requests/findRequests.thunk";
import { updateProjectSchedule } from "../../../../store/planning-hq/scheduling/scheduling-requests.thunk";
import { RequestTimelineItem } from "./RequestTimelineItem";
import { TimelineHeader } from "./TimelineHeader";
import { ScheduleItem } from "./types";
import { useCustomTimelinePlugin } from "./useCustomTimelinePlugin";

export type SchedulingRefHandle = {
  updateSize: () => void;
};

type TimelineProps = {
  project: ApiProject;
};

export const Timeline = forwardRef<SchedulingRefHandle, TimelineProps>(
  ({ project }, ref) => {
    const { apiClient } = useAuthentication();
    const calendarRef = useRef<FullCalendar>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const { customTimelinePlugin } = useCustomTimelinePlugin();
    const init = useRef(false);

    const { initState, initialEvents } = useSelector(
      (state: RootState) => state.projectScheduling
    );

    const thunkDispatch = useThunkDispatch();

    const rowResources = useMemo(() => {
      const rows = "ABCDEFGHIJKLMNOPQRST".split("");

      return rows.map((r, index) => ({
        id: r,
        title: index + "",
      }));
    }, []);

    const onEventAdded = useCallback(
      (args: EventReceiveArg) => {
        const event = args.event;
        const resources = args.event.getResources();
        const resource = resources[0];

        const updatedItem: ScheduleItem = {
          id: event.id,
          rowId: resource.id,
          start: new Date(event.start ?? "").toISOString(),
          end: new Date(event.end ?? "").toISOString(),
        };

        thunkDispatch(
          updateProjectSchedule({
            projectId: project.id,
            apiClient,
            item: updatedItem,
          })
        ).then(() => {
          thunkDispatch(
            findProjectRequests({
              apiClient,
              projectId: project.id,
            })
          );
        });
      },
      [apiClient, project.id, thunkDispatch]
    );

    const onEventResized = useCallback(
      async (args: EventResizeDoneArg) => {
        const oldValue = args.oldEvent;
        const event = args.event;

        if (oldValue.start !== event.start || oldValue.start !== event.start) {
          const event = args.event;
          const resources = args.event.getResources();
          const resource = resources[0];

          const updatedItem: ScheduleItem = {
            id: event.id,
            rowId: resource.id,
            start: new Date(event.start ?? "").toISOString(),
            end: new Date(event.end ?? "").toISOString(),
          };

          thunkDispatch(
            updateProjectSchedule({
              projectId: project.id,
              apiClient,
              item: updatedItem,
            })
          );
        }
      },
      [apiClient, project.id, thunkDispatch]
    );

    const onEventRowChange = useCallback(
      (args: EventDropArg) => {
        const event = args.event;
        const resources = args.event.getResources();
        const resource = resources[0];

        const updatedItem: ScheduleItem = {
          id: event.id,
          rowId: resource.id,
          start: new Date(event.start ?? "").toISOString(),
          end: new Date(event.end ?? "").toISOString(),
        };

        thunkDispatch(
          updateProjectSchedule({
            projectId: project.id,
            apiClient,
            item: updatedItem,
          })
        );
      },
      [apiClient, project.id, thunkDispatch]
    );

    useOnRequestAddedToProject({
      fullCalendar: calendarRef.current,
    });

    useEffect(() => {
      /**
       * Side effect to scroll to the left every time the component loads. E.g when tabs switch
       */
      if (init.current) return;
      if (initState === "fullfiled") {
        init.current = true;
        calendarRef.current?.getApi().scrollToTime({ days: -100 });
      }
    }, [initState]);

    useImperativeHandle(
      ref,
      () => {
        return {
          updateSize() {
            calendarRef.current?.getApi().updateSize();
          },
        };
      },
      []
    );

    if (!customTimelinePlugin) return null;
    if (!project.start || !project.end) return null;

    return (
      <>
        {!project ? null : (
          <>
            {initState === "idle" ||
              (initState === "pending" && (
                <Text color="gray.500">Loading...</Text>
              ))}

            {initState === "rejected" && (
              <Text color="gray.500">
                An error ocurred while loading requests
              </Text>
            )}

            {initState === "fullfiled" && (
              <Flex ref={containerRef} height="full" flexDir="column" gap={2}>
                {calendarRef.current && (
                  <TimelineHeader
                    calendar={calendarRef.current}
                    project={project}
                    container={containerRef.current as HTMLDivElement}
                  />
                )}
                <FullCalendar
                  key={"requests-scheduling-timeline"}
                  ref={calendarRef}
                  plugins={[
                    adaptivePlugin,
                    interactionPlugin,
                    customTimelinePlugin,
                  ]}
                  initialView="resourceTimelineMonth"
                  headerToolbar={false}
                  editable={true}
                  droppable={true}
                  schedulerLicenseKey={
                    process.env.REACT_APP_FULLCALENDAR_LICENSE
                  }
                  height="100%"
                  resourceAreaWidth={0}
                  resourcesInitiallyExpanded={true}
                  resources={rowResources}
                  expandRows={true}
                  resourceLaneClassNames={["custom-row"]}
                  eventContent={(eventArgs) => (
                    <RequestTimelineItem args={eventArgs} />
                  )}
                  eventClassNames={["project-request"]}
                  eventBorderColor="none"
                  validRange={{
                    start: project.start,
                    end: project.end,
                  }}
                  eventResize={onEventResized}
                  eventDrop={onEventRowChange}
                  eventReceive={onEventAdded}
                  initialEvents={initialEvents}
                  eventOverlap={false}
                  scrollTimeReset={false}
                />
              </Flex>
            )}
          </>
        )}
      </>
    );
  }
);
