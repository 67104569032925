const GCP_STORAGE_PREFIX = "https://storage.googleapis.com";

export const getPreviewUrl = (src: string) => {
  const previewServer = process.env.REACT_APP_IMAGE_RESIZER_SERVER;

  // preview server does not have to be configured
  if (!previewServer || !src || typeof src !== "string") {
    return src;
  }

  if (!src.startsWith(GCP_STORAGE_PREFIX)) {
    return src;
  }

  // remove the env prefix as well
  let path = src
    .replace(GCP_STORAGE_PREFIX, "")
    .replace(/\/oh-(dev|prod)-/, "/");

  return previewServer + "/preview" + path;
};
