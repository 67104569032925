import { Grid, GridItem, Skeleton } from "@chakra-ui/react";

export const NewRequestFormSkeleton = () => {
  return (
    <Grid templateColumns="repeat(6, 1fr)" gap={6}>
      <GridItem colSpan={6} display="flex" gap={2} flexDir="column">
        <Skeleton height="10px" maxW="60px" />
        <Skeleton height="38px" />
      </GridItem>

      <GridItem colSpan={6} display="flex" gap={2} flexDir="column">
        <Skeleton height="10px" maxW="60px" />
        <Skeleton height="38px" />
      </GridItem>

      <GridItem colSpan={[6, 6, 3]} display="flex" gap={2} flexDir="column">
        <Skeleton height="10px" maxW="60px" />
        <Skeleton height="38px" />
      </GridItem>

      <GridItem colSpan={[6, 6, 3]} display="flex" gap={2} flexDir="column">
        <Skeleton height="10px" maxW="60px" />
        <Skeleton height="38px" />
      </GridItem>

      <GridItem colSpan={6} display="flex" gap={2} flexDir="column">
        <Skeleton height="10px" maxW="60px" />
        <Skeleton height="38px" />
      </GridItem>

      <GridItem colSpan={6} display="flex" gap={2} flexDir="column">
        <Skeleton height="10px" maxW="60px" />
        <Skeleton height="96px" />
      </GridItem>

      <GridItem colSpan={[6, 6, 3]} display="flex" gap={2} flexDir="column">
        <Skeleton height="10px" maxW="60px" />
        <Skeleton height="38px" />
      </GridItem>

      <GridItem colSpan={[6, 6, 3]} display="flex" gap={2} flexDir="column">
        <Skeleton height="10px" maxW="60px" />
        <Skeleton height="38px" />
      </GridItem>

      <GridItem colSpan={6} display="flex" gap={2} flexDir="column">
        <Skeleton height="10px" maxW="60px" />
        <Skeleton height="96px" />
      </GridItem>
    </Grid>
  );
};
