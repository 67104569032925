import {
  ApiAccount,
  ApiClient,
  ApiFrequency,
  ApiRequest,
  ApiRequestAssignee,
  ApiRequestsQueryFilter,
  ApiSchedule,
  ApiScheduledRequest,
} from "@operations-hero/lib-api-client";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { scheduledRequestDefaultFilters } from "../pages/account-settings/scheduled-request-list/ScheduleFiltersDefault";
import { unassignedAssignee, unassignee } from "./request-list.slice";

interface LoadScheduledRequestProps {
  apiClient: ApiClient;
  accountId: string;
  locations?: string[];
  categories?: string[];
  workflow?: string | string[];
}

export interface ScheduleAssociation {
  scheduledRequestId?: string;
  scheduleId: string | null;
  schedule?: ApiSchedule | boolean;
}

export type PersonFilterValueType = "assignee";

export interface PersonFilterValue {
  type: PersonFilterValueType;
  groupOrUser: ApiRequestAssignee;
  key: string;
}
export const unassignedPerson: PersonFilterValue = {
  type: "assignee",
  groupOrUser: unassignedAssignee,
  key: "assignee::unassigned",
};
export interface PersonFilterReferenceValue {
  type: PersonFilterValueType;
  groupOrUser: ApiRequestAssignee | string;
}
export interface InitPeopleFilterThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  selectedValues: PersonFilterReferenceValue[];
}
export interface LoadPeopleFilterOptionsThunkParams {
  apiClient: ApiClient;
  account: ApiAccount;
  type: PersonFilterValueType;
  search?: string;
}

export const loadScheduledRequests = createAsyncThunk(
  "scheduled-request/load",
  async (params: LoadScheduledRequestProps, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const { apiClient, accountId, locations, categories, workflow } = params;

    const filters = {
      ...state.scheduleRequestList.filters,
      assignee: state.scheduleRequestList.filters?.persons
        ? state.scheduleRequestList.filters?.persons
            .filter((x) => x.type === "assignee")
            .map((x) =>
              typeof x.groupOrUser === "string"
                ? x.groupOrUser
                : x.groupOrUser.assignee.id
            )
        : undefined,
      locations,
      categories,
      workflow,
    };
    delete filters.persons;
    const schedules = await apiClient.findScheduledRequests(accountId, filters);

    const idsOrKeys = schedules.data
      .filter((sch) => sch.lastRequestKey)
      .map((sch) => sch.lastRequestKey as string);

    const { data: requests } =
      idsOrKeys.length > 0
        ? await apiClient.findRequests(accountId, {
            idsOrKeys,
            pageSize: 100,
            quickFilter: ApiRequestsQueryFilter.ALL,
          })
        : { data: [] as ApiRequest[] };

    return { schedules, requests };
  }
);

interface ToggleDeactivateReactivateScheduledsRequest {
  apiClient: ApiClient;
  accountId: string;
  scheduledRequestId: string;
}

export const deactivateScheduledRequest = createAsyncThunk(
  "scheduled-request/deactivate",
  async (params: ToggleDeactivateReactivateScheduledsRequest, thunkAPI) => {
    const { apiClient, accountId, scheduledRequestId } = params;

    const response = await apiClient.deactivateScheduledRequest(
      accountId,
      scheduledRequestId
    );

    return { scheduledRequestId, response };
  }
);

export const reactivateScheduledRequest = createAsyncThunk(
  "scheduled-request/reactivate",
  async (params: ToggleDeactivateReactivateScheduledsRequest, thunkAPI) => {
    const { apiClient, accountId, scheduledRequestId } = params;
    const updatedScheduledRequest = await apiClient.reactivateScheduledRequest(
      accountId,
      scheduledRequestId
    );

    return { scheduledRequestId, updatedScheduledRequest };
  }
);
export const initPeopleFilter = createAsyncThunk(
  "scheduled-request/init-people-filter",
  async (
    { apiClient, account, selectedValues }: InitPeopleFilterThunkParams,
    thunkAPI
  ) => {
    const ids = selectedValues.map((x) =>
      typeof x.groupOrUser === "string"
        ? x.groupOrUser
        : x.groupOrUser.assignee.id
    );

    const options = {
      ids: ids.filter((x) => x !== unassignee.id),
    };

    const data = await apiClient.findUserAndGroupsRequestFilter(
      account.id,
      options
    );
    const map = data.reduce<Record<string, ApiRequestAssignee>>(
      (result, item) => {
        result[item.assignee.id] = item;
        return result;
      },
      { [unassignee.id]: unassignedAssignee }
    );

    const groupOrUsers = selectedValues.reduce<PersonFilterValue[]>(
      (result, item) => {
        const id =
          typeof item.groupOrUser === "string"
            ? item.groupOrUser
            : item.groupOrUser.assignee.id;
        const hydrated = map[id];

        if (!hydrated) {
          return result;
        }

        result.push({
          type: item.type,
          groupOrUser: hydrated,
          key: `${item.type}::${hydrated.assignee.id}`,
        });

        return result;
      },
      []
    );

    return groupOrUsers;
  }
);
export const loadPeopleFilterOptions = createAsyncThunk(
  "scheduled-request/people-filter-options",
  async (
    { apiClient, account, search, type }: LoadPeopleFilterOptionsThunkParams,
    thunkAPI
  ) => {
    const options = {
      typeToSearch: type,
      search,
    };
    const data = await apiClient.findUserAndGroupsRequestFilter(
      account.id,
      options
    );

    const uniqueMap = data.reduce<Record<string, PersonFilterValue>>(
      (result, item) => {
        const key = `${type}::${item.assignee.id}`;
        result[key] = {
          type,
          groupOrUser: item,
          key,
        };
        return result;
      },
      {}
    );

    const groupOrUser = Object.values(uniqueMap);
    if (type === "assignee") groupOrUser.unshift(unassignedPerson);
    return groupOrUser;
  }
);

export interface ScheduledRequestFilters {
  includeInactive?: boolean;
  page: number;
  search?: string;
  pageSize: number;
  asset?: string[];
  reason?: string[];
  location?: string[];
  category?: string[];
  frequency?: ApiFrequency[];
  executeEvery: number | undefined;
  persons?: PersonFilterReferenceValue[];
  workflow?: string | string[];
}

export interface ScheduledRequestListSliceState {
  data: ApiScheduledRequest[];
  total: number;
  filters: ScheduledRequestFilters;
  toggleUpdate: boolean;
  requestsMap: Record<string, ApiRequest>;
  availablePersons: PersonFilterValue[];
  availablePersonsLoading: boolean;
}

export const scheduleRequestList = createSlice({
  name: "scheduled-request-list",
  initialState: {
    data: [],
    total: 0,
    toggleUpdate: false,
    filters: { ...scheduledRequestDefaultFilters },
    requestsMap: {} as Record<string, ApiRequest>,
    availablePersons: [] as PersonFilterValue[],
    availablePersonsLoading: false,
  } as ScheduledRequestListSliceState,
  reducers: {
    unloadScheduledRequestList: (state) => {
      state.data = [];
      state.total = 0;
      state.filters = { ...scheduledRequestDefaultFilters };
      state.requestsMap = {} as Record<string, ApiRequest>;
    },

    updateScheduleRequestFilters: (
      state,
      action: PayloadAction<Partial<ScheduledRequestFilters>>
    ) => {
      state.filters = {
        ...state.filters,
        ...action.payload,
      };
    },

    addScheduledRequestToList: (
      state,
      action: PayloadAction<ApiScheduledRequest>
    ) => {
      state.data.push(action.payload);
    },

    updateScheduledRequestInList: (
      state,
      action: PayloadAction<ApiScheduledRequest>
    ) => {
      const index = state.data.findIndex(
        (scheduledRequest) => scheduledRequest.id === action.payload.id
      );

      if (index !== -1) {
        state.data[index] = action.payload;
      }
    },
    updateScheduleInList: (
      state,
      action: PayloadAction<ScheduleAssociation>
    ) => {
      const { scheduledRequestId, scheduleId, schedule } = action.payload;

      const index = state.data.findIndex(
        (scheduledRequest) => scheduledRequest.id === scheduledRequestId
      );
      if (index < 0) {
        return;
      }
      const scheduledRequestCopy = { ...state.data[index] };
      if (scheduleId === null && typeof schedule !== "boolean" && schedule) {
        scheduledRequestCopy.schedules.push(schedule);
        state.data[index] = scheduledRequestCopy;
        return;
      }
      const schIndex = scheduledRequestCopy.schedules.findIndex(
        (sch) => sch.id === scheduleId
      );
      if (schIndex < 0 || schedule === undefined) {
        return;
      }
      if (schedule === false) {
        scheduledRequestCopy.schedules.splice(schIndex, 1);
        state.data[index] = scheduledRequestCopy;
        return;
      }
      if (typeof schedule !== "boolean") {
        scheduledRequestCopy.schedules[schIndex] = schedule;
      }

      state.data[index] = scheduledRequestCopy;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(loadScheduledRequests.fulfilled, (state, action) => {
      const { schedules, requests } = action.payload;
      const { data, total } = schedules;
      state.data = data;
      state.total = total;

      state.data = action.payload.schedules.data;
      state.total = action.payload.schedules.total;
      state.requestsMap = requests.reduce<Record<string, ApiRequest>>(
        (result, req) => {
          result[req.key] = req;
          return result;
        },
        {}
      );
    });

    builder.addCase(deactivateScheduledRequest.fulfilled, (state, action) => {
      const index = state.data.findIndex(
        (item) => item.id === action.payload.scheduledRequestId
      );
      if (!state.filters.includeInactive) {
        index !== -1 && state.data.splice(index, 1);
      } else {
        state.toggleUpdate = !state.toggleUpdate;
      }
    });

    builder.addCase(reactivateScheduledRequest.fulfilled, (state, action) => {
      const index = state.data.findIndex(
        (item) => item.id === action.payload.scheduledRequestId
      );

      if (index !== -1) {
        state.data[index] = action.payload.updatedScheduledRequest;
      }
    });
    builder.addCase(initPeopleFilter.fulfilled, (state, action) => {
      state.filters.persons = action.payload;
      state.availablePersons = action.payload;
    });

    builder.addCase(loadPeopleFilterOptions.fulfilled, (state, action) => {
      const isSearching = !!action.meta.arg.search;
      const selected = state.filters.persons;

      if (isSearching || !selected || selected.length === 0) {
        state.availablePersons = action.payload;
        return;
      }

      const itemsToAdd = selected.filter(
        (x) => x.type === action.meta.arg.type
      );

      let uniqueMap: Record<string, PersonFilterValue> = {};
      uniqueMap = itemsToAdd.reduce<Record<string, PersonFilterValue>>(
        (result, item) => {
          const hydrated = item as PersonFilterReferenceValue;
          const id =
            typeof hydrated.groupOrUser === "string"
              ? hydrated.groupOrUser
              : hydrated.groupOrUser.assignee.id;
          result[id] = item as PersonFilterValue;
          return result;
        },
        {}
      );

      uniqueMap = action.payload.reduce<Record<string, PersonFilterValue>>(
        (result, item) => {
          result[item.groupOrUser.assignee.id] = item;
          return result;
        },
        uniqueMap
      );

      state.availablePersons = Object.values(uniqueMap);
    });
  },
});

export const {
  addScheduledRequestToList,
  updateScheduledRequestInList,
  unloadScheduledRequestList,
  updateScheduleRequestFilters,
  updateScheduleInList,
} = scheduleRequestList.actions;

export default scheduleRequestList.reducer;
