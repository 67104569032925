import { Divider, Flex, Stack, Text } from "@chakra-ui/react";
import { ApiBudgetWithFundingSources } from "@operations-hero/lib-api-client";
import moment from "moment";
import { FC, useMemo } from "react";
import { FundingSourceBadge } from "../../../components/badges/FundingSourceBadge";
import { LocaleDate } from "../../../components/dates/LocaleDate";
import { formatCurrency } from "../../../utils/formatCurrency";

const FISCAL_YEAR_FORMAT = "MMMM YYYY" as const;

type BudgetCardProps = {
  budget: ApiBudgetWithFundingSources;
};

export const BudgetCard: FC<BudgetCardProps> = ({ budget }) => {
  const {
    name,
    code,
    fundingSources,
    startingAmount,
    remainingAmount,
    fiscalYearStart,
    fiscalYearEnd,
    created,
  } = budget;

  const fiscalYearFormatted = useMemo(() => {
    if (!fiscalYearStart || !fiscalYearEnd) return "-";
    return `${moment(fiscalYearStart).format(
      FISCAL_YEAR_FORMAT
    )} - ${moment(fiscalYearEnd).format(FISCAL_YEAR_FORMAT)} `;
  }, [fiscalYearStart, fiscalYearEnd]);

  return (
    <Flex
      w="full"
      boxShadow="md"
      borderRadius="md"
      align="stretch"
      p={4}
      flexDir="column"
      gap={2}
    >
      <Flex w="full" gap={2} roundedTop="md" height="fit-content">
        <Text colorScheme="blue" fontSize="md" fontWeight="bold" isTruncated>
          {name}
        </Text>
      </Flex>
      <Divider />
      <Flex
        w="full"
        justify="space-between"
        alignItems="flex-start"
        flexDir="column"
      >
        <Text color={"gray.500"}>{code}</Text>
      </Flex>
      <Flex w="full" justify="space-between" alignItems="center" wrap="wrap">
        <Flex flexDir="column" height="full">
          <Text fontWeight="semibold">
            Amount:
            <Text as="span" fontWeight="normal" ml={1}>
              {formatCurrency(startingAmount)}
            </Text>
          </Text>
          <Text fontWeight="semibold">
            Spent:
            <Text as="span" fontWeight="normal" ml={1}>
              {formatCurrency(startingAmount - remainingAmount)}
            </Text>
          </Text>
          <Text fontWeight="semibold">
            Remaining:
            <Text as="span" fontWeight="normal" ml={1}>
              {formatCurrency(remainingAmount)}
            </Text>
          </Text>
        </Flex>
        <Flex flexDir="column" height="full" alignItems="start">
          <Text fontWeight="semibold">
            Fiscal year:
            <Text as="span" fontWeight="normal" ml={1}>
              {fiscalYearFormatted}
            </Text>
          </Text>
          <Text fontWeight="semibold">
            Added:
            <Text as="span" fontWeight="normal" ml={1}>
              {created && (
                <LocaleDate
                  date={created}
                  options={{
                    month: "long",
                    day: undefined,
                    year: "numeric",
                  }}
                />
              )}
            </Text>
          </Text>
        </Flex>
      </Flex>
      <Stack direction="row" flexWrap="wrap" alignContent="center">
        <Text fontWeight="semibold">Budgets:</Text>
        {fundingSources.map((fs) => (
          <FundingSourceBadge value={fs.source} key={`fs::${fs.source.id}`} />
        ))}
      </Stack>
    </Flex>
  );
};
