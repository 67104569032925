import { Box, Square, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import { ApiLocationSummary } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../store";
import { useAuthentication } from "../auth/AuthProvider";

interface LocationColorLabelProps {
  location: ApiLocationSummary | null;
  hideChangeColor?: boolean;
}

export const LocationColorLabel: FC<LocationColorLabelProps> = ({
  location,
  hideChangeColor,
}) => {
  const { isProductAdmin, isEventAdmin, isInventoryAdmin } =
    useAuthentication();
  const { locationMap } = useSelector((state: RootState) => state.localCache);
  const linkTextColor = useColorModeValue("blue.600", "blue.200");
  return location ? (
    <Stack flexDir="row" alignItems="center" gap={2}>
      <Text fontWeight="bold" as="span">
        Location Color:
      </Text>
      <Box w="max-content">
        <Square
          rounded="md"
          size="30px"
          bg={`${locationMap[location.id]?.colorId}`}
          color="white"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="blue.800"
        />
      </Box>
      {!hideChangeColor && (
        <Text as="span">
          {"(Change color in "}
          {isProductAdmin || isEventAdmin || isInventoryAdmin ? (
            <Link to={`/account/locations/${location.id}`} target="_blank">
              <Text
                as="span"
                textDecoration="underline"
                textColor={linkTextColor}
              >
                Locations
              </Text>
            </Link>
          ) : (
            <Text
              as="span"
              textDecoration="underline"
              textColor={linkTextColor}
            >
              Locations
            </Text>
          )}
          {")"}
        </Text>
      )}
    </Stack>
  ) : null;
};
