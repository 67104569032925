import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  HStack,
  Icon,
  StackDivider,
  Text,
  Tooltip,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import {
  ApiInventoryItemAdjustment,
  ApiInventoryType,
  ApiIssuanceTransaction,
  ApiItemAdjustmentType,
  ApiRequest,
  ApiTransaction,
  ApiTransactionType,
} from "@operations-hero/lib-api-client";
import { format } from "date-fns";
import { FC, useCallback, useEffect, useState } from "react";
import { IoTrashSharp } from "react-icons/io5";
import {
  MdAdd,
  MdArrowBack,
  MdEdit,
  MdOutlineAssignmentReturn,
} from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../components/auth/AuthProvider";
import { OutlinedIconButton } from "../../../components/custom-icons/OutlinedIconButton";
import { Pager } from "../../../components/pager/Pager";
import { useShowToast } from "../../../hooks/showToast";
import { RootState, useThunkDispatch } from "../../../store";
import { InventoryItem } from "../../../store/inventory/inventory-item-list.slice";
import {
  setIssuanceTransactionsCurrentPage,
  setTotalInventoryChange,
} from "../../../store/request-form/request-form.slice";
import { loadTransactions } from "../../../store/request-form/thunks/loadTransactions.thunk";
import { formatCurrency } from "../../../utils/formatCurrency";
import { AccountModal } from "../../account-settings/account-modal/AccountModal";
import { CheckinForm } from "../../inventory/inventory-items/inventory-checkin-checkout/CheckinForm";
import { CheckoutForm } from "../../inventory/inventory-items/inventory-checkin-checkout/CheckoutForm";
import { InventoryIssuanceForm } from "./InventoryIssuanceForm";
import { InventoryReturnToInventoryForm } from "./InventoryReturnToInventoryForm";
import { ItemSearchAutocomplete } from "./ItemSearchAutocomplete";

interface InventoryIssuanceProps {
  request: ApiRequest;
  canCreateInventoryIssuance?: boolean;
}
const DATE_FORMAT = "M-d-y";

export const InventoryIssuance: FC<InventoryIssuanceProps> = ({
  request,
  canCreateInventoryIssuance,
}) => {
  const thunkDispatch = useThunkDispatch();
  const dispatch = useDispatch();
  const { apiClient, currentAccount } = useAuthentication();
  const { issuanceData, issuanceTotal, issuanceCurrentPage } = useSelector(
    (state: RootState) => state.requestForm.transactions.issuance
  );
  const [item, setItem] = useState<InventoryItem | null>(null);
  const showToast = useShowToast();

  const [itemToReturn, setItemToReturn] =
    useState<ApiInventoryItemAdjustment>();
  const [transactionForReturn, setTransactionForReturn] =
    useState<ApiIssuanceTransaction>();

  const {
    isOpen: isOpenInventoryIssuance,
    onOpen: onOpenInventoryIssuance,
    onClose: onCloseInventoryIssuance,
  } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false });

  const {
    isOpen: isOpenInventoryIssuanceForm,
    onOpen: onOpenInventoryIssuanceFrom,
    onClose: onCloseInventoryIssuanceForm,
  } = useDisclosure();

  const {
    isOpen: isOpenInventoryReturnForm,
    onOpen: onOpenInventoryReturnForm,
    onClose: onCloseInventoryReturnForm,
  } = useDisclosure();

  const handleOnPageChange = useCallback(
    (value: number) => {
      dispatch(setIssuanceTransactionsCurrentPage(value));
    },
    [dispatch]
  );

  const handleOnCloseModal = useCallback(() => {
    setItem(null);
    onCloseInventoryIssuance();
  }, [onCloseInventoryIssuance]);

  const handleOnChangeItem = useCallback(
    (value: InventoryItem | null) => {
      value && onOpenInventoryIssuanceFrom();
      setItem(value);
      onCloseInventoryIssuance();
    },
    [onOpenInventoryIssuanceFrom, onCloseInventoryIssuance]
  );

  const handleOnCloseFormModal = useCallback(() => {
    setItem(null);
    onCloseInventoryIssuanceForm();
  }, [onCloseInventoryIssuanceForm]);

  const handleOnCloseReturnFormModal = useCallback(() => {
    setItemToReturn(undefined);
    onCloseInventoryReturnForm();
  }, [onCloseInventoryReturnForm]);

  const handleCancelRequestTransaction = useCallback(
    (transactionId: string) => {
      apiClient
        .deleteRequestTransaction(currentAccount.id, request.id, transactionId)
        .then(() => {
          showToast("success", "Transaction was deleted successfully");
          thunkDispatch(
            loadTransactions({
              apiClient,
              account: currentAccount,
              key: request.id,
              transactionType: "issuance",
            })
          );
          dispatch(setTotalInventoryChange(-1));
        })
        .catch(() => {
          showToast("error", "something went wrong deleting a Transaction");
        });
    },
    [apiClient, request.id, showToast, currentAccount, thunkDispatch, dispatch]
  );

  const handleOnCloseInventoryIssuanceForm = useCallback(() => {
    onCloseInventoryIssuanceForm();
    setItem(null);
    if (!request) return;
    thunkDispatch(
      loadTransactions({
        apiClient,
        account: currentAccount,
        key: request.id,
        transactionType: "issuance",
      })
    );
  }, [
    apiClient,
    currentAccount,
    onCloseInventoryIssuanceForm,
    thunkDispatch,
    request,
  ]);

  const handleOnCloseInventoryReturnForm = useCallback(() => {
    onCloseInventoryReturnForm();
    setItemToReturn(undefined);
    if (!request) return;
    thunkDispatch(
      loadTransactions({
        apiClient,
        account: currentAccount,
        key: request.id,
        transactionType: "issuance",
      })
    );
  }, [
    apiClient,
    currentAccount,
    onCloseInventoryReturnForm,
    thunkDispatch,
    request,
  ]);

  const startReturnItemProcess = useCallback(
    (transaction: ApiTransaction) => {
      if (transaction.type === ApiTransactionType.issuance) {
        setItemToReturn(transaction.inventoryItemAdjustment);
        setTransactionForReturn(transaction);
        onOpenInventoryReturnForm();
      }
    },
    [onOpenInventoryReturnForm]
  );

  const isReturnTransaction = useCallback((transaction: ApiTransaction) => {
    return (
      transaction.type === ApiTransactionType.issuance &&
      transaction.inventoryItemAdjustment?.type ===
        ApiItemAdjustmentType.returnedToInventory
    );
  }, []);

  useEffect(() => {
    if (!request) return;
    thunkDispatch(
      loadTransactions({
        apiClient,
        account: currentAccount,
        key: request.id,
        transactionType: "issuance",
      })
    );
  }, [
    thunkDispatch,
    apiClient,
    currentAccount,
    request.id,
    issuanceCurrentPage,
    request,
  ]);

  return (
    <VStack gap={3} width="100%">
      <HStack justifyContent="space-between" width="100%">
        <Heading size="md">Inventory Issuance</Heading>
        {canCreateInventoryIssuance && (
          <Button
            size="sm"
            colorScheme="blue"
            variant="outline"
            onClick={onOpenInventoryIssuance}
          >
            <Icon as={MdAdd} mr={1} />
            Issue Item
          </Button>
        )}
      </HStack>
      <AccountModal
        title={
          <VStack alignItems="flex-start" fontWeight="bold" gap={2}>
            <Heading fontSize="2xl">QR Scan</Heading>
            <Text fontSize="md">Search by keyword or item #</Text>
          </VStack>
        }
        isOpen={isOpenInventoryIssuance}
        onClose={handleOnCloseModal}
        content={
          <ItemSearchAutocomplete
            value={item || null}
            onChange={handleOnChangeItem}
          />
        }
      />

      {isOpenInventoryIssuanceForm &&
        item &&
        (item.type === ApiInventoryType.checkout ||
          item.type === ApiInventoryType.bulkCheckout) && (
          <AccountModal
            content={
              <CheckoutForm
                onClose={handleOnCloseInventoryIssuanceForm}
                itemProp={item}
                request={request}
              />
            }
            title={`Checkout Item - ${item.name}`}
            titleProps={{ fontSize: "2xl" }}
            onClose={handleOnCloseFormModal}
            isOpen={isOpenInventoryIssuanceForm}
          />
        )}

      {isOpenInventoryIssuanceForm &&
        item &&
        item.type !== ApiInventoryType.checkout &&
        item.type !== ApiInventoryType.bulkCheckout && (
          <AccountModal
            content={
              <InventoryIssuanceForm
                onClose={handleOnCloseInventoryIssuanceForm}
                item={item}
                request={request}
              />
            }
            title={`Issue Item - ${item.name}`}
            titleProps={{ fontSize: "2xl" }}
            onClose={handleOnCloseFormModal}
            isOpen={isOpenInventoryIssuanceForm}
          />
        )}

      {itemToReturn &&
        transactionForReturn &&
        (itemToReturn.item.type === ApiInventoryType.checkout ||
          itemToReturn.item.type === ApiInventoryType.bulkCheckout) && (
          <AccountModal
            content={
              <CheckinForm
                adjustment={itemToReturn}
                onClose={handleOnCloseInventoryReturnForm}
              />
            }
            title="Checkin form"
            titleProps={{ fontSize: "2xl" }}
            contentProps={{
              maxW: ["90%", "90%", "60%"],
              minW: ["90%", "90%", "60%"],
            }}
            isOpen={isOpenInventoryReturnForm}
            onClose={handleOnCloseReturnFormModal}
          />
        )}

      {itemToReturn &&
        transactionForReturn &&
        itemToReturn.item.type !== ApiInventoryType.checkout &&
        itemToReturn.item.type !== ApiInventoryType.bulkCheckout && (
          <AccountModal
            content={
              <InventoryReturnToInventoryForm
                issuedItem={itemToReturn}
                issuedItemTransaction={transactionForReturn}
                onClose={handleOnCloseInventoryReturnForm}
              />
            }
            title="Return form"
            titleProps={{ fontSize: "2xl" }}
            isOpen={isOpenInventoryReturnForm}
            onClose={handleOnCloseReturnFormModal}
          ></AccountModal>
        )}

      <Box width="100%">
        <VStack width="100%">
          {issuanceData.length > 0 ? (
            <>
              <VStack width="100%" gap={2} divider={<StackDivider />}>
                {!isMobile && (
                  <HStack width="100%">
                    <Heading size="sm" width="15%">
                      Item #
                    </Heading>
                    <Heading size="sm" width="20%">
                      Description
                    </Heading>
                    <Heading size="sm" width="10%">
                      Quantity
                    </Heading>
                    <Heading size="sm" width="20%">
                      Total Cost
                    </Heading>
                    <Heading size="sm" width="35%">
                      Date
                    </Heading>
                  </HStack>
                )}
                {issuanceData.map((inventory) =>
                  !isMobile ? (
                    <HStack
                      width="100%"
                      key={`inventoryIssuance::${inventory.id}`}
                    >
                      <VStack width="15%" alignItems="start">
                        <Text w="full">
                          {inventory.type === ApiTransactionType.issuance &&
                            inventory.inventoryItemAdjustment?.item?.identifiers
                              .externalId}
                        </Text>
                      </VStack>
                      <Text width="20%">
                        {inventory.type === ApiTransactionType.issuance &&
                          inventory.inventoryItemAdjustment?.item?.summary}
                      </Text>
                      <Text width="10%">
                        {inventory.type === ApiTransactionType.issuance &&
                          inventory.inventoryItemAdjustment?.quantity}
                      </Text>
                      <Text width="20%">
                        {inventory.type === ApiTransactionType.issuance &&
                          formatCurrency(
                            inventory.quantity * inventory.unitCost
                          )}
                      </Text>
                      <HStack width="35%" justifyContent="space-between">
                        <Text>
                          {inventory.type === ApiTransactionType.issuance &&
                            format(
                              new Date(inventory.inventoryItemAdjustment?.date),
                              DATE_FORMAT
                            )}
                        </Text>

                        <Flex gap={2}>
                          {isReturnTransaction(inventory) && (
                            <Badge size="xs">RETURNED</Badge>
                          )}
                          {canCreateInventoryIssuance && (
                            <>
                              {inventory.type === ApiTransactionType.issuance &&
                                inventory.inventoryItemAdjustment?.type ===
                                  ApiItemAdjustmentType.issued && (
                                  <Tooltip label="Return items">
                                    <span>
                                      <OutlinedIconButton
                                        icon={
                                          <Icon
                                            as={MdOutlineAssignmentReturn}
                                            boxSize={5}
                                          />
                                        }
                                        onClick={() => {
                                          startReturnItemProcess(inventory);
                                        }}
                                      />
                                    </span>
                                  </Tooltip>
                                )}

                              {/* 
                              Enable this when the transactions also adjust the inventory
                              on deletion
                              
                              <OutlinedIconButton
                                icon={<Icon as={IoTrashSharp} boxSize={5} />}
                                onClick={() => {
                                  handleCancelRequestTransaction(inventory.id);
                                }}
                              /> */}
                            </>
                          )}
                        </Flex>
                      </HStack>
                    </HStack>
                  ) : (
                    <VStack
                      width="100%"
                      key={`inventoryIssuance::${inventory.id}`}
                    >
                      <HStack width="100%" justifyContent="space-between">
                        <Text fontWeight="bold">
                          {inventory.type === ApiTransactionType.issuance &&
                            inventory.inventoryItemAdjustment?.item?.identifiers
                              .externalId}
                        </Text>
                        <Text>
                          {inventory.type === ApiTransactionType.issuance &&
                            inventory.inventoryItemAdjustment?.item?.summary}
                        </Text>
                      </HStack>
                      <HStack width="100%" justifyContent="space-between">
                        <Text>
                          Quantity:{" "}
                          {inventory.type === ApiTransactionType.issuance &&
                            inventory.inventoryItemAdjustment?.quantity}
                        </Text>
                        <Text>
                          {inventory.type === ApiTransactionType.issuance &&
                            format(
                              new Date(inventory.inventoryItemAdjustment?.date),
                              DATE_FORMAT
                            )}
                        </Text>
                        {isReturnTransaction(inventory) ? (
                          <Badge size="xs">RETURNED</Badge>
                        ) : (
                          canCreateInventoryIssuance && (
                            <Flex gap={2}>
                              <OutlinedIconButton
                                icon={<Icon as={IoTrashSharp} boxSize={5} />}
                                onClick={() => {
                                  handleCancelRequestTransaction(inventory.id);
                                }}
                              />
                              <OutlinedIconButton
                                icon={<Icon as={MdEdit} boxSize={5} />}
                                onClick={() => {}}
                              />
                              <OutlinedIconButton
                                icon={<Icon as={MdArrowBack} boxSize={5} />}
                                onClick={() => {
                                  startReturnItemProcess(inventory);
                                }}
                              />
                            </Flex>
                          )
                        )}
                      </HStack>
                    </VStack>
                  )
                )}
              </VStack>
              <Divider />
              {issuanceTotal > 20 ? (
                <Box width="100%" pt={2}>
                  <Pager
                    currentPage={issuanceCurrentPage || 1}
                    pageSize={20}
                    total={issuanceTotal}
                    onPageChange={handleOnPageChange}
                  />
                </Box>
              ) : null}
            </>
          ) : (
            <Text pb={6} pt={2} width="100%">
              Add an item used for this request.
            </Text>
          )}
        </VStack>
      </Box>
    </VStack>
  );
};
