import { useCallback, useState } from "react";
import { getPreviewUrl } from "../utils/getImagePreviewUrl";
import { useShowToast } from "./showToast";

function useDownloadAttachment(displayUrl: string, name: string) {
  const showToast = useShowToast();
  const [isDownloading, setIsDownloading] = useState(false);
  const downloadImage = useCallback(async () => {
    const previewUrl = displayUrl ? getPreviewUrl(displayUrl) : displayUrl;
    if (!previewUrl) return;
    try {
      setIsDownloading(true);
      const response = await fetch(previewUrl);
      if (!response.ok) throw new Error();

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.href = blobUrl;
      anchor.download = name || `downloaded-file-${new Date().toString()}`;
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      showToast(
        "error",
        "There was an error downloading the image, please try later"
      );
    } finally {
      setIsDownloading(false);
    }
  }, [displayUrl, name, showToast]);

  return { downloadImage, isDownloading };
}

export default useDownloadAttachment;
