import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  Grid,
  GridItem,
  HStack,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import {
  ApiBudget,
  ApiFundingSource,
  ApiFundingSourceSummary,
} from "@operations-hero/lib-api-client";
import { FC } from "react";
import { FundingSourceAllocationProvider } from "../../../../components/allocations/FundingSourcesAllocationsProvider";
import { Allocation } from "../../../../components/allocations/types";
import { FundingSourceBadge } from "../../../../components/badges/FundingSourceBadge";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { AddAllocationButton } from "./funding-source-allocations/AddAllocationButton";

type FundingSourceAllocationsProps = {
  budget: ApiBudget;
  allocations: Allocation<ApiFundingSource | ApiFundingSourceSummary>[];
  isDisabled?: boolean;
};

export const FundingSourceAllocations: FC<FundingSourceAllocationsProps> = ({
  budget,
  allocations,
  isDisabled = false,
}) => {
  const { colorMode } = useColorMode();

  return (
    <FundingSourceAllocationProvider
      initialAllocations={allocations as Allocation<ApiFundingSource>[]}
    >
      {({ allocations, totalAllocated }) => {
        return (
          <>
            <HStack w="full" justify="space-between">
              <Text>Funding sources</Text>
              <AddAllocationButton
                budget={budget}
                buttonProps={{ isDisabled: isDisabled }}
              />
            </HStack>
            <VStack alignSelf="center" gap={3} w="full" justify="stretch">
              <Grid templateColumns="repeat(3, 1fr)" gap={4} w="full">
                {allocations.map((allocation, index) => (
                  <>
                    <GridItem alignContent="center">
                      {allocation.source && (
                        <>
                          <FundingSourceBadge value={allocation.source} />
                          <Text
                            fontWeight="bold"
                            fontSize="small"
                            color="gray.500"
                          >{`Available funds ${formatCurrency(allocation.source.fundsUnallocated ?? 0)}`}</Text>
                        </>
                      )}
                    </GridItem>
                    <GridItem alignContent="center">
                      <Box justifySelf="center">
                        <ArrowForwardIcon boxSize="5" />
                      </Box>
                    </GridItem>
                    <GridItem px="6" alignContent="center">
                      <Text justifySelf="end">
                        {formatCurrency(allocation.amount ?? 0)}
                      </Text>
                    </GridItem>
                  </>
                ))}
              </Grid>
            </VStack>
            <VStack
              w="full"
              backgroundColor={colorMode === "light" ? "gray.50" : "gray.700"}
              justify="stretch"
              alignItems="end"
              py="4"
              px="6"
              rounded="md"
              border={colorMode === "light" ? "none" : "solid"}
              borderWidth="thin"
              borderColor="gray.600"
            >
              <Text
                fontSize="large"
                fontWeight="bold"
              >{`${formatCurrency(totalAllocated)}`}</Text>
              <Text>Total Amount Allocated</Text>
            </VStack>
          </>
        );
      }}
    </FundingSourceAllocationProvider>
  );
};
