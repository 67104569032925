import { Avatar, Box, BoxProps, Text } from "@chakra-ui/react";
import { ApiEventGroupSumary } from "@operations-hero/lib-api-client";
import { FC } from "react";
import { ApprovalStateBadge } from "./ApprovalStatesBadges";

interface GroupBadgeProps {
  group: ApiEventGroupSumary;
  boxProps?: BoxProps;
}

export const EventGroupBadge: FC<GroupBadgeProps> = ({ group, boxProps }) => {
  return (
    <Box gap={2} flexDir="row" display="flex" {...boxProps}>
      <Avatar src={group.logo || ""} name={group.name} />
      <Box display={["block", "flex", "block", "block", "flex"]}>
        <Box>
          <Text fontSize="sm" color="gray.600">
            Group
          </Text>
          <Text fontWeight="bold">{group.name}</Text>
        </Box>
        {!group.active && (
          <ApprovalStateBadge
            type="deactivated"
            label="deactivated"
            rest={{ alignSelf: "center", marginLeft: [0, 3, 0, 0, 3] }}
          />
        )}
      </Box>
    </Box>
  );
};
