import { Box, Button } from "@chakra-ui/react";
import {
  ApiSafetyNote,
  ApiSafetyNoteType,
} from "@operations-hero/lib-api-client";
import { FormikProps, useFormikContext } from "formik";
import React, { useCallback } from "react";
import { TextEditorControl } from "../../../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import {
  SelectType,
  TaskBookTypeSelectControl,
} from "../../../../../components/form-helpers/TaskBookTypeSelectControl";
import { TaskBookFormValues } from "../../../../../store/taskbook.slice";
import { SubFormFooter } from "../common-components/SubFormFooter";
import { SubFormHeader } from "../common-components/SubFormHeader";

const newSafetyNote = {
  type: ApiSafetyNoteType.warning,
  description: "",
};

export const SafetyNotesForm: React.FC = () => {
  const { values, setValues, setFieldValue }: FormikProps<TaskBookFormValues> =
    useFormikContext();

  const addSafetyNote = useCallback(() => {
    setValues({
      ...values,
      safetyNotes: [
        ...values.safetyNotes,
        {
          ...newSafetyNote,
          id: `new::${values.safetyNotes.length}`,
        } as ApiSafetyNote,
      ],
    });
  }, [values, setValues]);

  const deleteSafetyNote = useCallback(
    (safetyNote: ApiSafetyNote, idx: number) => {
      const { safetyNotes } = values;
      const safetyNotesCopy = [...safetyNotes];

      const index = safetyNotes.findIndex((sn) => sn.id === safetyNote.id);
      if (index !== -1) {
        safetyNotesCopy.splice(index, 1);
        setFieldValue("safetyNotes", safetyNotesCopy);
        const notesToDelete = values.safetyNotesToDelete
          ? [...values.safetyNotesToDelete, safetyNote]
          : [safetyNote];
        !safetyNote.id.includes("new") &&
          setFieldValue("safetyNotesToDelete", notesToDelete);
      }
    },
    [setFieldValue, values]
  );

  return (
    <>
      <SubFormHeader
        title="Safety Notes"
        textColor="orange.400"
        buttonColor="orange.400"
        buttonLabel="Add Safety Note"
        addSubFormFunction={addSafetyNote}
      />
      {values.safetyNotes.map((item, index) => (
        <Box
          mt={8}
          padding={4}
          key={item.id}
          borderRadius={4}
          border={"1px solid"}
          borderColor="orange.400"
        >
          <Box maxWidth={300} mb={4}>
            <TaskBookTypeSelectControl
              label="Type"
              value={item.type}
              name={`safetyNotes[${index}].type`}
              selectType={SelectType.SAFETY_NOTE_TYPE}
            />
          </Box>
          <TextEditorControl
            value={item.description}
            name={`safetyNotes[${index}].description`}
          />
          <Button
            mt={4}
            maxH="32px"
            color="gray.700"
            variant="outline"
            borderColor="blue.500"
            onClick={() => deleteSafetyNote(item, index)}
          >
            Delete
          </Button>
        </Box>
      ))}
      <SubFormFooter
        color="orange.400"
        title=" Add Safety Note"
        addSubFormFunction={addSafetyNote}
        isEnabled={values.safetyNotes.length > 0}
      />
    </>
  );
};
