import { Box, Text, useColorModeValue } from "@chakra-ui/react";
import {
  GroupBase,
  OptionProps,
  Select,
  SingleValueProps,
} from "chakra-react-select";
import { useCallback, useMemo } from "react";
import {
  NullValue,
  useAvailableFiscalYear,
} from "../../pages/planning-hq/funding-source/useAvailableFiscalYears";
import { FiscalYearRange } from "../../store/planning-hq/types";
import { commonStyles, getCustomSelectComponents } from "./select-overrides";

export interface FiscalYearSelectProps {
  fiscalYear: FiscalYearRange | undefined;
  onChange?: (fiscalYear: FiscalYearRange) => void;
  isInvalid?: boolean;
  allowNull?: boolean;
}

type ValueLabel = {
  value: FiscalYearRange;
  label: string;
};

const CustomOptionComponent = (
  props: OptionProps<ValueLabel, false, GroupBase<ValueLabel>>
) => {
  const { data, innerRef, innerProps } = props;
  const hoverBgColor = useColorModeValue("gray.100", "whiteAlpha.100");
  return (
    <Box
      {...innerProps}
      p={2}
      ref={innerRef}
      _hover={{ backgroundColor: hoverBgColor }}
      cursor="pointer"
    >
      <Text>{data.label}</Text>
    </Box>
  );
};

const CustomSingleValueComponent = (
  props: SingleValueProps<ValueLabel, false, GroupBase<ValueLabel>>
) => {
  const { data, innerProps } = props;
  return (
    <Box {...innerProps}>
      <Text>{data.label}</Text>
    </Box>
  );
};

export const FiscalYearSelect = ({
  fiscalYear,
  onChange,
  isInvalid = false,
  allowNull = false,
}: FiscalYearSelectProps) => {
  const { ranges } = useAvailableFiscalYear(allowNull);

  const options = useMemo(
    () =>
      Object.values(ranges).map((range) => ({
        value: {
          start: range.start,
          end: range.end,
        },
        label: range.label,
      })),
    [ranges]
  );

  const components = useMemo(
    () => ({
      SingleValue: CustomSingleValueComponent,
      Option: CustomOptionComponent,
      ...getCustomSelectComponents(),
    }),
    []
  );

  const value = useMemo(() => {
    const currentValue = {
      fiscalYearStart: fiscalYear?.start ?? "",
      fiscalYearEnd: fiscalYear?.end ?? "",
    };

    const { fiscalYearStart, fiscalYearEnd } = currentValue;

    if (!fiscalYearStart && !fiscalYearEnd && allowNull)
      return {
        value: {
          start: NullValue.start,
          end: NullValue.end,
        },
        label: NullValue.label,
      };

    const findValueInOptions = options.find(
      (opt) =>
        opt.value.start === fiscalYearStart && opt.value.end === fiscalYearEnd
    );

    return findValueInOptions || null;
  }, [options, fiscalYear, allowNull]);

  const handleChange = useCallback(
    (item: ValueLabel | null) => {
      if (onChange && item) onChange(item.value);
    },
    [onChange]
  );

  return (
    <Select
      isInvalid={isInvalid}
      options={options}
      components={components}
      onChange={handleChange}
      chakraStyles={commonStyles}
      value={value}
      menuPosition="fixed"
    />
  );
};
