import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from "@chakra-ui/react";
import { ApiRequest } from "@operations-hero/lib-api-client";
import { useField, useFormikContext } from "formik";
import { FC, useCallback } from "react";
import { EstimatedHours } from "../../pages/request-form/EstimateHours";

export interface EstimatedHoursControlProps {
  label: string;
  name: string;
  value: number | null;
  placeholder?: string;
  helperText?: string;
  isDisabled?: boolean;
}

export const EstimatedHoursControl: FC<EstimatedHoursControlProps> = ({
  label,
  name,
  value,
  placeholder,
  helperText,
  isDisabled,
}) => {
  const { submitCount } = useFormikContext();
  //@ts-ignore
  const [field, meta, helper] = useField({
    name,
    value,
    placeholder,
  });

  const handleOnChange = useCallback(
    (request: Partial<ApiRequest>) => {
      helper.setTouched(true);
      helper.setValue(request.estimatedHours || null);
    },
    [helper]
  );

  return (
    <FormControl isInvalid={!!meta.error && (meta.touched || submitCount > 0)}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <EstimatedHours
        {...field}
        handlePropertyChange={handleOnChange}
        isCostSection
        hideLabel
        hideWidth
        isDisabled={isDisabled}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{meta.error}</FormErrorMessage>
    </FormControl>
  );
};
