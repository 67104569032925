import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Box,
  FormControl,
  FormErrorMessage,
  HStack,
  Text,
  useColorMode,
  VStack,
} from "@chakra-ui/react";
import { ApiBudget, CreateApiBudget } from "@operations-hero/lib-api-client";
import { Form, Formik, FormikProps } from "formik";
import { FC, MutableRefObject, useCallback, useMemo } from "react";
import * as yup from "yup";
import { FundingSourceAllocationProvider } from "../../../../components/allocations/FundingSourcesAllocationsProvider";
import { FundingSourceAllocation } from "../../../../components/allocations/types";
import { FiscalYearsControl } from "../../../../components/form-helpers/FiscalYearControl";
import { TextEditorControl } from "../../../../components/form-helpers/rich-text-editor/RichTextEditorControl";
import { TextInputControl } from "../../../../components/form-helpers/TextInputControl";
import { FiscalYearRange } from "../../../../store/planning-hq/types";
import { formatCurrency } from "../../../../utils/formatCurrency";
import { AddAllocationButton } from "./funding-source-allocations/AddAllocationButton";
import { AllocationAmountControl } from "./funding-source-allocations/AllocationAmountControl";
import { AllocationFundingSourceControl } from "./funding-source-allocations/AllocationFundingSourceControl";
import { RemoveAllocationButton } from "./funding-source-allocations/RemoveAllocationButton";

export type NewBudgetProps = {
  name: string;
  description: string;
  code: string;
  allocations: FundingSourceAllocation[];
  fiscalYear: FiscalYearRange;
};

export type NewBudgetFormProps = {
  onSubmit: (budget: CreateApiBudget) => Promise<ApiBudget | void>;
  formRef: MutableRefObject<FormikProps<NewBudgetProps> | null>;
};

export const NewBudgetForm: FC<NewBudgetFormProps> = ({
  onSubmit,
  formRef,
}) => {
  const { colorMode } = useColorMode();

  const initialValues: NewBudgetProps = {
    name: "",
    description: "",
    code: "",
    allocations: [],
    fiscalYear: {
      start: "",
      end: "",
    },
  };

  const handleOnSubmit = useCallback(
    (values: NewBudgetProps) => {
      const { name, code, description, allocations, fiscalYear } = values;

      const budget: CreateApiBudget = {
        name,
        code,
        description,
        fundingSourcesAndAmount: allocations.map((alloc) => ({
          amountAllocated: alloc.amount,
          fundingSourceId: alloc.source?.id ?? "",
        })),
        fiscalYearEnd: fiscalYear.end ? fiscalYear.end : undefined,
        fiscalYearStart: fiscalYear.start ? fiscalYear.start : undefined,
      };

      return onSubmit(budget);
    },
    [onSubmit]
  );

  const validationSchema = useMemo(() => {
    const schema = yup.object().shape({
      name: yup.string().nullable().required("Name is a required field"),
      code: yup.string().nullable().required("Code is a required field"),
      fiscalYear: yup
        .object()
        .test("fiscal-year-required", "Fiscal Year is required", (value) => {
          if (!value.start || !value.end) return false;
          return true;
        }),
      allocations: yup
        .array()
        .of(
          yup.object().shape({
            source: yup
              .object()
              .nullable()
              .required("Funding source is required"),
            amount: yup
              .number()
              .test(
                "allocated-amount-required",
                "Amount is required",
                (value) => {
                  return Boolean(value);
                }
              )
              .test(
                "allocated-amount-max",
                "Allocation exceeds funding source limit",
                (value, context) => {
                  if (!value) return true;

                  return (
                    value <= parseFloat(context.parent.source.fundsUnallocated)
                  );
                }
              ),
          })
        )
        .test(
          "min-funding-sources",
          "At least one funding source is required",
          (value) => {
            if (value?.length === 0) return false;
            return true;
          }
        ),
    });
    return schema;
  }, []);

  return (
    <Formik
      onSubmit={handleOnSubmit}
      initialValues={initialValues}
      innerRef={formRef}
      validationSchema={validationSchema}
      validateOnChange={true}
    >
      {({ values, errors, submitCount, setFieldValue }) => {
        return (
          <Form>
            <VStack align="stretch" gap={3}>
              <TextInputControl
                name="name"
                value={values.name}
                label="Name *"
              />
              <TextInputControl
                name="code"
                value={values.code}
                label="Code  *"
              />
              <TextEditorControl
                name="description"
                label="Description"
                value={values.description}
              />
              <FiscalYearsControl
                value={{
                  start: values.fiscalYear.start,
                  end: values.fiscalYear.end,
                }}
                label="Fiscal Year *"
                name="fiscalYear"
              />
              <FundingSourceAllocationProvider
                onChange={(allocations) => {
                  setFieldValue("allocations", allocations);
                }}
                initialAllocations={[{ source: null, amount: 0 }]}
              >
                {({ allocations, totalAllocated }) => {
                  return (
                    <>
                      <HStack w="full" justify="space-between">
                        <Text>Funding source</Text>
                        <AddAllocationButton />
                      </HStack>
                      {allocations.map((allocation, index) => (
                        <HStack
                          key={`allocation::${index}::${allocation.source?.id ?? ""}`}
                          alignItems="start"
                        >
                          <HStack flex={1} alignItems="start">
                            <Box flexBasis="60%">
                              <AllocationFundingSourceControl
                                value={allocation.source}
                                index={index}
                              />
                            </Box>
                            <ArrowForwardIcon boxSize="5" mt={3} />
                            <Box flexBasis="40%">
                              <AllocationAmountControl
                                value={allocation.amount}
                                index={index}
                                isDisabled={allocation.source === null}
                              />
                            </Box>
                          </HStack>
                          <RemoveAllocationButton index={index} />
                        </HStack>
                      ))}
                      <FormControl
                        isInvalid={
                          Boolean(errors.allocations) &&
                          typeof errors.allocations === "string" &&
                          submitCount > 0
                        }
                      >
                        <FormErrorMessage>
                          {errors.allocations?.toString()}
                        </FormErrorMessage>
                      </FormControl>
                      <VStack
                        w="full"
                        backgroundColor={
                          colorMode === "light" ? "gray.50" : "gray.700"
                        }
                        justify="stretch"
                        alignItems="end"
                        py="4"
                        px="6"
                        rounded="md"
                        border={colorMode === "light" ? "none" : "solid"}
                        borderWidth="thin"
                        borderColor="gray.600"
                      >
                        <Text
                          fontSize="large"
                          fontWeight="bold"
                        >{`${formatCurrency(totalAllocated)}`}</Text>
                        <Text>Total Amount Allocated</Text>
                      </VStack>
                    </>
                  );
                }}
              </FundingSourceAllocationProvider>
            </VStack>
          </Form>
        );
      }}
    </Formik>
  );
};
