import {
  ApiRequestStatus,
  CreateApiRequest,
} from "@operations-hero/lib-api-client";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useShowToast } from "../../../hooks/showToast";
import { RootState } from "../../../store";
import {
  setNewRequestDialogIsOpen,
  unloadForm,
} from "../../../store/new-request-form.slice";
import { useAuthentication } from "../../auth/AuthProvider";

export const useCreateNewRequestFromTaskbookQR = () => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const showToast = useShowToast();
  const { apiClient, currentAccount } = useAuthentication();
  const { assets, autoCreateRequest, taskbook } = useSelector(
    (state: RootState) => state.newRequestForm
  );

  const createRequestAsset = useCallback(
    async (requestId: string) => {
      await Promise.all(
        assets.map((asset) =>
          apiClient.createRequestAsset(currentAccount.id, requestId, {
            assetId: asset.id,
          })
        )
      );
    },
    [apiClient, assets, currentAccount.id]
  );

  const createRequestTaskbook = useCallback(
    async (requestId: string, requestKey: string) => {
      if (!autoCreateRequest || taskbook.length === 0) {
        return;
      }
      const [requestTaskbook] = await apiClient.createRequestTaskbook(
        currentAccount.id,
        requestId,
        {
          ids: taskbook.map((t) => t.id),
        }
      );

      await apiClient.updateRequest(currentAccount.id, requestId, {
        status: ApiRequestStatus.started,
      });

      await apiClient.updateRequestTaskbook(
        currentAccount.id,
        requestId,
        requestTaskbook.id,
        { started: new Date().toISOString(), status: "incomplete" }
      );
      dispatch(setNewRequestDialogIsOpen(false));
      navigate(`/requests/${requestKey}/books/${requestTaskbook.id}`);
    },
    [
      apiClient,
      autoCreateRequest,
      currentAccount.id,
      dispatch,
      navigate,
      taskbook,
    ]
  );

  const formatSummary = useCallback((requestSummary: string) => {
    // this is to put bold the taskbook name and created date
    const result = requestSummary.replace(/: /, ": **");
    return `${result}**`;
  }, []);

  const autoCreateNewRequest = useCallback(async () => {
    try {
      if (
        !autoCreateRequest ||
        !autoCreateRequest.requestToCreate ||
        !autoCreateRequest.requestToCreate.workflow
      ) {
        return;
      }

      const formatedSummary = formatSummary(
        autoCreateRequest.requestToCreate.summary
      );
      const newRequestPayload: CreateApiRequest = {
        ...(autoCreateRequest.requestToCreate as CreateApiRequest),
        summary: formatedSummary,
      };

      /*Creating Request */
      const newRequest = await apiClient.createRequest(
        currentAccount.id,
        newRequestPayload
      );

      /*Creating Request Assets */
      createRequestAsset(newRequest.id);

      /*Creating Request Taskbook */
      createRequestTaskbook(newRequest.id, newRequest.key);
    } catch (error) {
      showToast(
        "error",
        "Something went wrong creating the request, please try again!"
      );
    } finally {
      dispatch(setNewRequestDialogIsOpen({ isOpen: false, source: null }));
      dispatch(unloadForm());
    }
  }, [
    apiClient,
    autoCreateRequest,
    createRequestAsset,
    createRequestTaskbook,
    currentAccount.id,
    dispatch,
    formatSummary,
    showToast,
  ]);

  return { autoCreateNewRequest };
};
