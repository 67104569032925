import {
  Box,
  Button,
  ButtonGroup,
  Heading,
  HStack,
  Icon,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { ApiPurchaseTransaction } from "@operations-hero/lib-api-client";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { IoAddOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { SparkleWithAnimation } from "../../../../components/icons/Sparkle";
import { Pager } from "../../../../components/pager/Pager";
import { RootState, useThunkDispatch } from "../../../../store";
import { setPurchasesTransactionsCurrentPage } from "../../../../store/request-form/request-form.slice";
import { loadTransactions } from "../../../../store/request-form/thunks/loadTransactions.thunk";
import { getVisibleFields } from "../../../../utils/getVisibleFields";
import { ExpensesData } from "./ExpensesData";
import { ExpensesModal } from "./ExpensesModal";
import { ScanReceiptModal } from "./ScanReceiptModal/ScanReceiptModal";

export const PurchasesSection: React.FC = () => {
  const [workingPurchase, setWorkingPurchase] =
    useState<ApiPurchaseTransaction | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isScanReceiptOpen,
    onOpen: onOpenScanReceipt,
    onClose: onCloseScanReceipt,
  } = useDisclosure();
  const dispatch = useDispatch();

  const textColor = useColorModeValue("gradarkslategrey", "white");
  const isMobile = useBreakpointValue({
    base: true,
    sm: true,
    md: false,
  });

  const { purchasesData, purchasesTotal, purchasesCurrentPage } = useSelector(
    (state: RootState) => state.requestForm.transactions.purchases
  );
  const { request, policy, workflow, visibleFields } = useSelector(
    (state: RootState) => state.requestForm
  );

  const { apiClient, currentAccount, isProductAdmin } = useAuthentication();

  const handleCloseModal = () => {
    setWorkingPurchase(null);
    onClose();
  };

  const thunkDispatch = useThunkDispatch();

  const { showExpenses } = useMemo(() => {
    return getVisibleFields(visibleFields);
  }, [visibleFields]);

  const handlePageChange = useCallback(
    async (page: number) => {
      if (!request) {
        return;
      }
      await dispatch(setPurchasesTransactionsCurrentPage(page));
    },
    [dispatch, request]
  );

  const canAdd = useMemo(() => {
    if (!policy) {
      return false;
    }
    return (
      isProductAdmin ||
      policy.admin ||
      policy.reviewer ||
      policy.technician ||
      policy.contractor ||
      false
    );
  }, [policy, isProductAdmin]);

  useEffect(() => {
    if (!request?.key) {
      return;
    }
    thunkDispatch(
      loadTransactions({
        apiClient,
        account: currentAccount,
        key: request.key,
        transactionType: "purchase",
      })
    );
  }, [
    thunkDispatch,
    apiClient,
    currentAccount,
    request?.key,
    purchasesCurrentPage,
  ]);
  return workflow && (purchasesData.length > 0 || showExpenses) ? (
    <>
      <Stack>
        <HStack w="100%">
          <Heading size="md" flex={1}>
            Expenses
          </Heading>

          {canAdd && showExpenses && (
            <ButtonGroup>
              <Button
                leftIcon={<SparkleWithAnimation mr={0.5} boxSize="1em" />}
                aria-label="Scan Receipt"
                size={"sm"}
                colorScheme="blue"
                variant="outline"
                onClick={onOpenScanReceipt}
              >
                {!isMobile ? "Scan Receipt" : "Scan"}
              </Button>
              <Button
                size={"sm"}
                leftIcon={<Icon mr={0.56} as={IoAddOutline} />}
                colorScheme="blue"
                variant="outline"
                onClick={onOpen}
              >
                {!isMobile ? "Add Expense" : "Add"}
              </Button>
            </ButtonGroup>
          )}
        </HStack>

        {isScanReceiptOpen && (
          <ScanReceiptModal
            isOpen={isScanReceiptOpen}
            onClose={onCloseScanReceipt}
          />
        )}

        {purchasesData.length === 0 && (
          <Text pb="6" color={textColor} fontSize="md">
            Add receipts, invoices, or other paid for item.
          </Text>
        )}
        {purchasesData.length > 0 && (
          <ExpensesData
            workingPurchase={workingPurchase}
            setWorkingPurchase={setWorkingPurchase}
            onOpenEditModal={onOpen}
          />
        )}

        {purchasesTotal > 20 && (
          <Box>
            <Pager
              currentPage={purchasesCurrentPage || 1}
              total={purchasesTotal}
              pageSize={20}
              onPageChange={handlePageChange}
            />
          </Box>
        )}
      </Stack>
      <ExpensesModal
        isOpen={isOpen}
        handleCloseModal={handleCloseModal}
        workingPurchase={workingPurchase}
        purchaseId={workingPurchase?.id || null}
      />
    </>
  ) : null;
};
