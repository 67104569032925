import { Box, Flex, Text } from "@chakra-ui/react";
import { EventsSettingsKeys } from "@operations-hero/lib-api-client";
import { useCallback, useEffect, useState } from "react";
import { useAuthentication } from "../../components/auth/AuthProvider";
import { AutoSavingSwitch } from "../../components/inputs/AutoSavingSwitch";
import { useProductSubscriptions } from "../../components/shell/AppShell";
import { useShowToast } from "../../hooks/showToast";
import {
  getUserOrAccountSettingDefault,
  SettingProps,
} from "../../utils/emailSettingUtils";

export const EventHQEmailSettings = ({
  update,
  accountSettings,
  userSettings,
  label,
}: SettingProps) => {
  const { hasEvents } = useProductSubscriptions();
  const showToast = useShowToast();
  const { currentUser: user, currentAccount } = useAuthentication();
  const [mentionEventEmails, setMentionEventEmails] = useState(false);
  const [allowEventApprovers, setAllowEventApprovers] = useState(false);
  const [allowEventCreate, setAllowEventCreate] = useState(false);
  const [allowEventApproval, setAllowEventApproval] = useState(false);
  const [allowEventNeedsApproval, setAllowEventNeedsApproval] = useState(false);
  const [allowEventOccurrenceCanceled, setAllowEventOccurrenceCanceled] =
    useState(false);

  const handleSettingSave = useCallback(
    (key: string) => (value: boolean) => {
      return update(currentAccount.id, { [key]: value })
        .then((saved) => {
          showToast("success", `Update notification setting`);
          if (key === EventsSettingsKeys.ALLOW_EVENT_COMMENT_MENTION_EMAILS) {
            setMentionEventEmails(
              saved[
                EventsSettingsKeys.ALLOW_EVENT_COMMENT_MENTION_EMAILS
              ] as boolean
            );
          }

          if (key === EventsSettingsKeys.ALLOW_EVENT_APPROVERS_COMMENT_EMAILS) {
            setAllowEventApprovers(
              saved[
                EventsSettingsKeys.ALLOW_EVENT_APPROVERS_COMMENT_EMAILS
              ] as boolean
            );
          }

          if (
            key === EventsSettingsKeys.ALLOW_EVENT_CREATE_CONFIRMATION_EMAILS
          ) {
            setAllowEventCreate(
              saved[
                EventsSettingsKeys.ALLOW_EVENT_CREATE_CONFIRMATION_EMAILS
              ] as boolean
            );
          }

          if (key === EventsSettingsKeys.ALLOW_EVENT_CONFIRMED_EMAILS) {
            setAllowEventApproval(
              saved[EventsSettingsKeys.ALLOW_EVENT_CONFIRMED_EMAILS] as boolean
            );
          }

          if (key === EventsSettingsKeys.ALLOW_EVENT_NEEDS_APPROVAL_EMAILS) {
            setAllowEventNeedsApproval(
              saved[
                EventsSettingsKeys.ALLOW_EVENT_NEEDS_APPROVAL_EMAILS
              ] as boolean
            );
          }
          if (
            key === EventsSettingsKeys.ALLOW_EVENT_OCCURRENCE_CANCELED_EMAILS
          ) {
            setAllowEventOccurrenceCanceled(
              saved[
                EventsSettingsKeys.ALLOW_EVENT_OCCURRENCE_CANCELED_EMAILS
              ] as boolean
            );
          }
        })
        .catch(() => {
          showToast("error", "Error updating notification setting");
        });
    },
    [currentAccount, showToast, update]
  );

  useEffect(() => {
    setMentionEventEmails(
      getUserOrAccountSettingDefault(
        accountSettings,
        EventsSettingsKeys.ALLOW_EVENT_COMMENT_MENTION_EMAILS,
        userSettings
      )
    );

    setAllowEventApprovers(
      getUserOrAccountSettingDefault(
        accountSettings,
        EventsSettingsKeys.ALLOW_EVENT_APPROVERS_COMMENT_EMAILS,
        userSettings
      )
    );

    setAllowEventCreate(
      getUserOrAccountSettingDefault(
        accountSettings,
        EventsSettingsKeys.ALLOW_EVENT_CREATE_CONFIRMATION_EMAILS,
        userSettings
      )
    );

    setAllowEventApproval(
      getUserOrAccountSettingDefault(
        accountSettings,
        EventsSettingsKeys.ALLOW_EVENT_CONFIRMED_EMAILS,
        userSettings
      )
    );

    setAllowEventNeedsApproval(
      getUserOrAccountSettingDefault(
        accountSettings,
        EventsSettingsKeys.ALLOW_EVENT_NEEDS_APPROVAL_EMAILS,
        userSettings
      )
    );
    setAllowEventOccurrenceCanceled(
      getUserOrAccountSettingDefault(
        accountSettings,
        EventsSettingsKeys.ALLOW_EVENT_OCCURRENCE_CANCELED_EMAILS,
        userSettings
      )
    );
  }, [user, currentAccount, accountSettings, userSettings]);

  return (
    <>
      {hasEvents && (
        <Flex flexDir="column" gap={[5, 2, 5, 2]} w="full">
          <Text fontSize="lg" fontWeight="bold">
            {label ? label : "EventHQ Settings"}
          </Text>

          <Box width="100%">
            <AutoSavingSwitch
              id="allow-event-comment-mention-emails"
              isChecked={mentionEventEmails}
              label="Email me when I am mentioned in an event"
              onSave={handleSettingSave(
                EventsSettingsKeys.ALLOW_EVENT_COMMENT_MENTION_EMAILS
              )}
            />
          </Box>
          <Box width="100%">
            <AutoSavingSwitch
              id="allow-event-approvers-comment-emails"
              isChecked={allowEventApprovers}
              label="Email me comments when I'm an approver"
              onSave={handleSettingSave(
                EventsSettingsKeys.ALLOW_EVENT_APPROVERS_COMMENT_EMAILS
              )}
            />
          </Box>
          <Box width="100%">
            <AutoSavingSwitch
              id="allow-event-occurrence-canceled-emails"
              isChecked={allowEventOccurrenceCanceled}
              label="Email me when an event occurrence is cancelled"
              onSave={handleSettingSave(
                EventsSettingsKeys.ALLOW_EVENT_OCCURRENCE_CANCELED_EMAILS
              )}
            />
          </Box>
          <Box width="100%">
            <AutoSavingSwitch
              id="allow-event-create-confirmation-emails"
              isChecked={allowEventCreate}
              label="Email me a confirmation when I create an event"
              onSave={handleSettingSave(
                EventsSettingsKeys.ALLOW_EVENT_CREATE_CONFIRMATION_EMAILS
              )}
            />
          </Box>
          <Box width="100%">
            <AutoSavingSwitch
              id="allow-event-confirmed-emails"
              isChecked={allowEventApproval}
              label="Email me when my event has been confirmed or declined"
              onSave={handleSettingSave(
                EventsSettingsKeys.ALLOW_EVENT_CONFIRMED_EMAILS
              )}
            />
          </Box>
          <Box width="100%">
            <AutoSavingSwitch
              id="allow-event-needs-approval-emails"
              isChecked={allowEventNeedsApproval}
              label="Email me when an event requires my approval"
              onSave={handleSettingSave(
                EventsSettingsKeys.ALLOW_EVENT_NEEDS_APPROVAL_EMAILS
              )}
            />
          </Box>
        </Flex>
      )}
    </>
  );
};
