import {
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { ApiFundingSource } from "@operations-hero/lib-api-client";
import FuzzySearch from "fuzzy-search";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import Select, { SelectRenderer } from "react-dropdown-select";
import { useAuthentication } from "../../../../components/auth/AuthProvider";
import { AdditionalNumberBadge } from "../../../../components/badges/AdditionalNumberBadge";
import { getId } from "../../../../utils/getId";

export interface FundingSourceFilterProps {
  values: (ApiFundingSource | string)[];
  onChange: (value: ApiFundingSource[]) => void;
}

export const FundingSourceFilterBadge = ({
  value,
}: {
  value: ApiFundingSource;
}) => {
  return <HStack>{value && <Box>{value.name}</Box>}</HStack>;
};

const ContentRenderer = ({
  props,
  state,
}: SelectRenderer<ApiFundingSource>) => {
  const { values } = props;
  return (
    <Box p={1}>
      {values.length === 0 && "Funds"}
      {values.length === 1 && <Text>{values[0].name}</Text>}
      {values.length > 1 && (
        <Flex alignItems="center">
          <Text mr={1}>{values[0].name}</Text>
          <AdditionalNumberBadge number={values.length - 1} />
        </Flex>
      )}
    </Box>
  );
};

const DropdownRenderer = ({
  props,
  state,
  methods,
}: SelectRenderer<ApiFundingSource>) => {
  const isDisabled = useMemo(
    () => (item: ApiFundingSource) =>
      props.values.includes(item) && !methods.isSelected(item),
    [props.values, methods]
  );

  const bgColor = useColorModeValue(undefined, "gray.700");

  return (
    <Box p={2} backgroundColor={bgColor} minW="250px">
      <Box pb={1}>
        <HStack justifyContent="space-between" pb={2}>
          <div>Search and select:</div>
          {methods.areAllSelected() ? (
            <Button
              size="sm"
              variant="outline"
              onClick={() => methods.clearAll()}
            >
              Clear all
            </Button>
          ) : (
            <Button size="sm" onClick={() => methods.selectAll()}>
              Select all
            </Button>
          )}
        </HStack>
        <Input
          type="text"
          value={state.search}
          onChange={methods.setSearch}
          placeholder="Search Inventory Items"
        />
      </Box>
      <Box>
        {
          //@ts-ignore
          (state.search ? state.searchResults : props.options).map((item) => {
            return (
              <Checkbox
                key={item.id}
                isChecked={(() => props.values.includes(item))()}
                onChange={
                  isDisabled(item) ? undefined : () => methods.addItem(item)
                }
                w="100%"
                p={2}
                disabled={isDisabled(item)}
              >
                <FundingSourceFilterBadge value={item} />
              </Checkbox>
            );
          })
        }
      </Box>
    </Box>
  );
};

export const FundingSourceFilter: FC<FundingSourceFilterProps> = ({
  values,
  onChange,
}) => {
  const [options, setOptions] = useState<ApiFundingSource[]>([]);
  const { apiClient, currentAccount } = useAuthentication();
  const [search, setSearch] = useState<string>("");
  const themeClass = useColorModeValue("light-theme", "dark-theme");

  const fuzzySearch = useMemo(
    () =>
      new FuzzySearch(options, ["name"], {
        sort: true,
      }),
    [options]
  );

  const searchFn = useCallback(
    ({ props, state, methods }: SelectRenderer<ApiFundingSource>) => {
      if (!state.search) {
        return props.options;
      }
      const found = fuzzySearch.search(state.search);
      setSearch(state.search);
      //@ts-ignore
      return [...found];
    },
    [fuzzySearch]
  );

  const loadOptions = useCallback(() => {
    apiClient
      .findFundingSources(currentAccount.id, {
        search,
        pageSize: 50,
      })
      .then((response) => {
        setOptions(response.data);
      });
  }, [apiClient, currentAccount.id, search]);

  const selectValues = useMemo(() => {
    const filteredValues = options.filter((fs) =>
      values.find((val: ApiFundingSource | string) => {
        return val === getId(fs.id);
      })
    );

    return filteredValues;
  }, [options, values]);

  useEffect(() => {
    loadOptions();
  }, [loadOptions, search]);

  return (
    <Select
      multi
      options={options}
      className={themeClass}
      values={selectValues}
      onChange={(value) => onChange(value)}
      searchable={true}
      searchBy="name"
      valueField="id"
      labelField="name"
      dropdownGap={0}
      keepSelectedInList
      clearOnSelect={false}
      clearable={false}
      contentRenderer={ContentRenderer}
      dropdownRenderer={DropdownRenderer}
      dropdownHeight="350px"
      searchFn={searchFn}
    />
  );
};
